import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { createSubstitute } from "actions/products";
import { Modal, AsyncSelectField } from "components";

import { getVirtualProductOptions } from "utils/select_options/product_options";

import { ReactComponent as ProductIcon } from "icons/product.svg";

const AddVirtualModal = ({
  handleClose,
  handleSubmit,
  change,
  pristine,
  createSubstitute,
}) => {
  const onSubmit = async (values) => {
    try {
      await createSubstitute(values);
      handleClose();
    } catch (error) {
      throw new SubmissionError(error);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      header={{ title: "Dodaj produkt", icon: <ProductIcon /> }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="add-green"
            text="Dodaj"
            disabled={pristine}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="alternative_id"
          component={AsyncSelectField}
          change={change}
          getData={getVirtualProductOptions}
          label="Wybierz produkt, który będzie częścią substytutu:"
        />
      </form>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  createSubstitute: (data) => dispatch(createSubstitute(data)),
});

export default connect(
  ({ product: { data: product } }) => ({
    initialValues: {
      virtual_id: product.id,
      alternative_id: null,
    },
  }),
  mapDispatchToProps
)(reduxForm({ form: "add-substitute" })(AddVirtualModal));
