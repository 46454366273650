import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'

import { Button } from 'expano-components'
import { Main, Dashboard, InputField, InputColorPickerField } from 'components'
import { createShop } from 'actions/shops'

import { ReactComponent as ShopIcon } from 'icons/shop.svg'
import { ReactComponent as InfoIcon } from 'icons/info.svg'

const ShopNew = ({ handleSubmit, pristine, change, history }) => {
  const onSubmit = values =>
    createShop(values)
      .then(({ id }) => history.push(`/shops/${id}`))
      .catch(err => {
        throw new SubmissionError(err)
      })

  return (
    <Main
      page={{
        name: 'Dodaj sklep',
        icon: <ShopIcon />,
        breadcrumbs: [
          { name: 'Sklepy', path: '/shops' },
          { name: 'Dodaj sklep' }
        ]
      }}>
      <form
        className="dashboard-wrapper-flex"
        noValidate
        onSubmit={handleSubmit(onSubmit)}>
        <div className="column-2">
          <Dashboard
            header={{ icon: <InfoIcon />, title: 'Podstawowe dane' }}
            footer={
              <Button
                disabled={pristine}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <div className="dashboard__form__submit">
              <div className="dashboard-wrapper-flex m-b-10">
                <div className="column-half">
                  <div className="form-row grid-2">
                    <Field
                      name="name"
                      label="Nazwa sklepu"
                      component={InputField}
                      type="text"
                    />
                    <Field
                      name="identifier"
                      label="Identyfikator"
                      component={InputField}
                      type="text"
                    />
                  </div>
                </div>
                <div className="column-half">
                  <div className="form-row grid-2">
                    <Field
                      name="label_text"
                      label="Skrócona nazwa"
                      component={InputField}
                      type="text"
                    />
                    <Field
                      name="label_color"
                      label="Kolor"
                      handleChange={color => change('label_color', color)}
                      component={InputColorPickerField}
                    />
                  </div>
                </div>
              </div>
              <div className="form-row grid-2">
                <Field
                  name="phone"
                  label="Telefon"
                  component={InputField}
                  type="tel"
                />
                <Field
                  name="email"
                  label="Adres e-mail"
                  component={InputField}
                  type="email"
                />
              </div>
              <Field
                name="bank_account"
                label="Numer konta bankowego"
                component={InputField}
                type="text"
              />
              <Field
                name="street"
                label="Adres"
                component={InputField}
                type="text"
              />
              <div className="form-row grid-2">
                <Field
                  name="city"
                  label="Miasto"
                  component={InputField}
                  type="text"
                />
                <Field
                  name="zip_code"
                  label="Kod pocztowy"
                  component={InputField}
                  type="text"
                />
              </div>
              <div className="form-row grid-2">
                <Field
                  name="website"
                  label="Strona internetowa"
                  component={InputField}
                  type="text"
                />
              </div>
            </div>
          </Dashboard>
        </div>
      </form>
    </Main>
  )
}

export default reduxForm({ form: 'create-shop' })(ShopNew)
