import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Label } from 'expano-components'
import { Button, MainTable, Main, Dashboard } from 'components'
import { parseQuery } from 'utils/api'
import { getChannels } from 'actions/channels'

import { ReactComponent as ChannelIcon } from 'icons/channel.svg'

const ChannelsList = ({
  channels,
  getChannels,
  history,
  location: { search }
}) => {
  const query = parseQuery(search)
  const [is_loaded, setLoaded] = useState(false)
  const sort = {
    'sort[column]': query['sort[column]'] || 'id',
    'sort[order]': query['sort[order]'] || 'asc'
  }
  useEffect(() => {
    getChannels({ ...query, ...sort })
    setLoaded(true)
  }, [search])
  useEffect(() => {
    if (is_loaded) {
      getChannels({ ...query, page: undefined })
    }
  }, [channels.filters.query])
  if (channels.status === 'failure') {
    return <p>Error</p>
  }
  const { data, meta } = channels
  return (
    <Main
      page={{
        breadcrumbs: [{ name: 'Kanały' }],
        name: 'Kanały',
        icon: <ChannelIcon />,
        buttons: <Button type="add" text="Dodaj kanał" path="/channels/new" />
      }}>
      <Dashboard>
        <MainTable
          is_loading={['invalid', 'loading'].includes(channels.status)}
          is_searching={channels.status === 'searching'}
          filters_open={channels.filters.open}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: 'ID', name: 'id' },
            { label: 'Nazwa', name: 'name' },
            { label: 'Label', name: 'label', sortable: false },
            { label: 'Sklep', name: 'shop' },
            { label: 'Platforma', name: 'platform' }
          ]}
          empty_text="Brak kanałów"
          renderRow={({ id, identifier, shop, platform }) => (
            <tr
              key={id}
              style={{ cursor: 'pointer' }}
              onMouseDown={event => {
                if (event.button === 1) {
                  window.open(`/channels/${id}`, '_blank')
                }
              }}
              onClick={() => history.push(`/channels/${id}`)}>
              <td>{id}</td>
              <td>{identifier}</td>
              <td>
                <Label
                  type="double"
                  left={{
                    text: shop?.label_text,
                    color: `#${shop?.label_color}`
                  }}
                  right={{
                    text: platform?.label_text,
                    color: `#${platform?.label_color}`
                  }}
                />
              </td>
              <td>{shop?.name}</td>
              <td>{platform?.name}</td>
            </tr>
          )}
        />
      </Dashboard>
    </Main>
  )
}

const mapDispatchToProps = dispatch => ({
  getChannels: data => dispatch(getChannels(data))
})
export default connect(
  ({ channels }) => ({ channels }),
  mapDispatchToProps
)(ChannelsList)
