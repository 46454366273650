import React from 'react'
import { Label } from 'expano-components'

import { client } from 'utils/api'

const getShopOptions = q => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/shops?context=list&per_page=15${
          q ? `&q[identifier_matches]=%25${q}%25` : ''
        }`
      )
      resolve(
        data.map(({ id, label_text, label_color, name }) => ({
          label: (
            <p>
              <Label type="fill" text={label_text} color={label_color} /> {name}
            </p>
          ),
          value: { id, label_text, label_color, name }
        }))
      )
    } catch (error) {
      console.log(error)
      reject([])
    }
  })
}
export default getShopOptions
