import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'expano-components'
import { Dashboard } from 'components'
import AddCountry from './components/AddCountry'
import CountryRow from './components/CountryRow'

import { ReactComponent as CountryIcon } from 'icons/country.svg'

const PanelCountry = ({ countries }) => {
  const [is_open_add_modal, handleOpenAddModal] = useState(false)
  return (
    <div className="column-half">
      {is_open_add_modal && (
        <AddCountry handleClose={() => handleOpenAddModal(false)} />
      )}
      <Dashboard
        header={{
          title: 'Kraje',
          icon: <CountryIcon />,
          aside: (
            <Button
              type="add"
              text="Dodaj kraj"
              onClick={() => handleOpenAddModal(true)}
            />
          )
        }}>
        <table className="table">
          <thead className="table__header table-head">
            <tr>
              <th>Kraj</th>
              <th width="100">Działania</th>
            </tr>
          </thead>
          <tbody className="table__body table-body">
            {countries?.length > 0 &&
              countries.map(item => <CountryRow key={item.id} {...item} />)}
          </tbody>
        </table>
      </Dashboard>
    </div>
  )
}

export default connect(
  ({ countries: { data: countries } }) => ({ countries }),
  null
)(PanelCountry)
