import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { updateSubstitute } from "actions/products";
import { AsyncSelectField, Modal } from "components";
import { getVirtualProductOptions } from "utils/select_options/product_options";

import { ReactComponent as ProductIcon } from "icons/product.svg";

const EditSubstituteModal = ({
  updateSubstitute,
  handleSubmit,
  change,
  pristine,
  handleClose,
  id,
  alternative,
}) => {
  const onSubmit = async (values) => {
    try {
      await updateSubstitute(id, values);
      handleClose();
    } catch (error) {
      throw new SubmissionError(error);
    }
  };

  return (
    <Modal
      handleClose={handleClose}
      header={{ title: "Aktualizuj substytut", icon: <ProductIcon /> }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            text="Zapisz"
            disabled={pristine}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Field
          label="Wybierz produkt, który będzie częścią substytutu:"
          name="alternative_id"
          component={AsyncSelectField}
          change={change}
          getData={getVirtualProductOptions}
          initValue={
            alternative
              ? {
                  label: `[${alternative?.sku}] ${alternative?.name}`,
                  value: alternative?.id,
                }
              : null
          }
        />
      </form>
    </Modal>
  );
};

export default connect(null, (dispatch) => ({
  updateSubstitute: (id, data) => dispatch(updateSubstitute(id, data)),
}))(
  reduxForm({ form: "edit-substitute", enableReinitialize: true })(
    EditSubstituteModal
  )
);
