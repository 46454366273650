const initialState = {
  status: 'invalid'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CURRENCIES_LOADING':
      return {
        ...state,
        status: 'loading'
      }
    case 'CURRENCIES_SUCCESS':
      return {
        ...state,
        status: 'success',
        data: action.data
      }
    case 'CURRENCIES_FAILURE':
      return {
        ...state,
        status: 'failure'
      }
    case 'CURRENCY_ADD':
      return {
        ...state,
        data: [...state.data, action.data]
      }
    case 'CURRENCY_DELETE':
      return {
        ...state,
        data: [...state.data].filter(({ id }) => id !== action.id)
      }
    default:
      return state
  }
}
