import { toastr } from 'react-redux-toastr'
import { client, handleErrors } from 'utils/api'

export const getCountries = () => async dispatch => {
  try {
    dispatch({ type: 'COUNTRIES_LOADING' })
    const { data } = await client.get(`/available_countries`)
    dispatch({
      type: 'COUNTRIES_SUCCESS',
      data
    })
  } catch (error) {
    dispatch({ type: 'COUNTRIES_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podzczas pobierania')
  }
}

export const createCountry = values => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id }
      } = await client.post(`/available_countries`, values)
      const { data } = await client.get(`/available_countries/${id}`)
      dispatch({
        type: 'COUNTRY_ADD',
        data
      })
      resolve()
      toastr.success('Sukces', 'Dodano kraj')
    } catch (error) {
      reject(handleErrors(error?.response?.data?.errors))
      toastr.error('Błąd', 'Wystąpił błąd')
    }
  })

export const deleteCountry = id => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/available_countries/${id}`)
      dispatch({
        type: 'COUNTRY_DELETE',
        id
      })
      resolve()
      toastr.success('Sukces', 'Usunięto kraj')
    } catch (error) {
      reject(handleErrors(error?.response?.data?.errors))
      toastr.error('Błąd', 'Wystąpił błąd')
    }
  })
