import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Textarea, Button } from 'expano-components'
import { Modal } from 'components'
import { updateSystemSetting } from 'actions/system_settings'
import { JsonEditor as Editor } from 'jsoneditor-react'

import 'jsoneditor-react/es/editor.min.css'

const TextAreaField = props => <Textarea {...props} />

const EditSystemSetting = ({
  kind,
  name,
  value,
  handleSubmit,
  updateSystemSetting,
  pristine,
  handleClose,
  change,
  id
}) => {
  const submit = ({ value }) => {
    const val = kind === 'json' ? JSON.stringify(value) : value
    updateSystemSetting(id, val)
    handleClose()
  }

  return (
    <Modal
      size="lg"
      handleClose={handleClose}
      header={{ title: name }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            disabled={pristine}
            onClick={handleSubmit(submit)}
          />
        </>
      }>
      <form noValidate onSubmit={handleSubmit(submit)}>
        {kind === 'text' ? (
          <Field name="value" component={TextAreaField} />
        ) : (
          <Editor
            value={JSON.parse(value)}
            onChange={val => change('value', val)}
          />
        )}
      </form>
    </Modal>
  )
}

export default connect(null, dispatch => ({
  updateSystemSetting: (id, value) => dispatch(updateSystemSetting(id, value))
}))(reduxForm({ form: 'edit-system-setting' })(EditSystemSetting))
