import React from 'react'
import Loader from 'react-loader-spinner'
import { Main, Dashboard } from 'components'

import { ReactComponent as InfoIcon } from 'icons/info.svg'

const PanelLoader = () => (
  <Main
    page={{
      name: 'Trwa ładowanie ...',
      icon: <InfoIcon />
    }}>
    <Dashboard>
      <div className="panel-loader">
        <Loader type="Watch" color="#fd397a" height={40} width={40} />
      </div>
    </Dashboard>
  </Main>
)
export default PanelLoader
