import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, ModalDelete } from 'expano-components'
import { deleteCountry } from 'actions/countries'

const CountryRow = ({ id, code, deleteCountry }) => {
  const [is_modal_open, handleOpenModal] = useState(false)
  const handleDelete = () =>
    deleteCountry(id).then(() => handleOpenModal(false))

  return (
    <>
      {is_modal_open && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => handleOpenModal(false)}
          name={`kraj ${code}`}
        />
      )}
      <tr>
        <td>{code}</td>
        <td>
          <Button type="delete-bin" onClick={() => handleOpenModal(true)} />
        </td>
      </tr>
    </>
  )
}

export default connect(null, dispatch => ({
  deleteCountry: id => dispatch(deleteCountry(id))
}))(CountryRow)
