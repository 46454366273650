import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Label, Button } from "expano-components";
import { createChannel } from "actions/platforms";
import { Modal, SelectField } from "components";
import { ReactComponent as ShopIcon } from "icons/shop.svg";

const AddShop = ({
  handleClose,
  handleSubmit,
  change,
  shops,
  pristine,
  platform,
  createChannel,
}) => {
  const shop_options = shops.map(({ id, label_text, label_color, name }) => ({
    label: (
      <p>
        <Label type="fill" text={label_text} color={label_color} /> {name}
      </p>
    ),
    value: { id, label_text, label_color, name },
  }));
  const onSubmit = ({ shop }) =>
    createChannel({
      platform_id: platform.id,
      shop_id: shop.id,
    }).then(() => handleClose());

  return (
    <Modal
      handleClose={handleClose}
      header={{ title: "Dodaj sklep", icon: <ShopIcon /> }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="add-green"
            text="Dodaj"
            disabled={pristine}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }
    >
      <p style={styles.text}>
        Wybierz sklep na którym chcesz prowadzić sprzedaż
      </p>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Field
          label="Sklep"
          name="shop"
          component={SelectField}
          options={shop_options}
          change={change}
        />
      </form>
    </Modal>
  );
};

export default connect(
  ({ platform: { data: platform } }) => ({ platform }),
  (dispatch) => ({
    createChannel: (data) => dispatch(createChannel(data)),
  })
)(reduxForm({ form: "add-shop" })(AddShop));

const styles = {
  text: {
    fontSize: "13px",
    lineHeight: "20px",
    color: "#595D6E",
    paddingBottom: 10,
  },
  body: {
    padding: 30,
  },
};
