const initialState = {
  status: 'invalid',
  data: [],
  countries: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'VAT_RATES_LOADING':
      return {
        ...state,
        status: 'loading'
      }
    case 'VAT_RATES_SUCCESS':
      return {
        ...state,
        status: 'success',
        data: action.data,
        countries: action.countries
      }
    case 'VAT_RATES_FAILURE':
      return {
        ...state,
        status: 'failure'
      }
    case 'VAT_RATE_ADD':
      return {
        ...state,
        data: [...state.data, action.data]
      }
    case 'VAT_RATE_DELETE':
      return {
        ...state,
        data: [...state.data].filter(({ id }) => id !== action.id)
      }
    case 'VAT_RATE_UPDATE': {
      const data = [...state.data]
      const index = data.indexOf(data.find(({ id }) => id === action.data.id))
      if (index > -1) {
        data[index] = action.data
      }
      
      return {
        ...state,
        data
      }
    }

    default:
      return state
  }
}
