import React, { useState } from "react";
import { connect } from "react-redux";
import { Button, ModalDelete } from "expano-components";
import EditSubstituteModal from "./EditSubstituteModal";
import { deleteSubstitute } from "actions/products";

const VirtualLineItem = ({ id, position, deleteSubstitute, alternative }) => {
  const [show_modal, handleShow] = useState(false);
  const [show_delete_modal, handleShowDelete] = useState(false);

  return (
    <React.Fragment key={id}>
      {show_modal && (
        <EditSubstituteModal
          id={id}
          initialValues={{
            alternative_id: alternative.id,
          }}
          alternative={alternative}
          handleClose={() => handleShow(false)}
        />
      )}
      {show_delete_modal && (
        <ModalDelete
          handleDelete={() => deleteSubstitute(id)}
          handleClose={() => handleShowDelete(false)}
          name={`substytut ${alternative?.name}?`}
        />
      )}
      <li className="dashboard__list-item flex jcsb" key={id}>
        {position && <p className="flex-px-30">{position}.</p>}
        <p className="flex-px-150">{alternative?.sku}</p>
        <p className="flex-1 p-r-20">{alternative?.name}</p>
        <p className="flex-1 p-r-20">{alternative?.brand?.name}</p>
        <p className="flex-px-100">
          <Button type="edit" onClick={() => handleShow(true)} />
          <Button type="delete-bin" onClick={() => handleShowDelete(true)} />
        </p>
      </li>
    </React.Fragment>
  );
};

export default connect(null, (dispatch) => ({
  deleteSubstitute: (id) => dispatch(deleteSubstitute(id)),
}))(VirtualLineItem);
