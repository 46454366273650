import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, ModalDelete } from 'expano-components'
import { deleteProducerBrand } from 'actions/producers'
import EditBrandModal from './EditBrandModal'

const BrandRow = ({ id, name, deleteProducerBrand }) => {
  const [is_open_delete_modal, handleOpenDeleteModal] = useState(false)
  const [is_open_edit_modal, handleOpenEditModal] = useState(false)
  const handleDelete = () => deleteProducerBrand(id)
  return (
    <>
      {is_open_delete_modal && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => handleOpenDeleteModal(false)}
          name={`markę ${name}`}
        />
      )}
      {is_open_edit_modal && (
        <EditBrandModal
          id={id}
          initialValues={{ name }}
          handleClose={() => handleOpenEditModal(false)}
        />
      )}
      <tr>
        <td>{id}</td>
        <td>{name}</td>
        <td style={{ width: '120px' }}>
          <Button type="edit" onClick={() => handleOpenEditModal(true)} />
          <Button
            type="delete-bin"
            onClick={() => handleOpenDeleteModal(true)}
          />
        </td>
      </tr>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  deleteProducerBrand: id => dispatch(deleteProducerBrand(id))
})

export default connect(null, mapDispatchToProps)(BrandRow)
