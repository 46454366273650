import React, { useState, useEffect } from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { ModalDelete, Button, Label } from 'expano-components'
import {
  PanelLoader,
  Main,
  Dashboard,
  InputField,
  InputColorPickerField,
  SelectField,
  AsyncSelectField
} from 'components'
import AddShop from './modals/AddShop'
import {
  getPlatform,
  updatePlatform,
  deletePlatform,
  deleteChannel
} from 'actions/platforms'

import platform_type_options from 'utils/select_options/platform_types'
import getAvailableCountries from 'utils/select_options/available_countries'
import getAvailableCurrencies from 'utils/select_options/available_currencies'

import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as ShopIcon } from 'icons/shop.svg'
import { ReactComponent as PlatformIcon } from 'icons/platform.svg'

const PlatformEdit = ({
  pristine,
  valid,
  change,
  platform,
  history,
  match: {
    params: { id }
  },
  handleSubmit,
  getPlatform,
  updatePlatform,
  deleteChannel
}) => {
  const [is_open_delete_modal, setOpenDeleteModal] = useState(false)
  const [is_open_add_channel_modal, setOpenAddChannelModal] = useState(false)
  const [delete_channel, setDeleteChannel] = useState(null)

  useEffect(() => {
    getPlatform(id)
  }, [id])

  const onSubmit = values =>
    updatePlatform(id, values).catch(err => {
      throw new SubmissionError(err)
    })

  const handleDeleteShop = () =>
    deletePlatform(id).then(() => history.push('/shops'))

  const handleDeleteChannel = async () => {
    try {
      await deleteChannel(delete_channel.id)
    } catch (err) {
      console.log(err)
    } finally {
      setDeleteChannel(null)
    }
  }

  if (['invalid', 'loading'].includes(platform.status)) {
    return <PanelLoader />
  }

  if (platform.status === 'failure') {
    return <Redirect to="/shops" />
  }

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: 'Platformy', path: '/platforms' },
          {
            name: platform.data?.name
          }
        ],
        name: platform?.data?.name,
        icon: <PlatformIcon />,
        buttons: (
          <Button
            type="delete"
            text="Usuń platformę"
            onClick={() => setOpenDeleteModal(true)}
          />
        )
      }}>
      {is_open_delete_modal && (
        <ModalDelete
          handleDelete={handleDeleteShop}
          handleClose={() => setOpenDeleteModal(false)}
          name={`platformę ${platform?.data?.name}`}
        />
      )}
      {is_open_add_channel_modal && (
        <AddShop
          shops={platform.shops}
          handleClose={() => setOpenAddChannelModal(false)}
        />
      )}
      {delete_channel?.id && (
        <ModalDelete
          handleDelete={handleDeleteChannel}
          handleClose={() => setDeleteChannel(null)}
          name={`sklep ${delete_channel?.shop_name}`}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-2">
          <Dashboard
            header={{ icon: <InfoIcon />, title: 'Podstawowe dane' }}
            footer={
              <Button
                disabled={pristine || !valid}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="dashboard__form__submit">
                <div className="dashboard-wrapper-flex m-b-10">
                  <div className="column-half">
                    <Field
                      name="name"
                      label="Nazwa platformy"
                      component={InputField}
                      type="text"
                    />
                  </div>
                  <div className="column-half">
                    <div className="form-row grid-2">
                      <Field
                        name="label_text"
                        label="Skrócona nazwa"
                        component={InputField}
                        type="text"
                      />
                      <Field
                        name="label_color"
                        label="Kolor"
                        handleChange={color => change('label_color', color)}
                        component={InputColorPickerField}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="identifier"
                    label="Identyfikator platformy"
                    component={InputField}
                    type="text"
                  />
                  <Field
                    label="Typ platformy"
                    name="type"
                    component={SelectField}
                    options={platform_type_options}
                    defaultValue={platform_type_options.find(
                      ({ value }) => value === platform?.data?.type
                    )}
                    change={change}
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="country_code"
                    component={AsyncSelectField}
                    label="Kraj"
                    change={change}
                    getData={getAvailableCountries}
                    initValue={
                      platform?.data?.country_code
                        ? {
                            label: platform.data.country_code,
                            value: platform.data.country_code
                          }
                        : null
                    }
                  />
                  <Field
                    name="currency_code"
                    component={AsyncSelectField}
                    label="Waluta"
                    change={change}
                    getData={getAvailableCurrencies}
                    initValue={
                      platform?.data?.currency_code
                        ? {
                            label: platform.data.currency_code,
                            value: platform.data.currency_code
                          }
                        : null
                    }
                  />
                </div>
              </div>
            </form>
          </Dashboard>
        </div>
        <div className="column-1 column-1--container">
          <Dashboard
            header={{
              icon: <ShopIcon />,
              title: 'Sklepy'
            }}>
            {platform?.data?.channels?.length > 0 && (
              <ul className="dashboard__list">
                {platform?.data?.channels.map(({ id, shop }) => (
                  <li className="dashboard__list-item" key={id}>
                    <Label
                      type="bordered"
                      text={shop?.label_text}
                      color={`#${shop?.label_color}`}
                    />
                    <span className="platform-name">{shop.name}</span>
                    <span className="country country-label country-label--hidden" />
                    <Button
                      type="delete-bin"
                      onClick={() =>
                        setDeleteChannel({
                          id,
                          shop_name: shop.name
                        })
                      }
                    />
                  </li>
                ))}
              </ul>
            )}
            {platform.shops?.length > 0 && (
              <div className="dashboard__button-section">
                <Button
                  type="add"
                  text="Dodaj sklep"
                  onClick={() => setOpenAddChannelModal(true)}
                />
              </div>
            )}
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

const mapStateToProps = ({ platform }) => ({
  platform,
  initialValues: {
    name: platform?.data?.name,
    identifier: platform?.data?.identifier,
    country_code: platform?.data?.country_code,
    currency_code: platform?.data?.currency_code,
    label_color: platform?.data?.label_color,
    label_text: platform?.data?.label_text,
    type: platform?.data?.type
  }
})

const mapDispatchToProps = dispatch => ({
  updatePlatform: (id, data) => dispatch(updatePlatform(id, data)),
  deletePlatform: id => dispatch(deletePlatform(id)),
  getPlatform: id => dispatch(getPlatform(id)),
  deleteChannel: id => dispatch(deleteChannel(id))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'edit-platform', enableReinitialize: true })(PlatformEdit))
