import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { Button, MainTable, Main, Dashboard } from 'components'
import { parseQuery } from 'utils/api'
import {
  getWarehouses,
  filterWarehouses,
  toggleFilters
} from 'actions/warehouses'

import { ReactComponent as WarehouseIcon } from 'icons/warehouse.svg'

const WarehousesList = ({
  warehouses,
  toggleFilters,
  getWarehouses,
  history,
  location: { search }
}) => {
  const query = parseQuery(search)
  const [is_loaded, setLoaded] = useState(false)

  const sort = {
    'sort[column]': query['sort[column]'] || 'id',
    'sort[order]': query['sort[order]'] || 'asc'
  }

  useEffect(() => {
    getWarehouses({ ...query, ...sort })
    setLoaded(true)
  }, [search])

  useEffect(() => {
    if (is_loaded) {
      getWarehouses({ ...query, page: undefined })
    }
  }, [warehouses.filters.query])

  const { data, meta } = warehouses

  return (
    <Main
      page={{
        breadcrumbs: [{ name: 'Magazyny' }],
        name: 'Magazyny',
        icon: <WarehouseIcon />,
        buttons: (
          <Button type="add" text="Dodaj magazyn" path="/warehouses/new" />
        )
      }}>
      <Dashboard>
        <MainTable
          is_loading={['invalid', 'loading'].includes(warehouses.status)}
          is_searching={warehouses.status === 'searching'}
          filters_open={warehouses.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: 'ID', name: 'id' },
            { label: 'Nazwa', name: 'name' },
            { label: 'Ulica', name: 'street' },
            { label: 'Miasto', name: 'city' },
            { label: 'Kraj', name: 'country' }
          ]}
          empty_text="Brak magazynów"
          renderRow={({ id, name, street, city, country_code }) => (
            <tr
              key={id}
              style={{ cursor: 'pointer' }}
              onMouseDown={event => {
                if (event.button === 1) {
                  window.open(`/warehouses/${id}`, '_blank')
                }
              }}
              onClick={() => history.push(`/warehouses/${id}`)}>
              <td>{id}</td>
              <td>{name}</td>
              <td>{street}</td>
              <td>{city}</td>
              <td>{country_code}</td>
            </tr>
          )}
        />
      </Dashboard>
    </Main>
  )
}

const mapDispatchToProps = dispatch => ({
  getWarehouses: data => dispatch(getWarehouses(data)),
  filterWarehouses: data => dispatch(filterWarehouses(data)),
  toggleFilters: () => dispatch(toggleFilters())
})

export default connect(
  ({ warehouses }) => ({ warehouses }),
  mapDispatchToProps
)(WarehousesList)
