import { toastr } from 'react-redux-toastr'
import { client, handleErrors, stringifyQuery } from 'utils/api'

export const toggleFilters = () => dispatch => {
  dispatch({ type: 'CHANNELS_TOGGLE_FILTERS' })
}

export const filterChannels = query => async dispatch => {
  dispatch({ type: 'CHANNELS_SET_FILTERS_QUERY', query })
}

export const getChannels = ({ page = 1, per_page = 10, ...rest }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      status,
      filters: { query }
    } = getState().channels

    dispatch({
      type: status === 'invalid' ? 'CHANNELS_LOADING' : 'CHANNELS_SEARCHING'
    })

    const { data, meta } = await client.get(
      `/channels?${stringifyQuery({
        ...rest,
        context: 'ui_index',
        page,
        per_page,
        ...query
      })}`
    )
    dispatch({
      type: 'CHANNELS_SUCCESS',
      data,
      meta
    })
  } catch (error) {
    dispatch({ type: 'CHANNELS_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podczas pobierania')
  }
}

export const getChannel = id => async dispatch => {
  try {
    dispatch({ type: 'CHANNEL_LOADING' })
    const { data } = await client.get(`/channels/${id}?context=ui_show`)
    dispatch({
      type: 'CHANNEL_SUCCESS',
      data
    })
  } catch (error) {
    dispatch({ type: 'CHANNEL_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podzczas pobierania')
  }
}

export const createChannel = values =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post(`/channels`, values)
      resolve(data)
      toastr.success('Sukces', 'Kanał został dodany')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const deleteChannel = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/channels/${id}`)
      resolve()
      toastr.success('Sukces', 'Usunięto kanał')
    } catch (error) {
      reject(error)
      toastr.error('Błąd', 'Wystąpił błąd')
    }
  })
