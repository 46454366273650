import { toastr } from 'react-redux-toastr'
import { client, handleErrors } from 'utils/api'

export const getVatRates = () => async dispatch => {
  dispatch({ type: 'VAT_RATES_LOADING' })
  try {
    const [{ data: countries }, { data }] = await Promise.all([
      client.get('/available_countries?context=list&per_page=1000'),
      client.get(`/vat_rates?per_page=1000`)
    ])

    dispatch({
      type: 'VAT_RATES_SUCCESS',
      data,
      countries
    })
  } catch (error) {
    dispatch({ type: 'VAT_RATES_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podzczas pobierania')
  }
}

export const createVatRate = values => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id }
      } = await client.post(`/vat_rates`, values)
      const { data } = await client.get(`/vat_rates/${id}`, values)

      dispatch({
        type: 'VAT_RATE_ADD',
        data
      })
      resolve()
      toastr.success('Sukces', 'Dodano stawkę vat')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const updateVatRate = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/vat_rates/${id}`, values)
      const { data } = await client.get(`/vat_rates/${id}`, values)
      dispatch({
        type: 'VAT_RATE_UPDATE',
        data
      })
      resolve()
      toastr.success('Sukces', 'Zaktualizowano stawkę vat')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const deleteVatRate = id => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/vat_rates/${id}`)
      dispatch({
        type: 'VAT_RATE_DELETE',
        id
      })
      resolve()
      toastr.success('Sukces', 'Usunięto stawkę vat')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(error)
    }
  })
