import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'expano-components'
import { Dashboard } from 'components'
import ChannelListItem from './components/ChannelListItem'
import AddChannel from './components/AddChannel'

import { ReactComponent as ChannelIcon } from 'icons/channel.svg'

const Channels = ({ product_group, available_channels }) => {
  const [show_add_channel, showAddChannel] = useState(false)

  return (
    <>
      {show_add_channel && (
        <AddChannel handleClose={() => showAddChannel(false)} />
      )}
      <Dashboard header={{ title: 'Kanał', icon: <ChannelIcon /> }}>
        {product_group.channel_product_groups?.length > 0 && (
          <ul className="dashboard__list">
            {product_group.channel_product_groups.map(item => (
              <ChannelListItem key={item.id} {...item} />
            ))}
          </ul>
        )}
        {available_channels.length > 0 && (
          <div className="dashboard__button-section">
            <Button
              type="add"
              onClick={() => showAddChannel(true)}
              text="Dodaj kanał"
            />
          </div>
        )}
      </Dashboard>
    </>
  )
}

export default connect(
  ({ product_group: { data: product_group, available_channels } }) => ({
    product_group,
    available_channels
  })
)(Channels)
