import { client } from 'utils/api'

const getVatRatesOptions = (q, country_code = null) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/vat_rates?context=list${
          country_code ? `&q[country_code_eq]=${country_code}` : ''
        }&per_page=15${q ? `&q[name_matches]=%25${q}%25` : ''}`
      )
      resolve(
        data.map(({ id, name }) => ({
          label: name,
          value: id
        }))
      )
    } catch (error) {
      console.log(error)
      reject([])
    }
  })
}
export default getVatRatesOptions
