import React, { useState, useEffect } from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Button, ModalDelete, ImageUploader } from 'expano-components'
import { url } from 'utils/api'
import { Main, PanelLoader, Dashboard, InputField } from 'components'
import {
  getProducer,
  deleteProducer,
  updateProducer,
  uploadProducerImage
} from 'actions/producers'
import BrandRow from './components/BrandRow'
import AddBrandModal from './components/AddBrandModal'

import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as UsersIcon } from 'icons/users.svg'

const ProducersEdit = ({
  submitting,
  handleSubmit,
  pristine,
  producer,
  history,
  match: {
    params: { id }
  },
  getProducer,
  updateProducer,
  uploadProducerImage
}) => {
  const [is_open_delete_modal, handleOpenDeleteModal] = useState(false)
  const [is_open_add_brand_modal, handleOpenAddBrandModal] = useState(false)

  useEffect(() => {
    getProducer(id)
  }, [id])

  const onSubmit = values =>
    updateProducer(id, values).catch(err => {
      throw new SubmissionError(err)
    })

  const handleDelete = () =>
    deleteProducer(id).then(() => history.push('/producers'))

  const handleDeleteImage = () => updateProducer(id, { image: null })

  const handleUploadImage = file =>
    new Promise(async (resolve, reject) => {
      try {
        const body = new FormData()
        body.append('image', file)

        await uploadProducerImage(id, body)
        resolve()
      } catch (error) {
        reject(error)
      }
    })

  if (['invalid', 'loading'].includes(producer.status)) {
    return <PanelLoader />
  }

  if (producer.status === 'failure') {
    return <Redirect to="/producers" />
  }

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: 'Producenci', path: '/producers' },
          {
            name: producer.data?.name
          }
        ],
        name: producer?.data?.name,
        icon: <UsersIcon />,
        buttons: (
          <Button
            type="delete"
            text="Usuń producenta"
            onClick={() => handleOpenDeleteModal(true)}
          />
        )
      }}>
      {is_open_delete_modal && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => handleOpenDeleteModal(false)}
          name={`producent ${producer?.data?.name}`}
        />
      )}
      {is_open_add_brand_modal && (
        <AddBrandModal handleClose={() => handleOpenAddBrandModal(false)} />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{ title: 'Podstawowe dane', icon: <InfoIcon /> }}
            footer={
              <Button
                disabled={pristine || submitting}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="dashboard__form__submit">
                <Field
                  label="Nazwa producenta"
                  name="name"
                  component={InputField}
                />
                <Field
                  label="Identyfikator producenta"
                  name="identifier"
                  component={InputField}
                />
              </div>
            </form>
          </Dashboard>
          <Dashboard
            className="m-t-20"
            header={{ title: 'Logo', icon: <InfoIcon /> }}>
            <ImageUploader
              addText="dodaj logo"
              handleDelete={handleDeleteImage}
              handleUpload={handleUploadImage}
              url={url}
              images={
                producer?.data?.image_url
                  ? [{ path: producer.data.image_url }]
                  : []
              }
              limit={1}
            />
          </Dashboard>
        </div>
        <div className="column-half">
          <Dashboard
            header={{
              title: 'Marki',
              icon: <UsersIcon />,
              aside: (
                <Button
                  type="add"
                  text="Dodaj markę"
                  onClick={() => handleOpenAddBrandModal(true)}
                />
              )
            }}>
            <table className="table">
              <thead className="table__header table-head">
                <tr>
                  <th>ID</th>
                  <th>Nazwa</th>
                  <th>Działania</th>
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {producer?.data?.brands?.map(item => (
                  <BrandRow key={item.id} {...item} />
                ))}
              </tbody>
            </table>
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

const mapStateToProps = ({ producer }) => ({
  producer,
  initialValues: {
    name: producer?.data?.name,
    identifier: producer?.data?.identifier
  }
})
const mapDispatchToProps = dispatch => ({
  getProducer: id => dispatch(getProducer(id)),
  updateProducer: (id, data) => dispatch(updateProducer(id, data)),
  uploadProducerImage: (id, data) => dispatch(uploadProducerImage(id, data))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'edit-producer',
    enableReinitialize: true
  })(ProducersEdit)
)
