import { toastr } from 'react-redux-toastr'
import { client, handleErrors, stringifyQuery } from 'utils/api'

export const toggleFilters = () => dispatch =>
  dispatch({ type: 'PRODUCERS_TOGGLE_FILTERS' })

export const filterProducers = query => async dispatch =>
  dispatch({ type: 'PRODUCERS_SET_FILTERS_QUERY', query })

export const getProducers = ({ page = 1, per_page = 10, ...rest }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      status,
      filters: { query }
    } = getState().producers
    dispatch({
      type: status === 'invalid' ? 'PRODUCERS_LOADING' : 'PRODUCERS_SEARCHING'
    })
    const { data, meta } = await client.get(
      `/producers?${stringifyQuery({
        ...rest,
        page,
        per_page,
        context: 'ui_index',
        ...query
      })}`
    )
    dispatch({
      type: 'PRODUCERS_SUCCESS',
      data,
      meta
    })
  } catch (error) {
    dispatch({ type: 'PRODUCERS_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podczas pobierania')
  }
}

export const getProducer = id => async (dispatch, getState) => {
  try {
    const {
      producer: { status }
    } = getState()
    if (status === 'invalid') {
      dispatch({ type: 'PRODUCER_LOADING' })
    }
    const { data } = await client.get(`/producers/${id}?context=ui_show`)
    dispatch({
      type: 'PRODUCER_SUCCESS',
      data
    })
  } catch (error) {
    dispatch({ type: 'PRODUCER_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podzczas pobierania')
  }
}

export const createProducer = values =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post('/producers', values)

      resolve(data)
      toastr.success('Sukces', 'Dodano producenta')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const updateProducer = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/producers/${id}`, values)
      const { data } = await client.get(`/producers/${id}`)
      dispatch({
        type: 'PRODUCER_SUCCESS',
        data
      })
      resolve()
      toastr.success('Sukces', 'Producent został zaktualizowany')
    } catch (error) {
      reject(handleErrors(error?.response?.data?.errors))
      toastr.error('Błąd', 'Wystąpił błąd')
    }
  })

export const deleteProducer = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/producers/${id}`)
      resolve()
      toastr.success('Sukces', 'Usunięto producenta')
    } catch (err) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(err?.response?.data?.error)
    }
  })

export const uploadProducerImage = (id, image) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/producers/${id}`, image, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      dispatch(getProducer(id))
      resolve()
    } catch (error) {
      reject(error.response.data.error)
    }
  })

export const createProducerBrand = ({ name }) => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        producer: {
          data: { id }
        }
      } = getState()

      await client.post('/brands', { name, producer_id: id })

      dispatch(getProducer(id))

      toastr.success('Sukces', 'Dodano markę')
      resolve()
    } catch (err) {
      reject(err?.response?.data?.error)
      toastr.error('Błąd', 'Wystąpił błąd')
    }
  })

export const editProducerBrand = (brand_id, { name }) => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        producer: {
          data: { id }
        }
      } = getState()

      await client.put(`/brands/${brand_id}`, { name })

      dispatch(getProducer(id))

      resolve()
      toastr.success('Sukces', 'Zaktualizowano markę')
    } catch (err) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(err?.response?.data?.error)
    }
  })

export const deleteProducerBrand = brand_id => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        producer: {
          data: { id }
        }
      } = getState()
      await client.delete(`/brands/${brand_id}`)
      toastr.success('Sukces', 'Usunięto markę')
      dispatch(getProducer(id))
      resolve()
    } catch (err) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(err?.response?.data?.error)
    }
  })
