import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Button } from 'expano-components'

let timer

const Modal = ({ handleClose, size, header, footer, children }) => {
  const [is_active, handleActivate] = useState(false)

  useEffect(() => {
    timer = setTimeout(() => {
      handleActivate(true)
    }, 250)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return ReactDOM.createPortal(
    <div className="popup-wrapper">
      <div className={`popup ${size} ${is_active ? 'active' : ''}`}>
        <header className="popup__header">
          <div className="icon__container">{header?.icon}</div>
          <h2 className="dashboard__title heading">{header?.title}</h2>
          <Button type="close" onClick={handleClose} />
        </header>
        <section className="popup__body">{children}</section>
        <div className="popup__footer">{footer}</div>
      </div>
    </div>,
    document.querySelector('#root')
  )
}

export default Modal
