import { toastr } from 'react-redux-toastr'
import { client, handleErrors, stringifyQuery } from 'utils/api'

export const toggleFilters = () => dispatch => {
  dispatch({ type: 'WAREHOUSES_TOGGLE_FILTERS' })
}

export const filterWarehouses = query => async dispatch => {
  dispatch({ type: 'WAREHOUSES_SET_FILTERS_QUERY', query })
}

export const getWarehouses = ({ page = 1, per_page = 10, ...rest }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      status,
      filters: { query }
    } = getState().warehouses

    dispatch({
      type: status === 'invalid' ? 'WAREHOUSES_LOADING' : 'WAREHOUSES_SEARCHING'
    })
    const { data, meta } = await client.get(
      `/warehouses?${stringifyQuery({ ...rest, page, per_page, ...query })}`
    )
    dispatch({
      type: 'WAREHOUSES_SUCCESS',
      data,
      meta
    })
  } catch (error) {
    dispatch({ type: 'WAREHOUSES_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podczas pobierania')
  }
}

export const getWarehouse = id => async dispatch => {
  try {
    dispatch({ type: 'WAREHOUSE_LOADING' })
    const { data } = await client.get(`/warehouses/${id}`)
    dispatch({
      type: 'WAREHOUSE_SUCCESS',
      data: data
    })
  } catch (error) {
    dispatch({ type: 'WAREHOUSE_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podczzas pobierania')
  }
}

export const createWarehouse = values =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post(`/warehouses`, values)
      resolve(data)
      toastr.success('Sukces', 'Dodano magazyn')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const updateWarehouse = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/warehouses/${id}`, values)
      const { data } = await client.get(`/warehouses/${id}`)

      dispatch({
        type: 'WAREHOUSE_SUCCESS',
        data
      })

      toastr.success('Sukces', 'Magazyn został zaktualizowany')
      resolve()
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')

      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const deleteWarehouse = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/warehouses/${id}`)
      toastr.success('Sukces', 'Usunięto magazyn')
      resolve()
    } catch (error) {
      reject()
      toastr.error('Błąd', 'Wystąpił błąd')
    }
  })
