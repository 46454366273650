import React, { useState, useRef } from 'react'
import { SketchPicker } from 'react-color'

const InputColorPicker = ({ input, label, handleChange }) => {
  const [show_picker, handleShowPicker] = useState(false)
  const picker = useRef(null)
  const handleChangeComplete = ({ hex }) => {
    hex = hex.replace('#', '')
    handleChange(hex)
  }
  const handleClickOutside = event => {
    if (picker && !picker.current.contains(event.target)) {
      handleShowPicker(false)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }

  return (
    <div className="form-group color-picker">
      <div className="inp-wrapper">
        <label htmlFor={input?.name} className="label-form">
          {label}
        </label>
        <div className="inp-group">
          <button
            type="button"
            className="btn-pick-color"
            onClick={() => {
              handleShowPicker(true)
              document.addEventListener('mousedown', handleClickOutside)
            }}>
            {input?.value && (
              <div
                className="color-box"
                style={{ backgroundColor: `#${input.value}` }}
              />
            )}
          </button>
          <input
            {...input}
            onBlur={({ target: { value } }) => {
              input.onBlur()
              handleChange(value)
            }}
            id={input?.name}
            name={input?.name}
            type="text"
          />
          {show_picker && (
            <div className="picker-container" ref={picker}>
              <SketchPicker
                color={input.value}
                onChangeComplete={handleChangeComplete}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default InputColorPicker
