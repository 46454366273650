import React from 'react'
import { Select } from 'expano-components'

const SelectField = ({ input, label, meta, change, options, defaultValue }) => {
  return (
    <>
      <input type="hidden" {...input} />
      <Select
        label={label}
        options={options}
        defaultValue={defaultValue}
        handleChange={value => change(input.name, value)}
        meta={meta}
      />
    </>
  )
}

export default SelectField
