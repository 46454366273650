import React, { useState } from "react";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from "redux-form";
import { connect } from "react-redux";
import { Button, Select } from "expano-components";
import {
  Main,
  InputField,
  CheckField,
  SelectField,
  AsyncSelectField,
  Dashboard,
} from "components";
import { createProduct, getProductGroupData } from "actions/products";

import product_status_options from "utils/select_options/product_status_options";
import getProductGroupOptions from "utils/select_options/product_group_options";

import { ReactComponent as ProductIcon } from "icons/product.svg";
import { ReactComponent as InfoIcon } from "icons/info.svg";

const selector = formValueSelector("create-product");

const ProductNew = ({
  history,
  handleSubmit,
  change,
  pristine,
  product_status,
  is_bundle,
  is_virtual,
}) => {
  const [category, setCategory] = useState(null);
  const [producer, setProducer] = useState(null);
  const [brand, setBrand] = useState(null);

  const onSubmit = (values) => {
    const send_data = { ...values };
    if (product_status !== "active") {
      delete send_data.is_difficult_to_deliver;
    }
    return createProduct(send_data)
      .then(({ id }) => history.push(`/products/${id}`))
      .catch((err) => {
        throw new SubmissionError(err);
      });
  };

  const checkProductCategoryProducerAndBrand = async (id) => {
    const { is_bundle, category, producer, brand } = await getProductGroupData(
      id
    );

    //is_bundle
    change("is_bundle", is_bundle);

    //category
    setCategory(
      category?.id
        ? {
            label: `[${category.identifier}] ${category.name}`,
            value: category.id,
          }
        : null
    );
    change("category_id", category?.id ? category.id : null);

    //producer
    setProducer(
      producer?.id ? { label: producer.name, value: producer.id } : null
    );
    change("producer_id", producer?.id ? producer.id : null);

    //brand
    setBrand(brand?.id ? { label: brand.name, value: brand.id } : null);
    change("brand_id", brand?.id ? brand.id : null);
  };

  return (
    <Main
      page={{
        name: "Produkty",
        icon: <ProductIcon />,
        breadcrumbs: [
          { name: "Produkty", path: "/products" },
          {
            name: "Dodaj produkt",
          },
        ],
      }}
    >
      <div className="dashboard-wrapper-flex">
        <div className="column-2">
          <Dashboard
            header={{ icon: <InfoIcon />, title: "Podstawowe dane" }}
            footer={
              <Button
                disabled={pristine}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }
          >
            <form noValidate>
              <div className="dashboard__form__submit">
                <Field
                  name="product_group_id"
                  component={AsyncSelectField}
                  label="Produkt grupowy"
                  change={change}
                  afterChange={(id) => checkProductCategoryProducerAndBrand(id)}
                  getData={getProductGroupOptions}
                />
                <Field
                  name="name"
                  label="Nazwa produktu"
                  component={InputField}
                  type="text"
                />
                <div className="form-row grid-2">
                  <Select
                    defaultValue={category}
                    value={category}
                    isDisabled={true}
                    label="Kategoria"
                  />
                  <Select
                    defaultValue={producer}
                    value={producer}
                    isDisabled={true}
                    label="Producent"
                  />
                </div>
                <div className="form-row grid-2">
                  <Select
                    defaultValue={brand}
                    value={brand}
                    isDisabled={true}
                    label="Marka"
                  />
                  <Field
                    name="producer_code"
                    label="Kod producenta"
                    component={InputField}
                    type="text"
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="sku"
                    label="SKU"
                    component={InputField}
                    type="text"
                  />
                  <Field
                    name="ean"
                    label="Ean"
                    component={InputField}
                    type="text"
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    label="Status produktu"
                    name="status"
                    component={SelectField}
                    options={product_status_options}
                    change={change}
                    defaultValue={product_status_options.find(
                      ({ value }) => value === product_status
                    )}
                  />
                  <Field
                    label="Produkt jest wirtualny"
                    name="is_virtual"
                    info="Posida substytucyjne produkty"
                    component={CheckField}
                    disabled={is_bundle}
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    label="Produkt jest bundlem"
                    info="Powstaje z kompletacji innych produktów"
                    name="is_bundle"
                    component={CheckField}
                    disabled={is_virtual}
                  />
                  {product_status === "active" ? (
                    <Field
                      name="is_difficult_to_deliver"
                      label="Synchronizacja stanów"
                      info="Jeśli tak, zsynchronizowane zostaną dokładne stany magazynowe."
                      component={CheckField}
                    />
                  ) : (
                    <div className="form-group">
                      <div className="check-group disabled">
                        <input
                          disabled
                          name="is_difficult_to_deliver_readonly"
                          type="checkbox"
                          checked
                        />
                        <label
                          htmlFor="is_difficult_to_deliver_readonly"
                          className="label-form"
                        >
                          Synchronizacja stanów
                        </label>
                        <p className="additional-info">
                          Jeśli tak, zsynchronizowane zostaną dokładne stany
                          magazynowe.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

export default connect((state) => ({
  initialValues: {
    category_id: null,
    producer_id: null,
    brand_id: null,
    is_bundle: false,
    is_virtual: false,
    status: "active",
  },
  product_status: selector(state, "status") || "active",
  is_bundle: selector(state, "is_bundle"),
  is_virtual: selector(state, "is_virtual"),
}))(
  reduxForm({ form: "create-product", enableReinitialize: true })(ProductNew)
);
