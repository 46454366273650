import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'
import { reducer as toastr } from 'react-redux-toastr'
import sidebar from './sidebar'
import allowed_ips from './allowed_ips'
import allowed_ip from './allowed_ip'
import currencies from './currencies'
import countries from './countries'
import categories from './categories'
import category from './category'
import platforms from './platforms'
import platform from './platform'
import shops from './shops'
import shop from './shop'
import channels from './channels'
import channel from './channel'
import producers from './producers'
import producer from './producer'
import warehouses from './warehouses'
import warehouse from './warehouse'
import speditors from './speditors'
import speditor from './speditor'
import products from './products'
import product from './product'
import users from './users'
import user from './user'
import product_groups from './product_groups'
import product_group from './product_group'
import current_user from './current_user'
import vat_rates from './vat_rates'
import system_settings from './system_settings'
import brands from './brands'
import brand from './brand'

export default combineReducers({
  current_user,
  allowed_ips,
  allowed_ip,
  brands,
  brand,
  categories,
  category,
  channels,
  channel,
  countries,
  currencies,
  platforms,
  platform,
  producers,
  producer,
  products,
  product,
  product_groups,
  product_group,
  shops,
  shop,
  speditors,
  speditor,
  system_settings,
  toastr,
  users,
  user,
  warehouses,
  warehouse,
  vat_rates,
  form,
  sidebar
})
