import React, { useState } from "react";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from "redux-form";
import { connect } from "react-redux";
import { Button, Select } from "expano-components";
import {
  InputField,
  AsyncSelectField,
  CheckField,
  SelectField,
  Dashboard,
} from "components";
import { updateProduct } from "actions/products";
import { getProductGroupData } from "actions/products";

import product_status_options from "utils/select_options/product_status_options";
import getProductGroupOptions from "utils/select_options/product_group_options";

import { ReactComponent as InfoIcon } from "icons/info.svg";

const selector = formValueSelector("edit-product");

const BasicForm = ({
  handleSubmit,
  updateProduct,
  product,
  change,
  pristine,
  product_status,
  is_bundle,
  is_virtual,
}) => {
  const { product_group } = product;

  const [category, setCategory] = useState(
    product.category?.id
      ? {
          label: `[${product.category.identifier}] ${product.category.name}`,
          value: product.category.id,
        }
      : null
  );
  const [producer, setProducer] = useState(
    product.producer?.id
      ? { label: product.producer.name, value: product.producer.id }
      : null
  );
  const [brand, setBrand] = useState(
    product.brand?.id
      ? { label: product.brand.name, value: product.brand.id }
      : null
  );

  const submit = (values) => {
    const send_data = { ...values };
    if (product_status !== "active") {
      delete send_data.is_difficult_to_deliver;
    }
    return updateProduct(product.id, send_data).catch((err) => {
      throw new SubmissionError(err);
    });
  };

  const checkProductCategoryProducerAndBrand = async (id) => {
    const { category, producer, brand } = await getProductGroupData(id);

    //category
    setCategory(
      category?.id ? { label: category.name, value: category.id } : null
    );
    change("category_id", category?.id ? category.id : null);

    //producer
    setProducer(
      producer?.id ? { label: producer.name, value: producer.id } : null
    );
    change("producer_id", producer?.id ? producer.id : null);

    //brand
    setBrand(brand?.id ? { label: brand.name, value: brand.id } : null);
    change("brand_id", brand?.id ? brand.id : null);
  };
  return (
    <Dashboard
      header={{ title: "Podstawowe dane", icon: <InfoIcon /> }}
      footer={
        <Button
          disabled={pristine}
          type="save"
          onClick={handleSubmit(submit)}
        />
      }
    >
      <form noValidate onSubmit={handleSubmit(submit)}>
        <div className="dashboard__form__submit">
          <Field
            name="product_group_id"
            component={AsyncSelectField}
            label="Produkt grupowy"
            change={change}
            getData={getProductGroupOptions}
            afterChange={checkProductCategoryProducerAndBrand}
            initValue={
              product_group
                ? {
                    label: `[${product_group.sku}] ${product_group.name}`,
                    value: product_group.id,
                  }
                : null
            }
          />
          <Field
            name="name"
            label="Nazwa produktu"
            component={InputField}
            type="text"
          />
          <div className="form-row grid-2">
            <Select
              defaultValue={category}
              value={category}
              isDisabled={true}
              label="Kategoria"
            />
            <Select
              defaultValue={producer}
              value={producer}
              isDisabled={true}
              label="Producent"
            />
          </div>
          <div className="form-row grid-2">
            <Select
              defaultValue={brand}
              value={brand}
              isDisabled={true}
              label="Marka"
            />
            <Field
              name="producer_code"
              label="Kod producenta"
              component={InputField}
              type="text"
            />
          </div>
          <div className="form-row grid-2">
            <Field name="sku" label="SKU" component={InputField} type="text" />
            <Field name="ean" label="Ean" component={InputField} type="text" />
          </div>
          <div className="form-row grid-2 m-b-20">
            <Field
              label="Status produktu"
              name="status"
              component={SelectField}
              options={product_status_options}
              change={change}
              defaultValue={product_status_options.find(
                ({ value }) => value === product.status
              )}
            />
            <Field
              label="Produkt jest wirtualny"
              name="is_virtual"
              info="Posida substytucyjne produkty"
              component={CheckField}
              disabled={is_bundle}
            />
          </div>
          <div className="form-row grid-2">
            <Field
              label="Produkt jest bundlem"
              info="Powstaje z kompletacji innych produktów"
              name="is_bundle"
              component={CheckField}
              disabled={is_virtual}
            />
            {product_status === "active" ? (
              <Field
                name="is_difficult_to_deliver"
                label="Synchronizacja stanów"
                info="Jeśli tak, zsynchronizowane zostaną dokładne stany magazynowe."
                component={CheckField}
              />
            ) : (
              <div className="form-group">
                <div className="check-group disabled">
                  <input
                    disabled
                    name="is_difficult_to_deliver_readonly"
                    type="checkbox"
                    checked
                  />
                  <label
                    htmlFor="is_difficult_to_deliver_readonly"
                    className="label-form"
                  >
                    Synchronizacja stanów
                  </label>
                  <p className="additional-info">
                    Jeśli tak, zsynchronizowane zostaną dokładne stany
                    magazynowe.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </Dashboard>
  );
};

export default connect(
  (state) => ({
    a: console.log(state.product),
    product: state.product.data,
    product_status: selector(state, "status"),
    is_bundle:
      selector(state, "is_bundle") ||
      state.product?.data?.product_components_as_bundle?.length > 0,
    is_virtual:
      selector(state, "is_virtual") ||
      state.product?.data?.product_substitutes_as_virtual?.length > 0,
    initialValues: {
      name: state.product.data?.name,
      category_id: state.product.data?.category?.id,
      sku: state.product.data?.sku,
      ean: state.product.data?.ean,
      producer_id: state.product.data?.producer?.id,
      brand_id: state.product.data?.brand?.id,
      product_group_id: state.product.data?.product_group?.id,
      status: state.product.data?.status,
      producer_code: state.product.data?.producer_code,
      is_bundle: state.product.data?.is_bundle,
      is_virtual: state.product.data?.is_virtual,
      is_difficult_to_deliver: state.product.data?.is_difficult_to_deliver,
    },
  }),
  (dispatch) => ({
    updateProduct: (id, data) => dispatch(updateProduct(id, data)),
  })
)(
  reduxForm({
    form: "edit-product",
    enableReinitialize: true,
  })(BasicForm)
);
