import React from 'react'
import Select from 'react-select/async'
import debounce from 'debounce-promise'

const AsyncSelect = ({
  change,
  isDisabled,
  getData,
  afterChange = null,
  label,
  meta,
  input: { name },
  initValue,
  choosedValues = []
}) => {
  const isError = meta && meta.touched && meta.invalid
  const customStyles = {
    control: styles => ({
      ...styles,
      borderColor: '#E2E5EC',
      borderWidth: 1,
      borderRadius: 4,
      minHeight: 30
    }),
    option: provided => ({
      ...provided,
      fontFamily: 'Poppins',
      fontSize: '13px'
    }),
    singleValue: provided => ({
      ...provided,
      fontFamily: 'Poppins',
      fontSize: '13px',
      padding: 0
    }),
    valueContainer: provided => ({
      ...provided,
      padding: '0 10px'
    }),
    indicatorSeparator: styles => ({ ...styles, display: 'none' }),
    placeholder: provided => ({
      ...provided,
      fontFamily: 'Poppins',
      fontSize: '13px'
    }),
    multiValue: provided => ({
      ...provided,
      flexDirection: 'row-reverse',
      backgroundColor: '#F3F6F9'
    }),
    multiValueLabel: provided => ({
      ...provided,
      padding: '0 6px',
      fontSize: 10,
      color: '#595D6E'
    })
  }
  return (
    <div className={`form-group ${isError ? 'error' : ''}`}>
      <div className="inp-wrapper">
        {label && <label>{label}</label>}
        <Select
          onChange={({ value }) => {
            change(name, value)
            if (!!afterChange) {
              afterChange(value)
            }
          }}
          styles={customStyles}
          defaultOptions
          cacheOptions
          isDisabled={isDisabled}
          placeholder={'Wybierz...'}
          theme={theme => ({
            ...theme,
            fontFamily: 'Poppins',
            borderRadius: 4,
            colors: {
              ...theme.colors,
              neutral20: '#E2E5EC'
            },
            error: meta && meta.touched && meta.invalid
          })}
          loadOptions={debounce((a, b) => getData(a, b, choosedValues), 500, {
            leading: false,
            accumulate: false
          })}
          defaultValue={initValue}
        />
        {isError && meta.error && <p className="inp-error">{meta.error}</p>}
      </div>
    </div>
  )
}

export default AsyncSelect
