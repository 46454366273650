const initialState = {
  status: 'invalid'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SPEDITOR_RESET':
      return {
        status: 'invalid'
      }
    case 'SPEDITOR_LOADING':
      return {
        ...state,
        status: 'loading'
      }
    case 'SPEDITOR_SUCCESS':
      return {
        ...state,
        status: 'success',
        data: action.data
      }
    case 'SPEDITOR_FAILURE':
      return {
        ...state,
        status: 'failure',
        err: action.err
      }
    default:
      return state
  }
}
