import React, { PureComponent } from 'react'
import { getCategoryByAncestry } from 'actions/categories'

class TreeItem extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      isLoading: true,
      isLoaded: false,
      categories: null,
      hasChildren: true
    }
  }
  getData = () => {
    if (this.state.isLoaded) return false
    getCategoryByAncestry(
      `${this.props.ancestry ? `${this.props.ancestry}/` : ''}${this.props.id}`
    )
      .then(categories => {
        this.setState({
          categories,
          isLoading: false,
          isLoaded: true,
          hasChildren: categories.length > 0
        })
      })
      .catch(err => {
        console.log(err)
        this.setState({
          categories: null,
          isLoading: false,
          isLoaded: true,
          hasChildren: false
        })
      })
  }
  render() {
    const { row_data, path, history, level, data, ...rest } = this.props
    const { isOpen, isLoading, categories, hasChildren } = this.state
    return (
      <li
        className="tree__item"
        onClick={e => {
          e.stopPropagation()
          history.push(`${path}/${rest.id}`)
        }}>
        <table className="table">
          {level !== 0 && (
            <div
              className="tree__line tree__line--horizontal"
              style={{ left: `${level * 32 - 10}px` }}
            />
          )}
          <tbody className="table__body">
            <tr>
              <td
                style={{
                  paddingLeft: `${level === 0 ? 18 : level * 32 + 18}px`,
                  width: `${100 / (row_data.length + 2)}%`
                }}>
                {hasChildren ? (
                  <>
                    <button
                      className="button button__tree"
                      onClick={e => {
                        e.stopPropagation()
                        if (!isOpen) {
                          this.getData()
                        }
                        this.setState(({ isOpen }) => {
                          return {
                            isOpen: !isOpen
                          }
                        })
                      }}>
                      {isOpen ? (
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <rect
                            x="0.5"
                            y="0.5"
                            width="9"
                            height="9"
                            fill="white"
                            stroke="#5D78FF"
                          />
                          <line x1="3" y1="5" x2="7" y2="5" stroke="#5D78FF" />
                        </svg>
                      ) : (
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <rect
                            x="0.5"
                            y="0.5"
                            width="9"
                            height="9"
                            fill="white"
                            stroke="#5D78FF"
                          />
                          <line x1="3" y1="5" x2="7" y2="5" stroke="#5D78FF" />
                          <line x1="5" y1="7" x2="5" y2="3" stroke="#5D78FF" />
                        </svg>
                      )}
                    </button>
                    <span className="tree-span tree-span--hide tree-span-after">
                      {rest.name}
                    </span>
                  </>
                ) : (
                  rest.name
                )}
              </td>
              {row_data &&
                row_data.length > 0 &&
                row_data.map((attr, index) => (
                  <td
                    key={index}
                    style={{ width: `${100 / (row_data.length + 2)}%` }}>
                    {data[attr] && attr === 'products'
                      ? data[attr].length
                      : data[attr]}
                  </td>
                ))}
            </tr>
          </tbody>
        </table>
        {isOpen && categories && categories.length > 0 && !isLoading && (
          <ul className={`tree__list`}>
            <div
              className="tree__line tree__line--vertical"
              style={{
                left: `${level * 32}px`,
                height: categories.length === 1 ? '65px' : 'initial'
              }}
            />
            {categories.map(list_el => (
              <TreeItem
                history={history}
                key={list_el.id}
                name={list_el.name}
                path={path}
                data={list_el}
                id={list_el.id}
                row_data={row_data}
                ancestry={list_el.ancestry}
                level={level + 1}
              />
            ))}
          </ul>
        )}
      </li>
    )
  }
}

export default TreeItem
