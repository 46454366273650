import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'expano-components'
import { Dashboard } from 'components'
import BundleLineItem from './components/BundleLineItem'
import AddBundleModal from './components/AddBundleModal'

import { ReactComponent as ProductIcon } from 'icons/product.svg'

const Bundles = ({ product_components_as_bundle }) => {
  const [show_add_bundle, setShowAddBundle] = useState(false)
  return (
    <>
      {show_add_bundle && (
        <AddBundleModal handleClose={() => setShowAddBundle(false)} />
      )}
      <Dashboard
        header={{
          title: 'Produkty powiązane (bundle)',
          icon: <ProductIcon />
        }}>
        {product_components_as_bundle?.length > 0 && (
          <ul className="dashboard__list">
            {product_components_as_bundle.map(item => (
              <BundleLineItem key={item.id} {...item} />
            ))}
          </ul>
        )}
        <div className="dashboard__button-section">
          <Button
            type="add"
            onClick={() => setShowAddBundle(true)}
            text="Dodaj produkt"
          />
        </div>
      </Dashboard>
    </>
  )
}

const mapStateToProps = ({ product }) => ({
  product_components_as_bundle: product?.data?.product_components_as_bundle
})

export default connect(mapStateToProps, null)(Bundles)
