import React from 'react'
import { Link } from 'react-router-dom'
import { PagePanel } from 'expano-components'

const Main = ({ children, page }) => {
  return (
    <div className="main-dashboard">
      <PagePanel app="root" Link={Link} data={page} />
      <div className="main-dashboard__container">{children}</div>
    </div>
  )
}

export default Main
