import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { PanelCurrency, PanelCountry } from './components'
import { Main, PanelLoader } from 'components'
import { getCurrencies } from 'actions/currencies'
import { getCountries } from 'actions/countries'
import { ReactComponent as EuroIcon } from 'icons/euro.svg'

const CurrencyCountry = ({
  currencies,
  countries,
  getCurrencies,
  getCountries
}) => {
  useEffect(() => {
    getCurrencies()
    getCountries()
  }, [])
  if (
    ['invalid', 'loading'].includes(currencies.status) ||
    ['invalid', 'loading'].includes(countries.status)
  ) {
    return <PanelLoader />
  }

  return (
    <Main
      page={{
        breadcrumbs: [{ name: 'Waluta/Kraj' }],
        name: 'Waluta/Kraj',
        icon: <EuroIcon />
      }}>
      <div className="dashboard-wrapper-flex">
        <PanelCurrency />
        <PanelCountry />
      </div>
    </Main>
  )
}

export default connect(
  ({ currencies, countries }) => ({
    currencies,
    countries
  }),
  dispatch => ({
    getCurrencies: () => dispatch(getCurrencies()),
    getCountries: () => dispatch(getCountries())
  })
)(CurrencyCountry)
