import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button, Label } from "expano-components";
import { createProductGroupChannels } from "actions/product_groups";
import { Modal, MultiSelect } from "components";

import { ReactComponent as ChannelIcon } from "icons/channel.svg";

const SelectField = ({ input, meta, handleChange, options }) => {
  return (
    <div className="form-group">
      <input type="hidden" {...input} />
      <MultiSelect
        label="Wybierz sklep na którym chcesz prowadzić sprzedaż"
        options={options}
        handleChange={handleChange}
        meta={meta}
      />
    </div>
  );
};

const AddChannel = ({
  handleClose,
  handleSubmit,
  createProductGroupChannels,
  change,
  pristine,
  available_channels,
}) => {
  const onSubmit = ({ channel_ids }) =>
    createProductGroupChannels(channel_ids).then(handleClose);

  return (
    <Modal
      size="lg"
      handleClose={handleClose}
      header={{
        title: "Dodaj kanał",
        icon: <ChannelIcon />,
      }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="add-green"
            text="Dodaj"
            disabled={pristine}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }
    >
      <p className="text-bold text-danger">UWAGA!!</p>
      <p className="text-bold text-danger m-b-20">Niezalecane jest dodawanie kanałów spoza przypisanych do marki!</p>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="channel_ids"
          component={SelectField}
          handleChange={(items) =>
            items?.length > 0
              ? change(
                  "channel_ids",
                  items.map(({ value }) => value)
                )
              : null
          }
          options={available_channels.map(
            ({ id, identifier, shop, platform }) => ({
              label: (
                <p>
                  <Label
                    type="double"
                    left={{
                      text: shop?.label_text,
                      color: shop?.label_color,
                    }}
                    right={{
                      text: platform?.label_text,
                      color: `#${platform?.label_color}`,
                    }}
                  />
                  {identifier}
                </p>
              ),
              value: id,
            })
          )}
        />
      </form>
    </Modal>
  );
};

export default connect(
  ({ current_user, product_group }) => ({
    current_user,
    available_channels: product_group.available_channels,
  }),
  (dispatch) => ({
    createProductGroupChannels: (data) =>
      dispatch(createProductGroupChannels(data)),
  })
)(reduxForm({ form: "add-channel" })(AddChannel));
