import React from 'react'

const Dashboard = ({ className, header, footer, children }) => (
  <div className={`dashboard${className ? ` ${className}` : ''}`}>
    {header?.title && (
      <header className="dashboard__header">
        {header?.icon && <div className="icon__container">{header.icon}</div>}
        {header?.title && (
          <h2 className="dashboard__title heading">{header.title}</h2>
        )}
        {header?.aside}
      </header>
    )}
    {children}
    {footer && <div className="dashboard__form__footer">{footer}</div>}
  </div>
)

export default Dashboard
