import React from 'react'
import TreeItem from './TreeItem'

const Tree = ({ head, row_data = [], data, path, history }) => {
  return data.length > 0 ? (
    <>
      {head?.length > 0 && (
        <table className="table">
          <thead className="table__header table-head">
            <tr>
              {head.map((item, index) => (
                <th
                  key={index}
                  style={{
                    minWidth: index === 0 ? '400px' : 'initial',
                    width: `${100 / head.length}%`
                  }}>
                  {item}
                </th>
              ))}
            </tr>
          </thead>
        </table>
      )}
      {data?.length > 0 && (
        <div className="tree">
          <ul className="tree__list">
            {data.map((list_el, index) => (
              <TreeItem
                key={list_el.id}
                data={list_el}
                name={list_el.name}
                id={list_el.id}
                history={history}
                row_data={row_data}
                level={0}
                tree_box="tree-minus"
                path={path}
                ancestry={list_el.ancestry}
              />
            ))}
          </ul>
        </div>
      )}
    </>
  ) : (
    <p style={{ padding: '0 30px' }}>Brak</p>
  )
}

export default Tree
