import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Button } from 'expano-components'
import { Main, Dashboard, InputField } from 'components'
import { createProducer } from 'actions/producers'

import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as UsersIcon } from 'icons/users.svg'

const ProducerNew = ({ handleSubmit, pristine, history }) => {
  const onSubmit = values => {
    return createProducer(values)
      .then(({ id }) => history.push(`/producers/${id}`))
      .catch(err => {
        throw new SubmissionError(err)
      })
  }

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: 'Producenci', path: '/producers' },
          {
            name: 'Dodaj producenta'
          }
        ],
        name: 'Dodaj producenta',
        icon: <UsersIcon />
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{ title: 'Podstawowe dane', icon: <InfoIcon /> }}
            footer={
              <Button
                disabled={pristine}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="dashboard__form__submit">
                <Field
                  name="name"
                  label="Nazwa producenta"
                  component={InputField}
                  type="text"
                />
                <Field
                  name="identifier"
                  label="Identyfikator producenta"
                  component={InputField}
                  type="text"
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

export default reduxForm({ form: 'create-producer', enableReinitialize: true })(
  ProducerNew
)
