import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Button } from 'expano-components'
import { createCountry } from 'actions/countries'
import { Modal, InputField } from 'components'
import { ReactComponent as CountryIcon } from 'icons/country.svg'

const AddCountry = ({ handleClose, handleSubmit, pristine, createCountry }) => {
  const onSubmit = values =>
    createCountry(values)
      .then(handleClose)
      .catch(err => {
        throw new SubmissionError(err)
      })

  return (
    <Modal
      handleClose={handleClose}
      header={{ title: 'Dodaj kraj', icon: <CountryIcon /> }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            disabled={pristine}
            type="add-green"
            onClick={handleSubmit(onSubmit)}
            text="Dodaj"
          />
        </>
      }>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field name="code" label="Kraj" component={InputField} type="text" />
      </form>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  createCountry: data => dispatch(createCountry(data))
})
export default connect(
  null,
  mapDispatchToProps
)(reduxForm({ form: 'create-currency' })(AddCountry))
