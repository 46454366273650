import { client } from 'utils/api'

const getBrandOptions = (q = null, producer_id = null) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/brands?context=list&per_page=15${
          q ? `&q[name_matches]=%25${q}%25` : ''
        }${producer_id ? `&q[producer_id_eq]=${producer_id}` : ''}`
      )
      resolve(
        data.map(({ id, name }) => ({
          label: name,
          value: id
        }))
      )
    } catch (error) {
      console.log(error)
      reject([])
    }
  })

export default getBrandOptions
