import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Button } from 'expano-components'
import { createCurrency } from 'actions/currencies'
import { Modal, InputField } from 'components'
import { ReactComponent as EuroIcon } from 'icons/euro.svg'

const AddCurrency = ({
  handleClose,
  handleSubmit,
  pristine,
  createCurrency
}) => {
  const onSubmit = values =>
    createCurrency(values)
      .then(handleClose)
      .catch(err => {
        throw new SubmissionError(err)
      })

  return (
    <Modal
      handleClose={handleClose}
      header={{ title: 'Dodaj walutę', icon: <EuroIcon /> }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            disabled={pristine}
            type="add-green"
            onClick={handleSubmit(onSubmit)}
            text="Dodaj"
          />
        </>
      }>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field name="code" label="Waluta" component={InputField} type="text" />
      </form>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  createCurrency: data => dispatch(createCurrency(data))
})
export default connect(
  null,
  mapDispatchToProps
)(reduxForm({ form: 'create-currency' })(AddCurrency))
