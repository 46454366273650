import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Button } from 'expano-components'
import { updateBundle } from 'actions/products'
import { AsyncSelectField, InputField, Modal } from 'components'
import getProductOptions from 'utils/select_options/product_options'

import { ReactComponent as ProductIcon } from 'icons/product.svg'

const EditBundleModal = ({
  updateBundle,
  handleSubmit,
  change,
  pristine,
  handleClose,
  id,
  component
}) => {
  const onSubmit = values =>
    updateBundle(id, values)
      .then(handleClose)
      .catch(err => {
        throw new SubmissionError(err)
      })

  return (
    <Modal
      handleClose={handleClose}
      header={{ title: 'Aktualizuj bundle', icon: <ProductIcon /> }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            text="Zapisz"
            disabled={pristine}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Field
          label="Wybierz produkt, który będzie częścią bundla:"
          name="component_id"
          component={AsyncSelectField}
          change={change}
          getData={getProductOptions}
          initValue={
            component
              ? {
                  label: `[${component?.sku}] ${component?.name}`,
                  value: component?.id
                }
              : null
          }
        />
        <Field
          name="quantity"
          type="number"
          component={InputField}
          label="Ilość:"
        />
      </form>
    </Modal>
  )
}

export default connect(null, dispatch => ({
  updateBundle: (id, data) => dispatch(updateBundle(id, data))
}))(
  reduxForm({ form: 'edit-bundle', enableReinitialize: true })(EditBundleModal)
)
