import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { Dashboard } from "components";
import AddChannel from "./components/AddChannel";
import ChannelListItem from "./components/ChannelListItem";

import { ReactComponent as ChannelIcon } from "icons/channel.svg";

const BrandChannels = ({ brand_channels }) => {
  const [show_add_channel, showAddChannel] = useState(false);

  return (
    <>
      {show_add_channel && (
        <AddChannel handleClose={() => showAddChannel(false)} />
      )}
      <Dashboard
        className="m-b-20"
        header={{ title: "Kanały", icon: <ChannelIcon /> }}
      >
        {brand_channels?.length > 0 && (
          <ul className="dashboard__list">
            {brand_channels.map((item) => (
              <ChannelListItem key={item.id} {...item} />
            ))}
          </ul>
        )}

        <div className="dashboard__button-section">
          <Button
            type="add"
            onClick={() => showAddChannel(true)}
            text="Dodaj kanał"
          />
        </div>
      </Dashboard>
    </>
  );
};

export default connect(({ brand: { data } }) => ({
  brand_channels: data.brand_channels,
}))(BrandChannels);
