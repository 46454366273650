import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, ModalDelete } from 'expano-components'
import { deleteCurrency } from 'actions/currencies'

const CurrencyRow = ({ id, code, deleteCurrency }) => {
  const [is_modal_open, handleOpenModal] = useState(false)
  const handleDelete = () =>
    deleteCurrency(id).then(() => handleOpenModal(false))

  return (
    <>
      {is_modal_open && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => handleOpenModal(false)}
          name={`walutę ${code}`}
        />
      )}
      <tr>
        <td>{code}</td>
        <td>
          <Button type="delete-bin" onClick={() => handleOpenModal(true)} />
        </td>
      </tr>
    </>
  )
}

export default connect(null, dispatch => ({
  deleteCurrency: id => dispatch(deleteCurrency(id))
}))(CurrencyRow)
