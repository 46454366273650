import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { Button, ModalDelete } from "expano-components";
import { PanelLoader, Main } from "components";
import { getProduct, deleteProduct } from "actions/products";
import {
  Bundles,
  Virtuals,
  Channels,
  BasicForm,
  Specifications,
} from "./components";

import { ReactComponent as ProductIcon } from "icons/product.svg";

const ProductEdit = ({
  product,
  getProduct,
  history,
  match: {
    params: { id },
  },
}) => {
  const [show_delete_modal, handleShowDeleteModal] = useState(false);

  useEffect(() => {
    getProduct(id);
  }, [id]);

  if (["invalid", "loading"].includes(product.status)) {
    return <PanelLoader />;
  }

  if (product.status === "failure") {
    return <Redirect to="/products" />;
  }

  return (
    <Main
      page={{
        name: "Produkty",
        icon: <ProductIcon />,
        breadcrumbs: [
          { name: "Produkty", path: "/products" },
          {
            name: product?.data?.name,
          },
        ],
        buttons: (
          <Button
            type="delete"
            text="Usuń produkt"
            onClick={() => handleShowDeleteModal(true)}
          />
        ),
      }}
    >
      {show_delete_modal && (
        <ModalDelete
          handleDelete={() =>
            deleteProduct(id).then(() => {
              handleShowDeleteModal(false);
              history.push("/products");
            })
          }
          handleClose={() => handleShowDeleteModal(false)}
          name={`produkt ${product?.data?.name}`}
        />
      )}

      <div className="dashboard-wrapper-flex">
        <div className="column-2">
          <BasicForm />
          {product?.data?.is_bundle && <Bundles />}
          {product?.data?.is_virtual && <Virtuals />}
        </div>
        <div className="column-1">
          <Specifications />
          <Channels />
        </div>
      </div>
    </Main>
  );
};

export default connect(
  ({ product }) => ({
    product,
  }),
  (dispatch) => ({
    getProduct: (id) => dispatch(getProduct(id)),
  })
)(ProductEdit);
