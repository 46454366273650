const initialState = {
  status: 'invalid',
  data: null,
  platforms: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SHOP_RESET':
      return {
        status: 'invalid'
      }
    case 'SHOP_LOADING':
      return {
        ...state,
        status: 'loading'
      }
    case 'SHOP_SUCCESS':
      return {
        ...state,
        status: 'success',
        data: action.data,
        platforms: action.platforms || state.platforms
      }

    case 'SHOP_FAILURE':
      return {
        ...state,
        status: 'failure',
        err: action.err
      }
    default:
      return state
  }
}
