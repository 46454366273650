import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Input, Label, Select, ModalDelete, Button } from 'expano-components'
import { Main, Dashboard, PanelLoader } from 'components'
import { getChannel, deleteChannel } from 'actions/channels'

import { ReactComponent as ChannelIcon } from 'icons/channel.svg'
import { ReactComponent as InfoIcon } from 'icons/info.svg'

const ChannelEdit = ({
  history,
  match: {
    params: { id }
  },
  channel,
  getChannel
}) => {
  const [is_open_delete_modal, setOpenDeleteModal] = useState(false)

  useEffect(() => {
    getChannel(id)
  }, [id])

  const handleDelete = () =>
    deleteChannel(id).then(() => history.push('/channels'))

  if (['invalid', 'loading'].includes(channel.status)) {
    return <PanelLoader />
  }

  if (channel.status === 'failure') {
    return <Redirect to="/channels" />
  }

  const { identifier, platform, shop } = channel.data

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: 'Kanały', path: '/channels' },
          {
            name: identifier
          }
        ],
        name: identifier,
        icon: <ChannelIcon />,
        buttons: (
          <Button
            type="delete"
            text="Usuń kanał"
            onClick={() => setOpenDeleteModal(true)}
          />
        )
      }}>
      {is_open_delete_modal && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => setOpenDeleteModal(false)}
          name={`kanał ${identifier}`}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-2">
          <Dashboard
            header={{
              title: 'Konfiguracja kanału',
              icon: <ChannelIcon />
            }}>
            <div className="dashboard__form__submit">
              <div className="form-row grid-2">
                <Select
                  label="Sklep"
                  isDisabled={true}
                  defaultValue={{
                    label: (
                      <p>
                        <Label
                          type="fill"
                          text={shop?.label_text}
                          color={shop?.label_color}
                        />{' '}
                        {shop?.name}
                      </p>
                    ),
                    value: shop?.id
                  }}
                />
                <Select
                  label="Platformę"
                  isDisabled={true}
                  defaultValue={{
                    label: (
                      <p>
                        <Label
                          type="bordered"
                          text={platform?.label_text}
                          color={`#${platform?.label_color}`}
                        />{' '}
                        {platform?.name}
                      </p>
                    ),
                    value: platform?.id
                  }}
                />
              </div>
            </div>
          </Dashboard>
        </div>
        <div className="column-1">
          <Dashboard
            header={{
              title: 'Informacje',
              icon: <InfoIcon />
            }}>
            <div className="dashboard__form__submit">
              <Input
                label="Nazwa kanału"
                input={{
                  name: 'identifier',
                  defaultValue: identifier
                }}
                meta={{}}
                className="input-disabled"
                disabled
                type="text"
              />
            </div>
            {shop?.id && platform?.id && (
              <div className="dashboard__channel-label">
                <p className="label-form channel-label-text">Label kanału</p>
                <Label
                  type="double"
                  left={{
                    text: shop?.label_text,
                    color: shop?.label_color
                  }}
                  right={{
                    text: platform?.label_text,
                    color: `#${platform?.label_color}`
                  }}
                />
              </div>
            )}
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

export default connect(
  ({ channel }) => ({
    channel
  }),
  dispatch => ({
    getChannel: id => dispatch(getChannel(id))
  })
)(ChannelEdit)
