import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'expano-components'
import { VatRatesPanels } from './components'
import { Main, PanelLoader } from 'components'
import { getVatRates } from 'actions/vat_rates'
import AddVatRateModal from './modals/AddVatRateModal'

import { ReactComponent as EuroIcon } from 'icons/euro.svg'

const VatRates = ({ vat_rates, getVatRates }) => {
  const [show_add_modal, handleShowAddModal] = useState(false)

  useEffect(() => {
    getVatRates()
  }, [])

  if (['invalid', 'loading'].includes(vat_rates.status)) {
    return <PanelLoader />
  }

  return (
    <Main
      page={{
        breadcrumbs: [{ name: 'Stawki VAT' }],
        name: 'Stawki VAT',
        icon: <EuroIcon />,
        buttons: (
          <Button
            type="add"
            text="Dodaj stawke VAT"
            onClick={() => handleShowAddModal(true)}
          />
        )
      }}>
      {show_add_modal && (
        <AddVatRateModal handleClose={() => handleShowAddModal(false)} />
      )}
      <VatRatesPanels />
    </Main>
  )
}

export default connect(
  ({ vat_rates }) => ({
    vat_rates
  }),
  dispatch => ({
    getVatRates: () => dispatch(getVatRates())
  })
)(VatRates)
