import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Button } from 'expano-components'
import { AsyncSelectField, Dashboard } from 'components'
import { createProductGroupVatRates } from 'actions/product_groups'
import validate from './validate'

import getVatRatesOptions from 'utils/select_options/vat_rates_options'

import { ReactComponent as EuroIcon } from 'icons/euro.svg'

const BasicForm = ({
  product_group,
  createProductGroupVatRates,
  handleSubmit,
  change,
  pristine
}) => {
  const onSubmit = ({ product_group_vat_rates }) =>
    createProductGroupVatRates(product_group_vat_rates)

  const { countries, data } = product_group

  return (
    <Dashboard
      header={{ icon: <EuroIcon />, title: 'Stawki VAT' }}
      footer={
        <Button
          type="save"
          disabled={pristine}
          onClick={handleSubmit(onSubmit)}
        />
      }>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="dashboard__form__submit">
          {countries
            ?.sort((item, next) => item.id - next.id)
            ?.map(({ id, code }, index) => {
              const product_group_vat_rate = data?.product_group_vat_rates?.find(
                ({ vat_rate }) => vat_rate.country_code === code
              )
              return (
                <Field
                  key={id}
                  name={`product_group_vat_rates[${index}].vat_rate_id`}
                  component={AsyncSelectField}
                  label={`Kraj: ${code}`}
                  change={change}
                  onChange={value =>
                    change(
                      `product_group_vat_rates[${index}].vat_rate_id`,
                      value
                    )
                  }
                  getData={q => getVatRatesOptions(q, code)}
                  initValue={
                    product_group_vat_rate
                      ? {
                          label: product_group_vat_rate.vat_rate.name,
                          value: product_group_vat_rate.vat_rate.id
                        }
                      : null
                  }
                />
              )
            })}
        </div>
      </form>
    </Dashboard>
  )
}

const mapStateToProps = ({ product_group }) => ({
  product_group,
  initialValues: {
    product_group_vat_rates: product_group.countries?.map(({ code }) => {
      const product_group_vat_rate = product_group.data?.product_group_vat_rates?.find(
        ({ vat_rate }) => vat_rate.country_code === code
      )
      return {
        id: product_group_vat_rate?.id || null,
        vat_rate_id: product_group_vat_rate?.vat_rate?.id || null,
        product_group_id: product_group.data?.id
      }
    })
  }
})

const mapDispatchToProps = dispatch => ({
  createProductGroupVatRates: data => dispatch(createProductGroupVatRates(data))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'edit-product-group-vat-rates',
    validate,
    enableReinitialize: true
  })(BasicForm)
)
