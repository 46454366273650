const initialState = {
  status: 'invalid'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'COUNTRIES_LOADING':
      return {
        ...state,
        status: 'loading'
      }
    case 'COUNTRIES_SUCCESS':
      return {
        ...state,
        status: 'success',
        data: action.data
      }
    case 'COUNTRIES_FAILURE':
      return {
        ...state,
        status: 'failure'
      }
    case 'COUNTRY_ADD':
      return {
        ...state,
        data: [...state.data, action.data]
      }
    case 'COUNTRY_DELETE':
      return {
        ...state,
        data: [...state.data].filter(({ id }) => id !== action.id)
      }
    default:
      return state
  }
}
