import React, { useState } from 'react'
import { Button, Input, Label } from 'expano-components'
import { Main, Dashboard, AsyncSelect } from 'components'
import { createChannel } from 'actions/channels'
import getPlatformOptions from 'utils/select_options/platform_options'
import getShopOptions from 'utils/select_options/shop_options'

import { ReactComponent as ChannelIcon } from 'icons/channel.svg'
import { ReactComponent as InfoIcon } from 'icons/info.svg'

const ChannelNew = ({ history }) => {
  const [shop, setShop] = useState(null)
  const [platform, setPlatform] = useState(null)
  const handleCreate = () =>
    createChannel({
      platform_id: platform.id,
      shop_id: shop.id
    }).then(({ id }) => history.push(`/channels/${id}`))

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: 'Kanały', path: '/channels' },
          {
            name: 'Dodaj kanał'
          }
        ],
        name: 'Dodaj kanał',
        icon: <ChannelIcon />
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-2">
          <Dashboard
            header={{
              title: 'Konfiguracja kanału',
              icon: <ChannelIcon />,
              aside: (
                <Button
                  type="save"
                  disabled={!shop || !platform}
                  onClick={handleCreate}
                />
              )
            }}>
            <div className="dashboard__form__submit">
              <div className="form-row grid-2">
                <AsyncSelect
                  label="Wybierz sklep"
                  onChange={value => setShop(value)}
                  getData={getShopOptions}
                />
                <AsyncSelect
                  label="Wybierz platformę"
                  onChange={value => setPlatform(value)}
                  getData={getPlatformOptions}
                />
              </div>
            </div>
          </Dashboard>
        </div>
        <div className="column-1">
          <Dashboard
            header={{
              title: 'Informacje',
              icon: <InfoIcon />
            }}>
            <div className="dashboard__form__submit">
              <Input
                label="Nazwa kanału"
                input={{
                  name: 'identifier',
                  defaultValue:
                    shop?.id &&
                    platform?.id &&
                    `${shop.name} x ${platform.name}`
                }}
                meta={{}}
                className="input-disabled"
                disabled
                type="text"
              />
            </div>
            {shop?.id && platform?.id && (
              <div className="dashboard__channel-label">
                <p className="label-form channel-label-text">Label kanału</p>
                <Label
                  type="double"
                  left={{
                    text: shop?.label_text,
                    color: shop?.label_color
                  }}
                  right={{
                    text: platform?.label_text,
                    color: `#${platform?.label_color}`
                  }}
                />
              </div>
            )}
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

export default ChannelNew
