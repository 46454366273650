import { toastr } from 'react-redux-toastr'
import { client, handleErrors, stringifyQuery } from 'utils/api'

export const toggleFilters = () => dispatch =>
  dispatch({ type: 'ALLOWED_IPS_TOGGLE_FILTERS' })

export const filterAllowedIps = query => async dispatch =>
  dispatch({ type: 'ALLOWED_IPS_SET_FILTERS_QUERY', query })

export const getAllowedIps = ({ page = 1, per_page = 10, ...rest }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      status,
      filters: { query }
    } = getState().allowed_ips
    dispatch({
      type:
        status === 'invalid' ? 'ALLOWED_IPS_LOADING' : 'ALLOWED_IPS_SEARCHING'
    })

    const { data, meta } = await client.get(
      `/allowed_ips?${stringifyQuery({ ...rest, page, per_page, ...query })}`
    )
    dispatch({
      type: 'ALLOWED_IPS_SUCCESS',
      data,
      meta
    })
  } catch (error) {
    dispatch({ type: 'ALLOWED_IPS_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podczas pobierania')
  }
}

export const getAllowedIp = id => async dispatch => {
  try {
    dispatch({ type: 'ALLOWED_IP_LOADING' })
    const { data } = await client.get(`/allowed_ips/${id}`)
    dispatch({
      type: 'ALLOWED_IP_SUCCESS',
      data
    })
  } catch (error) {
    dispatch({ type: 'ALLOWED_IP_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podzczas pobierania')
  }
}

export const createAllowedIp = values =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post(`/allowed_ips`, values)
      resolve(data)
      toastr.success('Sukces', 'Nowy adres IP został dodany')
    } catch (error) {
      reject(handleErrors(error?.response?.data?.errors))
      toastr.error('Błąd', 'Wystąpił błąd')
    }
  })

export const updateAllowedIp = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/allowed_ips/${id}`, values)
      const { data } = await client.get(`/allowed_ips/${id}`)
      dispatch({
        type: 'ALLOWED_IP_SUCCESS',
        data
      })
      resolve()
      toastr.success('Sukces', 'Adres IP został zaktualizowany')
    } catch (error) {
      reject(handleErrors(error?.response?.data?.errors))
        toastr.error('Błąd', 'Wystąpił błąd')
    }
  })

export const deleteAllowedIp = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/allowed_ips/${id}`)
      resolve()
                  toastr.success('Sukces', 'Adres IP został usunięty')

    } catch (error) {
      reject(error)
      toastr.error('Błąd', 'Wystąpił błąd')
    }
  })
