import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'

import { Button } from 'expano-components'
import {
  Main,
  Dashboard,
  InputField,
  SelectField,
  CheckField
} from 'components'
import { createWarehouse } from 'actions/warehouses'

import warehouse_country_options from 'utils/select_options/warehouse_country'

import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as WarehouseIcon } from 'icons/warehouse.svg'

const WarehouseNew = ({ history, handleSubmit, change, pristine }) => {
  const onSubmit = values =>
    createWarehouse(values)
      .then(({ id }) => {
        history.push(`/warehouses/${id}`)
      })
      .catch(err => {
        throw new SubmissionError(err)
      })

  return (
    <Main
      page={{
        name: 'Dodaj magazyn',
        icon: <WarehouseIcon />,
        breadcrumbs: [
          { name: 'Magazyny', path: '/warehouses' },
          {
            name: 'Dodaj magazyn'
          }
        ]
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{
              icon: <InfoIcon />,
              title: 'Podstawowe dane'
            }}
            footer={
              <Button
                disabled={pristine}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="dashboard__form__submit">
                <div className="form-row grid-2">
                  <Field
                    name="identifier"
                    label="Identyfikator"
                    component={InputField}
                    type="text"
                  />
                  <Field
                    name="name"
                    label="Nazwa magazynu"
                    component={InputField}
                    type="text"
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="street"
                    label="Ulica"
                    component={InputField}
                    type="text"
                  />
                  <Field
                    name="city"
                    label="Miasto"
                    component={InputField}
                    type="text"
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="zip_code"
                    label="Kod pocztowy"
                    component={InputField}
                    type="text"
                  />
                  <Field
                    label="Kraj"
                    name="country_code"
                    component={SelectField}
                    options={warehouse_country_options}
                    change={change}
                  />
                </div>
                <Field
                  name="is_virtual"
                  label="Magazyn wirtualny"
                  component={CheckField}
                  mainLabel="Typ magazynu"
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

export default reduxForm({
  form: 'create-warehouse',
  enableReinitialize: true
})(WarehouseNew)
