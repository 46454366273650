import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { Button, MainTable, Main, Dashboard } from 'components'
import { parseQuery } from 'utils/api'
import { getSpeditors, filterSpeditors, toggleFilters } from 'actions/speditors'

import { ReactComponent as SpeditorIcon } from 'icons/speditor.svg'

const SpeditorsList = ({
  speditors,
  toggleFilters,
  getSpeditors,
  filterSpeditors,
  history,
  location: { search }
}) => {
  const query = parseQuery(search)
  const [is_loaded, setLoaded] = useState(false)
  const sort = {
    'sort[column]': query['sort[column]'] || 'id',
    'sort[order]': query['sort[order]'] || 'asc'
  }
  useEffect(() => {
    getSpeditors({ ...query, ...sort })
    setLoaded(true)
  }, [search])
  useEffect(() => {
    if (is_loaded) {
      getSpeditors({ ...query, page: undefined })
    }
  }, [speditors.filters.query])

  const { data, meta } = speditors

  return (
    <Main
      page={{
        breadcrumbs: [{ name: 'Spedytorzy' }],
        name: 'Spedytorzy',
        icon: <SpeditorIcon />,
        buttons: (
          <Button type="add" text="Dodaj spedytora" path="/speditors/new" />
        )
      }}>
      <Dashboard>
        <MainTable
          is_loading={['invalid', 'loading'].includes(speditors.status)}
          is_searching={speditors.status === 'searching'}
          filters_open={speditors.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: 'ID', name: 'id' },
            { label: 'Nazwa', name: 'name' },
            { label: 'Tracking URL', name: 'tracking_url' }
          ]}
          empty_text="Brak spedytorów"
          renderRow={({ id, name, tracking_url }) => (
            <tr
              key={id}
              style={{ cursor: 'pointer' }}
              onMouseDown={event => {
                if (event.button === 1) {
                  window.open(`/speditors/${id}`, '_blank')
                }
              }}
              onClick={() => history.push(`/speditors/${id}`)}>
              <td>{id}</td>
              <td>{name}</td>
              <td>{tracking_url}</td>
            </tr>
          )}
          filtersAction={filterSpeditors}
          filters={[
            {
              type: 'input',
              label: 'Nazwa',
              name: 'name',
              search_type: 'matches'
            }
          ]}
        />
      </Dashboard>
    </Main>
  )
}

const mapDispatchToProps = dispatch => ({
  getSpeditors: data => dispatch(getSpeditors(data)),
  filterSpeditors: data => dispatch(filterSpeditors(data)),
  toggleFilters: () => dispatch(toggleFilters())
})
export default connect(
  ({ speditors }) => ({ speditors }),
  mapDispatchToProps
)(SpeditorsList)
