const initialState = {
  status: 'invalid'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'BRAND_LOADING':
      return {
        ...state,
        status: 'loading'
      }
    case 'BRAND_SUCCESS':
      return {
        ...state,
        status: 'success',
        data: action.data
      }
    case 'BRAND_FAILURE':
      return {
        ...state,
        status: 'failure',
        err: action.err
      }
    default:
      return state
  }
}
