import { toastr } from 'react-redux-toastr'
import { client, stringifyQuery } from 'utils/api'

export const toggleFilters = () => dispatch =>
  dispatch({ type: 'SYSTEM_SETTINGS_TOGGLE_FILTERS' })

export const filterSystemSettings = query => async dispatch =>
  dispatch({ type: 'SYSTEM_SETTINGS_SET_FILTERS_QUERY', query })

export const getSystemSettings = ({
  page = 1,
  per_page = 100,
  ...rest
}) => async (dispatch, getState) => {
  try {
    const {
      status,
      filters: { query }
    } = getState().system_settings

    dispatch({
      type:
        status === 'invalid'
          ? 'SYSTEM_SETTINGS_LOADING'
          : 'SYSTEM_SETTINGS_SEARCHING'
    })

    const { data, meta } = await client.get(
      `/system_settings?${stringifyQuery({
        ...rest,
        page,
        per_page,
        ...query
      })}`
    )

    dispatch({
      type: 'SYSTEM_SETTINGS_SUCCESS',
      data,
      meta
    })
  } catch (error) {
    dispatch({ type: 'SYSTEM_SETTINGS_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podczas pobierania')
  }
}

export const updateSystemSetting = (id, value) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/system_settings/${id}`, { value })
      const { data } = await client.get(`/system_settings/${id}`)

      dispatch({
        type: 'SYSTEM_SETTINGS_UPDATE_RECORD',
        data
      })
      resolve()
      toastr.success('Sukces', 'Wartość została zaktualizowana')
    } catch (error) {
      reject()
      toastr.error('Błąd', 'Wystąpił błąd podczas zapisu')
    }
  })
