import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'expano-components'
import { Dashboard } from 'components'
import AddChannel from './components/AddChannel'
import ChannelListItem from './components/ChannelListItem'

import { ReactComponent as ChannelIcon } from 'icons/channel.svg'

const Channels = ({ available_channels, channel_products }) => {
  const [show_add_channel, showAddChannel] = useState(false)

  return (
    <>
      {show_add_channel && (
        <AddChannel handleClose={() => showAddChannel(false)} />
      )}
      <Dashboard
        className="m-b-20"
        header={{ title: 'Kanały', icon: <ChannelIcon /> }}>
        {channel_products?.length > 0 && (
          <ul className="dashboard__list">
            {channel_products.map(item => (
              <ChannelListItem key={item.id} {...item} />
            ))}
          </ul>
        )}

        {available_channels?.length > 0 && (
          <div className="dashboard__button-section">
            <Button
              type="add"
              onClick={() => showAddChannel(true)}
              text="Dodaj kanał"
            />
          </div>
        )}
      </Dashboard>
    </>
  )
}

export default connect(({ product: { data, available_channels } }) => ({
  channel_products: data.channel_products,
  available_channels
}))(Channels)
