import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, Link } from 'react-router-dom'
import { PageLoader } from 'components'
import { handleLogin, handleLogout } from 'actions/account'
import { getCurrentUser } from 'actions/current_user'
import { Login, TopBar, Sidebar } from 'expano-components'
import { toggleSidebar } from 'actions/sidebar'
import Router from '../Router'
import { nav } from './nav'

const app = 'root'

const App = ({
  history,
  sidebar,
  toggleSidebar,
  location,
  current_user,
  getCurrentUser
}) => {
  useEffect(() => {
    getCurrentUser()
  }, [])

  if (['invalid', 'loading'].includes(current_user.status)) {
    return <PageLoader />
  }
  if (current_user.status === 'failure') {
    return <Login app={app} handleLogin={handleLogin} />
  }
  return (
    <>
      <Sidebar
        Link={Link}
        app={app}
        nav={nav}
        is_open={sidebar.is_open}
        toggleSidebar={toggleSidebar}
        location={location}
      />
      <main className="main-container">
        <TopBar
          handleLogout={handleLogout}
          currentUser={{
            email: current_user.email,
            company: current_user.company?.name
          }}
          is_open={sidebar.is_open}
          toggleSidebar={toggleSidebar}
          history={history}
        />
        <Router />
      </main>
    </>
  )
}

const mapStateToProps = ({ current_user, sidebar }) => ({
  current_user,
  sidebar
})

const mapDispatchToProps = dispatch => ({
  toggleSidebar: () => dispatch(toggleSidebar()),
  getCurrentUser: () => dispatch(getCurrentUser())
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
