const validate = values => {
  const errors = {}
  if (values.vat_rates && values.vat_rates.length) {
    const vat_rates_errors = []
    values.vat_rates.forEach((vat_rate, memberIndex) => {
      const vat_rate_errors = {}
      if (!vat_rate || !vat_rate.id) {
        vat_rate_errors.id = 'Pole wymagane'
        vat_rates_errors[memberIndex] = vat_rate_errors
      }
    })
    if (vat_rates_errors.length) {
      errors.vat_rates = vat_rates_errors
    }
  }
  return errors
}

export default validate
