import { client } from 'utils/api'

const getProducerOptions = (q = null) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/producers?context=list&per_page=15${
          q ? `&q[name_matches]=%25${q}%25` : ''
        }`
      )
      resolve(
        data.map(({ id, name }) => ({
          label: name,
          value: id
        }))
      )
    } catch (error) {
      console.log(error)
      reject([])
    }
  })

export default getProducerOptions
