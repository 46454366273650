import React from "react";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from "redux-form";
import { connect } from "react-redux";
import { Button } from "expano-components";
import {
  Main,
  CheckField,
  InputField,
  AsyncSelectField,
  Dashboard,
} from "components";
import { createProductGroup } from "actions/product_groups";

import getCategoryOptions from "utils/select_options/category_options";
import getProducerOptions from "utils/select_options/producer_options";
import getBrandOptions from "utils/select_options/brand_options";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import { ReactComponent as ProductGroupIcon } from "icons/product_group.svg";

const selector = formValueSelector("create-product-group");

const ProductGroupNew = ({
  history,
  handleSubmit,
  pristine,
  change,
  producer_id,
}) => {
  const onSubmit = ({
    name,
    sku,
    category_id,
    producer_id,
    brand_id,
    is_required_gpsr_document,
  }) =>
    createProductGroup({
      name,
      sku,
      producer_id,
      category_id,
      brand_id,
      is_required_gpsr_document,
    })
      .then(({ id }) => {
        history.push(`/product_groups/${id}`);
      })
      .catch((err) => {
        throw new SubmissionError(err);
      });

  return (
    <Main
      page={{
        breadcrumbs: [
          {
            name: "Produkty grupowe",
            path: "/product_groups",
          },
          {
            name: "Dodaj produkt grupowy",
          },
        ],
        name: "Dodaj produkt grupowy",
        icon: <ProductGroupIcon />,
      }}
    >
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{ title: "Podstawowe dane", icon: <InfoIcon /> }}
            footer={
              <Button
                disabled={pristine}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }
          >
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="dashboard__form__submit">
                <Field
                  name="name"
                  label="Nazwa produktu"
                  component={InputField}
                  type="email"
                />
                <div className="form-row grid-2">
                  <Field
                    name="category_id"
                    component={AsyncSelectField}
                    label="Kategoria"
                    change={change}
                    getData={getCategoryOptions}
                  />
                  <Field
                    name="sku"
                    label="SKU"
                    component={InputField}
                    type="text"
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="producer_id"
                    component={AsyncSelectField}
                    label="Producent"
                    afterChange={() => change("brand_id", null)}
                    change={change}
                    getData={getProducerOptions}
                  />
                  <Field
                    key={producer_id}
                    isDisabled={!producer_id}
                    name="brand_id"
                    component={AsyncSelectField}
                    label="Marka"
                    change={change}
                    getData={(q) => getBrandOptions(q, producer_id)}
                  />
                </div>
                <Field
                  name="is_required_gpsr_document"
                  label="Wymaga dokumentu GPSR"
                  component={CheckField}
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

export default connect((state) => ({
  producer_id: selector(state, "producer_id") || null,
}))(
  reduxForm({ form: "create-product-group", enableReinitialize: true })(
    ProductGroupNew
  )
);
