import { client } from 'utils/api'

const getCategoryOptions = (q = null) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/categories?context=list&per_page=15${
          q ? `&q[name_or_identifier_matches]=%25${q}%25` : ''
        }`
      )
      resolve(
        data.map(({ id, name, identifier }) => ({
          label: `[${identifier}] ${name}`,
          value: id
        }))
      )
    } catch (error) {
      console.log(error)
      reject([])
    }
  })

export default getCategoryOptions
