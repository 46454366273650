import { client } from 'utils/api'

const getAvailableCurrencies = (q) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/available_currencies?context=list&per_page=15${
          q ? `&q[name_matches]=%25${q}%25` : ''
        }`
      )
      resolve(
        data.map(({  code }) => ({
          label: code,
          value: code
        }))
      )
    } catch (error) {
      console.log(error)
      reject([])
    }
  })
}
export default getAvailableCurrencies
