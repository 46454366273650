import React from 'react'

import { ReactComponent as ShopIcon } from 'icons/shop.svg'
import { ReactComponent as WarehouseIcon } from 'icons/warehouse.svg'
import { ReactComponent as ProductIcon } from 'icons/product.svg'
import { ReactComponent as UsersIcon } from 'icons/user.svg'

export const nav = [
  {
    name: 'Sprzedaż',
    type: 'group',
    icon: <ShopIcon />,
    items: [
      { name: 'Sklepy', path: '/shops' },
      { name: 'Platformy', path: '/platforms' },
      { name: 'Kanały', path: '/channels' },
      { name: 'Waluta/Kraj', path: '/currency_country' },
      { name: 'Stawki VAT', path: '/vat_rates' }
    ]
  },
  {
    name: 'Logistyka',
    type: 'group',
    icon: <WarehouseIcon />,
    items: [
      { name: 'Magazyny', path: '/warehouses' },
      { name: 'Spedytorzy', path: '/speditors' }
    ]
  },
  {
    name: 'Asortyment',
    type: 'group',
    icon: <ProductIcon />,
    items: [
      {
        name: 'Produkty grupowe',
        path: '/product_groups'
      },
      { name: 'Produkty', path: '/products' },
      { name: 'Kategorie', path: '/categories' },
      { name: 'Producenci', path: '/producers' },
      { name: 'Marki', path: '/brands' }
    ]
  },
  {
    name: 'Administracja',
    type: 'group',
    icon: <UsersIcon />,
    items: [
      { name: 'Dozwolone IP', path: '/allowed_ips' },
      { name: 'Użytkownicy', path: '/users' },
      { name: 'Rejestr systemowy', path: '/system_settings' }
    ]
  }
]
