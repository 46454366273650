import React, { useState } from "react";
import { connect } from "react-redux";
import { Label, Button, ModalDelete } from "expano-components";
import { deleteBrandChannel } from "actions/brands";

const ChannelListItem = ({
  id,
  brand_name,
  deleteBrandChannel,
  channel: { identifier, shop, platform },
}) => {
  const [show_delete_modal, handleShowDeleteModal] = useState(false);

  return (
    <>
      {show_delete_modal && (
        <ModalDelete
          handleDelete={() => deleteBrandChannel(id)}
          handleClose={() => handleShowDeleteModal(false)}
          name={`kanał ${identifier} z marki ${brand_name}?`}
        />
      )}
      <li className="dashboard__list-item" key={id}>
        <Label
          type="double"
          left={{
            text: shop?.label_text,
            color: shop?.label_color,
          }}
          right={{
            text: platform?.label_text,
            color: `#${platform?.label_color}`,
          }}
        />
        <span className="platform-name">{identifier}</span>
        <span className="country country-label country-label--hidden" />
        <Button type="delete-bin" onClick={() => handleShowDeleteModal(true)} />
      </li>
    </>
  );
};

export default connect(
  ({ brand: { data } }) => ({ brand_name: data.name }),
  (dispatch) => ({
    deleteBrandChannel: (id) => dispatch(deleteBrandChannel(id)),
  })
)(ChannelListItem);
