import Loaders from "react-loader-spinner";

const Loader = ({ size = 80, color = "#2959F8" }) => (
  <div className="loader">
    <Loaders
      type="Watch"
      height={size}
      width={size}
      color={color}
      visible={true}
      secondaryColor={color}
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  </div>
);

export default Loader;