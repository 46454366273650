import { toastr } from 'react-redux-toastr'
import { client, handleErrors } from 'utils/api'

export const getCurrencies = () => async dispatch => {
  try {
    dispatch({ type: 'CURRENCIES_LOADING' })
    const { data } = await client.get(`/available_currencies`)
    dispatch({
      type: 'CURRENCIES_SUCCESS',
      data
    })
  } catch (error) {
    dispatch({ type: 'CURRENCIES_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podzczas pobierania')
  }
}

export const createCurrency = values => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id }
      } = await client.post(`/available_currencies`, values)
      const { data } = await client.get(`/available_currencies/${id}`)
      dispatch({
        type: 'CURRENCY_ADD',
        data
      })
      resolve()
      toastr.success('Sukces', 'Dodano walutę')

    } catch (error) {
      reject(handleErrors(error?.response?.data?.errors))
      toastr.error('Błąd', 'Wystąpił błąd')
    }
  })

export const deleteCurrency = id => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/available_currencies/${id}`)
      dispatch({
        type: 'CURRENCY_DELETE',
        id
      })
      resolve()
       toastr.success('Sukces', 'Usunięto walutę')
    } catch (error) {
      reject(handleErrors(error?.response?.data?.errors))
          toastr.error('Błąd', 'Wystąpił błąd')
    }
  })
