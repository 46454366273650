import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import { Button, MainTable, Main, Dashboard } from 'components'
import { parseQuery } from 'utils/api'
import {
  getAllowedIps,
  filterAllowedIps,
  toggleFilters
} from 'actions/allowed_ips'

import { ReactComponent as AllowedIpIcon } from 'icons/allowed_ip.svg'

const AllowedIpsList = ({
  allowed_ips,
  toggleFilters,
  getAllowedIps,
  filterAllowedIps,
  location: { search }
}) => {
  const query = parseQuery(search)
  const [is_loaded, setLoaded] = useState(false)
  const sort = {
    'sort[column]': query['sort[column]'] || 'id',
    'sort[order]': query['sort[order]'] || 'asc'
  }
  useEffect(() => {
    getAllowedIps({ ...query, ...sort })
    setLoaded(true)
  }, [search])

  useEffect(() => {
    if (is_loaded) {
      getAllowedIps({ ...query, page: undefined })
    }
  }, [allowed_ips.filters.query])

  const { data, meta } = allowed_ips

  return (
    <Main
      page={{
        breadcrumbs: [{ name: 'Dozwolone IP' }],
        name: 'Dozwolone IP',
        icon: <AllowedIpIcon />,
        buttons: (
          <Button
            type="add"
            text="Dodaj dozwolone IP"
            path="/allowed_ips/new"
          />
        )
      }}>
      <Dashboard>
        <MainTable
          is_loading={['invalid', 'loading'].includes(allowed_ips.status)}
          is_searching={allowed_ips.status === 'searching'}
          filters_open={allowed_ips.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: 'ID', name: 'id' },
            { label: 'Nazwa', name: 'name' },
            { label: 'Adres IP', name: 'ip_address' }
          ]}
          empty_text="Brak dozwolonych IP"
          renderRow={({ id, name, ip_address }) => (
            <tr key={id}>
              <td>{id}</td>
              <td>{name}</td>
              <td>{ip_address}</td>
              <td>
                <Button type="edit" path={`/allowed_ips/${id}`} />
              </td>
            </tr>
          )}
          filtersAction={filterAllowedIps}
          filters={[
            {
              type: 'input',
              label: 'Nazwa',
              name: 'name',
              search_type: 'matches'
            },
            {
              type: 'input',
              label: 'Adres IP',
              name: 'ip_address',
              search_type: 'matches'
            }
          ]}
        />
      </Dashboard>
    </Main>
  )
}

const mapDispatchToProps = dispatch => ({
  getAllowedIps: data => dispatch(getAllowedIps(data)),
  filterAllowedIps: data => dispatch(filterAllowedIps(data)),
  toggleFilters: () => dispatch(toggleFilters())
})
export default connect(
  ({ allowed_ips }) => ({ allowed_ips }),
  mapDispatchToProps
)(AllowedIpsList)
