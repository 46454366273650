import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Button } from 'expano-components'
import { editProducerBrand } from 'actions/producers'
import { Modal, InputField } from 'components'
import { ReactComponent as UsersIcon } from 'icons/users.svg'

const EditBrandModal = ({
  id,
  handleClose,
  handleSubmit,
  pristine,
  editProducerBrand
}) => {
  const onSubmit = values =>
    editProducerBrand(id, values)
      .then(handleClose)
      .catch(err => {
        throw new SubmissionError(err)
      })

  return (
    <Modal
      handleClose={handleClose}
      header={{ title: 'Edytuj markę', icon: <UsersIcon /> }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            disabled={pristine}
            type="save"
            onClick={handleSubmit(onSubmit)}
            text="Zapisz"
          />
        </>
      }>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field name="name" label="Nazwa" component={InputField} type="text" />
      </form>
    </Modal>
  )
}

export default connect(null, dispatch => ({
  editProducerBrand: (id, data) => dispatch(editProducerBrand(id, data))
}))(reduxForm({ form: 'update-producer-brand' })(EditBrandModal))
