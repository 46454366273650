import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { MainTable, Main, Dashboard } from 'components'
import { parseQuery } from 'utils/api'
import {
  getSystemSettings,
  filterSystemSettings,
  toggleFilters
} from 'actions/system_settings'
import Row from './components/Row'

import { ReactComponent as ListIcon } from 'icons/list.svg'

const SystemSettingsList = ({
  system_settings,
  toggleFilters,
  getSystemSettings,
  filterSystemSettings,
  location: { search }
}) => {
  const query = parseQuery(search)
  const [is_loaded, setLoaded] = useState(false)

  const sort = {
    'sort[column]': query['sort[column]'] || 'id',
    'sort[order]': query['sort[order]'] || 'asc'
  }

  useEffect(() => {
    getSystemSettings({ ...query, ...sort })
    setLoaded(true)
  }, [search])

  useEffect(() => {
    if (is_loaded) {
      getSystemSettings({ ...query, page: undefined })
    }
  }, [system_settings.filters.query])

  useEffect(() => {
    return () => {
      filterSystemSettings({ open: true, query: {} })
    }
  }, [])

  const { data, meta } = system_settings

  return (
    <Main
      page={{
        breadcrumbs: [{ name: 'Rejestr systemowy' }],
        name: 'Rejestr systemowy',
        icon: <ListIcon />
      }}>
      <Dashboard>
        <MainTable
          is_loading={['invalid', 'loading'].includes(system_settings.status)}
          is_searching={system_settings.status === 'searching'}
          filters_open={system_settings.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: 'Nazwa', name: 'name' },
            { label: 'Wartość', name: 'value' },
            { label: 'Grupa', name: 'group' },
            { label: 'Działania', name: 'actions' }
          ]}
          empty_text="Brak rejestrów"
          renderRow={item => <Row key={item.id} {...item} />}
          filtersAction={filterSystemSettings}
          filters={[
            {
              type: 'input',
              label: 'Nazwa',
              name: 'name',
              search_type: 'matches'
            },
            {
              type: 'input',
              label: 'Grupa',
              name: 'group',
              search_type: 'matches'
            }
          ]}
        />
      </Dashboard>
    </Main>
  )
}

const mapDispatchToProps = dispatch => ({
  getSystemSettings: data => dispatch(getSystemSettings(data)),
  filterSystemSettings: data => dispatch(filterSystemSettings(data)),
  toggleFilters: () => dispatch(toggleFilters())
})
export default connect(
  ({ system_settings }) => ({ system_settings }),
  mapDispatchToProps
)(SystemSettingsList)
