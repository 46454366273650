import { client } from 'utils/api'

const getProductGroupOptions = (q = null) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/product_groups?context=list&per_page=15${
          q ? `&q[name_or_sku_matches]=%25${q}%25` : ''
        }`
      )
      resolve(
        data.map(({ id, name, sku }) => ({
          label: `[${sku}] ${name}`,
          value: id
        }))
      )
    } catch (error) {
      console.log(error)
      reject([])
    }
  })

export default getProductGroupOptions
