import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, MainTable, Main, Dashboard, Modal, Loader } from "components";
import { ModalUpload, Button as ExpanoButton } from "expano-components";
import { parseQuery } from "utils/api";
import {
  getProducts,
  filterProducts,
  toggleFilters,
  updateProductsByCsv,
} from "actions/products";
import getPlatformOptions from "utils/select_options/platform_options";
import getShopOptions from "utils/select_options/shop_options";
import getCategoryOptions from "utils/select_options/category_options";
import getProducerOptions from "utils/select_options/producer_options";
import getBrandOptions from "utils/select_options/brand_options";

import { ReactComponent as ProductIcon } from "icons/product.svg";

const getStatus = (status) => {
  switch (status) {
    case "active":
      return { name: "Aktywny", color: "success" };
    case "inactive":
      return { name: "Do wyprzedania", color: "warning" };
    case "discontinued":
      return { name: "Nieaktywny", color: "danger" };

    default:
      return null;
  }
};

const ProductsList = ({
  products,
  toggleFilters,
  getProducts,
  filterProducts,
  history,
  location: { search },
}) => {
  const query = parseQuery(search);
  const [is_loaded, setLoaded] = useState(false);
  const [updated_products_data, setUpdatedProductsData] = useState([]);
  const [update_products_error, setUpdateProductsError] = useState(null);
  const [is_uploading, setUploading] = useState(false);
  const [is_open_upload_modal, setOpenUploadModal] = useState(false);
  const [is_open_upload_status_modal, setOpenUploadStatusModal] =
    useState(false);

  const sort = {
    "sort[column]": query["sort[column]"] || "id",
    "sort[order]": query["sort[order]"] || "asc",
  };

  useEffect(() => {
    getProducts({ ...query, ...sort });
    setLoaded(true);
  }, [search]);

  useEffect(() => {
    if (is_loaded) {
      getProducts({ ...query, page: undefined });
    }
  }, [products.filters.query]);

  const handleUpload = async (file) => {
    try {
      setUpdateProductsError(null);
      setUploading(true);
      setOpenUploadStatusModal(true);
      const data = await updateProductsByCsv(file);
      setUpdatedProductsData(data);
    } catch (error) {
      setUpdatedProductsData([]);
      setUpdateProductsError(error);
    } finally {
      setUploading(false);
    }
  };

  const { data, meta } = products;

  return (
    <Main
      page={{
        name: "Produkty",
        icon: <ProductIcon />,
        breadcrumbs: [{ name: "Produkty" }],
        buttons: (
          <>
            <ExpanoButton
              text="Aktualizuj produkty (.csv)"
              onClick={() => setOpenUploadModal(true)}
            />
            <Button type="add" text="Dodaj produkt" path="/products/new" />
          </>
        ),
      }}
    >
      {is_open_upload_status_modal && (
        <Modal
          size="md"
          handleClose={() => setOpenUploadStatusModal(false)}
          header={{
            icon: <ProductIcon />,
            title: "Aktualizacja produktów",
          }}
          footer={
            <>
              <Button
                type="cancel"
                text="Zamknij"
                onClick={() => setOpenUploadStatusModal(false)}
              />
            </>
          }
        >
          {is_uploading ? (
            <div className="wrapper jc-c">
              <Loader />
            </div>
          ) : updated_products_data?.length > 0 ? (
            <>
              <p>Zaktualizowano produkty:</p>
              <br />
              {updated_products_data.map(({ id, sku, name }) => (
                <p key={id}>
                  {name} -{" "}
                  <a
                    className="link"
                    href={`/products/${id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {sku}
                  </a>
                </p>
              ))}
            </>
          ) : (
            <p>Brak zaktualizowanych produktów</p>
          )}
          {update_products_error && (
            <p className="text-danger m-t-10">{update_products_error}</p>
          )}
        </Modal>
      )}
      {is_open_upload_modal && (
        <ModalUpload
          handleUpload={handleUpload}
          headerText="Wgraj plik (.csv)"
          accept=".csv"
          handleClose={() => setOpenUploadModal(false)}
        />
      )}
      <Dashboard>
        <MainTable
          is_loading={["invalid", "loading"].includes(products.status)}
          is_searching={products.status === "searching"}
          filters_open={products.filters.open}
          toggleFilters={toggleFilters}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: "Nazwa", name: "name", style: { width: 350 } },
            { label: "SKU", name: "sku", style: { width: 150 } },
            {
              label: "Kategoria",
              name: "category",
              sortable: false,
              style: { width: 200 },
            },
            {
              label: "Producent",
              name: "producer",
              sortable: false,
              style: { width: 120 },
            },
            {
              label: "Marka",
              name: "brand",
              sortable: false,
              style: { width: 120 },
            },
            {
              label: "Status produktu",
              name: "status",
              sortable: false,
              style: { width: 120 },
            },
          ]}
          empty_text="Brak produktów"
          renderRow={({ id, name, sku, category, producer, status, brand }) => (
            <tr
              key={id}
              style={{ cursor: "pointer" }}
              onMouseDown={(event) => {
                if (event.button === 1) {
                  window.open(`/products/${id}`, "_blank");
                }
              }}
              onClick={() => history.push(`/products/${id}`)}
            >
              <td>{name}</td>
              <td>{sku}</td>
              <td>{category?.name || "brak"}</td>
              <td>{producer?.name || "brak"}</td>
              <td>{brand?.name || "brak"}</td>
              <td>
                <div className={`product_status ${getStatus(status)?.color}`}>
                  <span>{getStatus(status)?.name}</span>
                </div>
              </td>
            </tr>
          )}
          filtersAction={filterProducts}
          filters={[
            {
              type: "input",
              label: "Sku lub Nazwa produktu",
              name: "sku_or_name",
              search_type: "matches",
            },
            {
              type: "async-multi-select",
              label: "Producent",
              search_type: "in",
              name: "producer_id",
              asyncAction: getProducerOptions,
            },
            {
              type: "async-multi-select",
              label: "Marka",
              search_type: "in",
              name: "brand_id",
              asyncAction: (q) => getBrandOptions(q, null),
            },
            {
              type: "async-multi-select",
              label: "Kategoria",
              search_type: "in",
              name: "category_id",
              asyncAction: getCategoryOptions,
            },
            {
              type: "async-multi-select",
              label: "Sklep",
              name: "shop_id",
              search_type: "in",
              asyncAction: getShopOptions,
            },
            {
              type: "async-multi-select",
              label: "Platforma",
              search_type: "in",
              name: "platform_id",
              asyncAction: getPlatformOptions,
            },
          ]}
        />
      </Dashboard>
    </Main>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getProducts: (data) => dispatch(getProducts(data)),
  filterProducts: (data) => dispatch(filterProducts(data)),
  toggleFilters: () => dispatch(toggleFilters()),
});
export default connect(
  ({ products }) => ({ products }),
  mapDispatchToProps
)(ProductsList);
