import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import {
  ShopsList,
  ShopsEdit,
  ShopsNew,
  //
  CategoriesList,
  CategoriesEdit,
  CategoriesNew,
  //
  ChannelsList,
  ChannelsEdit,
  ChannelsNew,
  //
  PlatformsList,
  PlatformsEdit,
  PlatformsNew,
  //
  WarehousesList,
  WarehousesNew,
  WarehousesEdit,
  //
  SpeditorsList,
  SpeditorsNew,
  SpeditorsEdit,
  //
  AllowedIpsList,
  AllowedIpsEdit,
  AllowedIpsNew,
  //
  ProductsList,
  ProductsEdit,
  ProductsNew,
  //
  ProducersList,
  ProducersEdit,
  ProducersNew,
  //
  UsersList,
  UserEdit,
  UserNew,
  //
  ProductGroupsList,
  ProductGroupsNew,
  ProductGroupsEdit,
  //
  CurrencyCountry,
  VatRates,
  SystemSettingsList,
  //
  BrandsList,
  BrandEdit,
  BrandNew,
  NotFound
} from '../pages'

const Router = () => {
  return (
    <Switch>
      <Route
        exact={true}
        path="/"
        component={() => <Redirect to="/products" />}
      />
      <Route exact={true} path="/shops" component={ShopsList} />
      <Route exact={true} path="/shops/new" component={ShopsNew} />
      <Route exact={true} path="/shops/:id" component={ShopsEdit} />
      <Route exact={true} path="/categories" component={CategoriesList} />
      <Route exact={true} path="/categories/new" component={CategoriesNew} />
      <Route exact={true} path="/categories/:id" component={CategoriesEdit} />
      <Route exact={true} path="/channels" component={ChannelsList} />
      <Route exact={true} path="/channels/new" component={ChannelsNew} />
      <Route exact={true} path="/channels/:id" component={ChannelsEdit} />
      <Route exact={true} path="/platforms" component={PlatformsList} />
      <Route exact={true} path="/platforms/new" component={PlatformsNew} />
      <Route exact={true} path="/platforms/:id" component={PlatformsEdit} />
      <Route exact={true} path="/warehouses" component={WarehousesList} />
      <Route exact={true} path="/warehouses/new" component={WarehousesNew} />
      <Route exact={true} path="/warehouses/:id" component={WarehousesEdit} />
      <Route exact={true} path="/speditors" component={SpeditorsList} />
      <Route exact={true} path="/speditors/new" component={SpeditorsNew} />
      <Route exact={true} path="/speditors/:id" component={SpeditorsEdit} />
      <Route exact={true} path="/allowed_ips" component={AllowedIpsList} />
      <Route exact={true} path="/allowed_ips/new" component={AllowedIpsNew} />
      <Route exact={true} path="/allowed_ips/:id" component={AllowedIpsEdit} />
      <Route exact={true} path="/producers" component={ProducersList} />
      <Route exact={true} path="/producers/new" component={ProducersNew} />
      <Route exact={true} path="/producers/:id" component={ProducersEdit} />
      <Route exact={true} path="/products" component={ProductsList} />
      <Route exact={true} path="/products/new" component={ProductsNew} />
      <Route exact={true} path="/products/:id" component={ProductsEdit} />
      <Route
        exact={true}
        path="/product_groups"
        component={ProductGroupsList}
      />
      <Route
        exact={true}
        path="/product_groups/new"
        component={ProductGroupsNew}
      />
      <Route
        exact={true}
        path="/product_groups/:id"
        component={ProductGroupsEdit}
      />
      <Route
        exact={true}
        path="/currency_country"
        component={CurrencyCountry}
      />
      <Route exact={true} path="/users" component={UsersList} />
      <Route exact={true} path="/users/new" component={UserNew} />
      <Route exact={true} path="/users/:id" component={UserEdit} />
      <Route exact={true} path="/vat_rates" component={VatRates} />
      <Route
        exact={true}
        path="/system_settings"
        component={SystemSettingsList}
      />
      <Route exact={true} path="/brands" component={BrandsList} />
      <Route exact={true} path="/brands/new" component={BrandNew} />
      <Route exact={true} path="/brands/:id" component={BrandEdit} />
      <Route component={NotFound} />
    </Switch>
  )
}

export default Router
