const initialState = {
  status: 'invalid',
  filters: {
    open: true,
    query: {}
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PRODUCERS_TOGGLE_FILTERS':
      return {
        ...state,
        filters: { ...state.filters, open: !state.filters.open }
      }
    case 'PRODUCERS_SET_FILTERS_QUERY':
      return {
        ...state,
        filters: { ...state.filters, query: action.query }
      }
    case 'PRODUCERS_SEARCHING':
      return {
        ...state,
        status: 'searching'
      }
    case 'PRODUCERS_LOADING':
      return {
        ...state,
        status: 'loading'
      }
    case 'PRODUCERS_SUCCESS':
      return {
        ...state,
        status: 'success',
        ...action
      }
    case 'PRODUCERS_FAILURE':
      return {
        ...state,
        status: 'failure'
      }
    default:
      return state
  }
}
