import React from 'react'
import { connect } from 'react-redux'
import { Dashboard } from 'components'
import VatRateRow from './components/VatRateRow'

import { ReactComponent as EuroIcon } from 'icons/euro.svg'

const VatRatesPanels = ({ data, countries }) => {
  const vat_rates_groups = countries
    .map(({ code, id }) => ({
      code,
      id,
      items: data.filter(({ country_code }) => country_code === code)
    }))
    .filter(el => el.items.length > 0)
    .sort((item, next) => item.id - next.id)

  return (
    <div className="dashboard-wrapper-flex wrap">
      {vat_rates_groups?.map(({ code, id, items }) => (
        <div className="column-half" key={id}>
          <Dashboard header={{ icon: <EuroIcon />, title: `Kraj: ${code}` }}>
            <table className="table">
              <thead className="table__header table-head">
                <tr>
                  <th>Nazwa</th>
                  <th>Stawka</th>
                  <th width="100" />
                </tr>
              </thead>
              <tbody className="table__body table-body">
                {items?.map(item => (
                  <VatRateRow key={item.id} {...item} />
                ))}
              </tbody>
            </table>
          </Dashboard>
        </div>
      ))}
    </div>
  )
}

export default connect(({ vat_rates: { data, countries } }) => ({
  data,
  countries
}))(VatRatesPanels)
