import React from 'react'
import { toastr } from 'react-redux-toastr'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Button } from 'expano-components'
import {
  Main,
  AsyncSelectField,
  Dashboard,
  InputField,
  InputColorPickerField,
  SelectField
} from 'components'
import { createPlatform } from 'actions/platforms'
import platform_type_options from 'utils/select_options/platform_types'
import getAvailableCountries from 'utils/select_options/available_countries'
import getAvailableCurrencies from 'utils/select_options/available_currencies'

import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as PlatformIcon } from 'icons/platform.svg'

const PlatformNew = ({ handleSubmit, change, pristine, history }) => {
  const onSubmit = values =>
    createPlatform(values)
      .then(data => {
        toastr.success('Sukces', 'Platforma została dodana')
        history.push(`/platforms/${data.id}`)
      })
      .catch(err => {
        toastr.error('Błąd', 'Wystąpił błąd')
        throw new SubmissionError(err)
      })

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: 'Platformy', path: '/platforms' },
          {
            name: 'Dodaj platformę'
          }
        ],
        name: 'Dodaj platformę',
        icon: <PlatformIcon />
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-2">
          <Dashboard
            header={{ icon: <InfoIcon />, title: 'Podstawowe dane' }}
            footer={
              <Button
                disabled={pristine}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="dashboard__form__submit">
                <div className="dashboard-wrapper-flex m-b-10">
                  <div className="column-half">
                    <Field
                      name="name"
                      label="Nazwa platformy"
                      component={InputField}
                      type="text"
                    />
                  </div>
                  <div className="column-half">
                    <div className="form-row grid-2">
                      <Field
                        name="label_text"
                        label="Skrócona nazwa"
                        component={InputField}
                        type="text"
                      />
                      <Field
                        name="label_color"
                        label="Kolor"
                        handleChange={color => change('label_color', color)}
                        component={InputColorPickerField}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="identifier"
                    label="Identyfikator platformy"
                    component={InputField}
                    type="text"
                  />
                  <Field
                    label="Typ platformy"
                    name="type"
                    component={SelectField}
                    options={platform_type_options}
                    change={change}
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="country_code"
                    component={AsyncSelectField}
                    label="Kraj"
                    change={change}
                    getData={getAvailableCountries}
                  />
                  <Field
                    name="currency_code"
                    component={AsyncSelectField}
                    label="Waluta"
                    change={change}
                    getData={getAvailableCurrencies}
                  />
                </div>
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

export default reduxForm({ form: 'create-platform' })(PlatformNew)
