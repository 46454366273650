import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import { ConnectedRouter } from "connected-react-router";
import ReduxToastr from "react-redux-toastr";
import store, { history } from "./store";
import App from "./app";

import "sanitize.css/sanitize.css";
import "./styles/theme.scss";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <CookiesProvider>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <App />
      </CookiesProvider>
    </ConnectedRouter>
  </Provider>
);
