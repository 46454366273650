import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Button } from 'expano-components'
import { createProducerBrand } from 'actions/producers'
import { Modal, InputField } from 'components'
import { ReactComponent as UsersIcon } from 'icons/users.svg'

const AddBrandModal = ({
  handleClose,
  handleSubmit,
  pristine,
  createProducerBrand
}) => {
  const onSubmit = values =>
    createProducerBrand(values)
      .then(handleClose)
      .catch(err => {
        throw new SubmissionError(err)
      })

  return (
    <Modal
      handleClose={handleClose}
      header={{ title: 'Dodaj markę', icon: <UsersIcon /> }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            disabled={pristine}
            type="add-green"
            onClick={handleSubmit(onSubmit)}
            text="Dodaj"
          />
        </>
      }>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field name="name" label="Nazwa" component={InputField} type="text" />
      </form>
    </Modal>
  )
}

export default connect(null, dispatch => ({
  createProducerBrand: data => dispatch(createProducerBrand(data))
}))(reduxForm({ form: 'create-producer-brand' })(AddBrandModal))
