import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import { ModalDelete, Button } from 'expano-components'
import { PanelLoader, Main } from 'components'
import { getProductGroup, deleteProductGroup } from 'actions/product_groups'
import { BasicForm, Channels, VatRates } from './components'

import { ReactComponent as ProductGroupIcon } from 'icons/product_group.svg'

const ProductGroupEdit = ({
  product_group,
  getProductGroup,
  history,
  match: {
    params: { id }
  }
}) => {
  const [show_delete_modal, handleShowDeleteModal] = useState(false)

  useEffect(() => {
    getProductGroup(id)
  }, [])

  if (['invalid', 'loading'].includes(product_group.status)) {
    return <PanelLoader />
  }

  if (product_group.status === 'failure') {
    return <Redirect to="/product_groups" />
  }

  return (
    <Main
      page={{
        breadcrumbs: [
          {
            name: 'Produkty grupowe',
            path: '/product_groups'
          },
          {
            name: product_group.data?.name
          }
        ],
        name: product_group?.data?.name,
        icon: <ProductGroupIcon />,
        buttons: (
          <Button
            type="delete"
            text="Usuń produkt grupowy"
            onClick={() => handleShowDeleteModal(true)}
          />
        )
      }}>
      {show_delete_modal && (
        <ModalDelete
          handleDelete={() =>
            deleteProductGroup(id).then(() => {
              handleShowDeleteModal(false)
              history.push('/product_groups')
            })
          }
          handleClose={() => handleShowDeleteModal(false)}
          name={`produkt grupowy ${product_group?.data?.name}`}
        />
      )}

      <div className="dashboard-wrapper-flex">
        <div className="column-2">
          <BasicForm />
          <VatRates />
        </div>
        <div className="column-1">
          <Channels data={[]} />
        </div>
      </div>
    </Main>
  )
}

export default connect(
  ({ product_group }) => ({
    product_group
  }),
  dispatch => ({
    getProductGroup: id => dispatch(getProductGroup(id))
  })
)(ProductGroupEdit)
