import React from 'react'
import Loader from 'react-loader-spinner'

const PageLoader = ({ width = 40, height = 40 }) => {
  return (
    <div className="main-dashboard">
      <div className="page-loader">
        <div className="loader-holder">
          <Loader type="Watch" color="#fd397a" height={width} width={height} />
        </div>
      </div>
    </div>
  )
}

export default PageLoader
