
import React, { useState } from "react";

let timer;
const Input = ({ data, handleChange }) => {
  const [value, setValue] = useState('');
  return (
    <div className='inp-wrapper'>
      <label htmlFor={data?.name} className='label-form'>
        {data?.label}
      </label>
      <div className='inp-group '>
        <input
          type='text'
          onChange={({ target }) => {
            clearTimeout(timer);
            setValue(target.value);
            timer = setTimeout(() => {
              handleChange(target.value);
            }, 400);
          }}
          id={data?.name}
          name={data?.name}
          value={value}
        />
      </div>
    </div>
  );
};
export default Input;