import React, { useState, useEffect } from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { ModalDelete, Button, Label } from 'expano-components'
import {
  Main,
  PanelLoader,
  Dashboard,
  InputField,
  InputColorPickerField
} from 'components'
import { updateShop, getShop, deleteShop, deleteChannel } from 'actions/shops'
import AddPlatform from './modals/AddPlatform'
import { Redirect } from 'react-router'

import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as PlatformIcon } from 'icons/platform.svg'
import { ReactComponent as ShopIcon } from 'icons/shop.svg'

const ShopEdit = ({
  handleSubmit,
  shop,
  valid,
  pristine,
  change,
  history,
  match: {
    params: { id }
  },
  getShop,
  updateShop,
  deleteChannel
}) => {
  const [is_open_delete_modal, setOpenDeleteModal] = useState(false)
  const [is_open_add_channel_modal, setOpenAddChannelModal] = useState(false)
  const [delete_channel, setDeleteChannel] = useState(null)

  useEffect(() => {
    getShop(id)
  }, [id])

  const onSubmit = values =>
    updateShop(id, values).catch(err => {
      throw new SubmissionError(err)
    })

  const handleDeleteShop = () =>
    deleteShop(id).then(() => history.push('/shops'))

  const handleDeleteChannel = async () => {
    try {
      await deleteChannel(delete_channel.id)
    } catch (err) {
      console.log(err)
    } finally {
      setDeleteChannel(null)
    }
  }

  if (['invalid', 'loading'].includes(shop.status)) {
    return <PanelLoader />
  }

  if (shop.status === 'failure') {
    return <Redirect to="/shops" />
  }

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: 'Sklepy', path: '/shops' },
          {
            name: shop.data?.name
          }
        ],
        name: shop?.data?.full_name,
        icon: <ShopIcon />,
        buttons: (
          <Button
            type="delete"
            text="Usuń sklep"
            onClick={() => setOpenDeleteModal(true)}
          />
        )
      }}>
      {is_open_delete_modal && (
        <ModalDelete
          handleDelete={handleDeleteShop}
          handleClose={() => setOpenDeleteModal(false)}
          name={`sklep ${shop?.data?.name}`}
        />
      )}
      {is_open_add_channel_modal && (
        <AddPlatform
          platforms={shop.platforms}
          handleClose={() => setOpenAddChannelModal(false)}
        />
      )}
      {delete_channel?.id && (
        <ModalDelete
          handleDelete={handleDeleteChannel}
          handleClose={() => setDeleteChannel(null)}
          name={`platformę ${delete_channel?.platform_name}`}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-2">
          <Dashboard
            header={{ icon: <InfoIcon />, title: 'Podstawowe dane' }}
            footer={
              <Button
                disabled={pristine || !valid}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="dashboard__form__submit">
                <div className="dashboard-wrapper-flex m-b-10">
                  <div className="column-half">
                    <div className="form-row grid-2">
                      <Field
                        name="name"
                        label="Nazwa sklepu"
                        component={InputField}
                        type="text"
                      />
                      <Field
                        name="identifier"
                        label="Identyfikator"
                        component={InputField}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="column-half">
                    <div className="form-row grid-2">
                      <Field
                        name="label_text"
                        label="Skrócona nazwa"
                        component={InputField}
                        type="text"
                      />
                      <Field
                        name="label_color"
                        label="Kolor"
                        handleChange={color => change('label_color', color)}
                        component={InputColorPickerField}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="phone"
                    label="Telefon"
                    component={InputField}
                    type="tel"
                  />
                  <Field
                    name="email"
                    label="E-mail"
                    component={InputField}
                    type="email"
                  />
                </div>
                <Field
                  name="bank_account"
                  label="Numer konta bankowego"
                  component={InputField}
                  type="text"
                />
                <Field
                  name="street"
                  label="Adres"
                  component={InputField}
                  type="text"
                />
                <div className="form-row grid-2">
                  <Field
                    name="city"
                    label="Miasto"
                    component={InputField}
                    type="text"
                  />
                  <Field
                    name="zip_code"
                    label="Kod pocztowy"
                    component={InputField}
                    type="text"
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="website"
                    label="Strona internetowa"
                    component={InputField}
                    type="text"
                  />
                </div>
              </div>
            </form>
          </Dashboard>
        </div>
        <div className="column-1 column-1--container">
          <Dashboard
            header={{
              icon: <PlatformIcon />,
              title: 'Platformy'
            }}>
            {shop?.data?.channels?.length > 0 && (
              <ul className="dashboard__list">
                {shop?.data?.channels.map(({ id, platform }) => (
                  <li className="dashboard__list-item" key={id}>
                    <Label
                      type="bordered"
                      text={platform?.label_text}
                      color={`#${platform?.label_color}`}
                    />
                    <span className="platform-name">{platform.name}</span>
                    <span className="country country-label country-label--hidden" />
                    <Button
                      type="delete-bin"
                      onClick={() =>
                        setDeleteChannel({
                          id,
                          platform_name: platform.name
                        })
                      }
                    />
                  </li>
                ))}
              </ul>
            )}
            {shop.platforms?.length > 0 && (
              <div className="dashboard__button-section">
                <Button
                  type="add"
                  text="Dodaj platformę"
                  onClick={() => setOpenAddChannelModal(true)}
                />
              </div>
            )}
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

const mapStateToProps = ({ shop }) => ({
  shop,
  initialValues: {
    bank_account: shop?.data?.bank_account,
    city: shop?.data?.city,
    email: shop?.data?.email,
    identifier: shop?.data?.identifier,
    label_color: shop?.data?.label_color,
    label_text: shop?.data?.label_text,
    name: shop?.data?.name,
    phone: shop?.data?.phone,
    street: shop?.data?.street,
    website: shop?.data?.website,
    zip_code: shop?.data?.zip_code
  }
})

const mapDispatchToProps = dispatch => ({
  updateShop: (id, values) => dispatch(updateShop(id, values)),
  getShop: data => dispatch(getShop(data)),
  deleteChannel: id => dispatch(deleteChannel(id))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'edit-shop', enableReinitialize: true })(ShopEdit))
