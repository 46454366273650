import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, ModalDelete } from 'expano-components'
import EditBundleModal from './EditBundleModal'
import { deleteBundle } from 'actions/products'

const BundleLineItem = ({ id, deleteBundle, quantity, component }) => {
  const [show_modal, handleShow] = useState(false)
  const [show_delete_modal, handleShowDelete] = useState(false)

  return (
    <React.Fragment key={id}>
      {show_modal && (
        <EditBundleModal
          id={id}
          initialValues={{
            quantity,
            component_id: component.id
          }}
          component={component}
          handleClose={() => handleShow(false)}
        />
      )}
      {show_delete_modal && (
        <ModalDelete
          handleDelete={() => deleteBundle(id)}
          handleClose={() => handleShowDelete(false)}
          name={`bundle ${component?.name}`}
        />
      )}
      <li className="dashboard__list-item flex jcsb" key={id}>
        <p className="flex-px-150">{component?.sku}</p>
        <p className="flex-1 p-r-20">{component?.name}</p>
        <p className="flex-px-100">{quantity} szt.</p>
        <p className="flex-px-100">
          <Button type="edit" onClick={() => handleShow(true)} />
          <Button type="delete-bin" onClick={() => handleShowDelete(true)} />
        </p>
      </li>
    </React.Fragment>
  )
}

export default connect(null, dispatch => ({
  deleteBundle: id => dispatch(deleteBundle(id))
}))(BundleLineItem)
