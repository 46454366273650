import React, { useState } from "react";
import { connect } from "react-redux";
import { Label, Button, ModalDelete } from "expano-components";
import { deleteProductChannel } from "actions/products";

const ChannelListItem = ({
  deleteProductChannel,
  id,
  channel: { id: channel_id, identifier, shop, platform },
  brand_channel_ids,
}) => {
  const [show_delete_modal, handleShowDeleteModal] = useState(false);
  const is_not_from_brand_channel = !brand_channel_ids?.includes(channel_id);

  return (
    <>
      {show_delete_modal && (
        <ModalDelete
          handleDelete={() => deleteProductChannel(id)}
          handleClose={() => handleShowDeleteModal(false)}
          name={`produkt z kanału ${identifier}`}
        />
      )}
      <li className="dashboard__list-item" key={id}>
        <Label
          type="double"
          left={{
            text: shop?.label_text,
            color: shop?.label_color,
          }}
          right={{
            text: platform?.label_text,
            color: `#${platform?.label_color}`,
          }}
        />
        <span className="platform-name">{identifier}</span>
        <span className="country country-label">
          {is_not_from_brand_channel && (
            <Label type="fill" color="#fd397a" text="Spoza marki" />
          )}
        </span>
        <Button type="delete-bin" onClick={() => handleShowDeleteModal(true)} />
      </li>
    </>
  );
};

export default connect(
  ({
    product: {
      data: {
        brand: { brand_channels },
      },
    },
  }) => ({
    brand_channel_ids: brand_channels?.map(({ channel_id }) => channel_id),
  }),
  (dispatch) => ({
    deleteProductChannel: (id) => dispatch(deleteProductChannel(id)),
  })
)(ChannelListItem);
