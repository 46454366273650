import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Button } from 'expano-components'
import { Main, Dashboard, InputField, AsyncSelectField } from 'components'
import { createCategory } from 'actions/categories'
import getCategoryOptions from 'utils/select_options/category_options'

import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as ListIcon } from 'icons/list.svg'

const CategoryNew = ({ handleSubmit, pristine, change, history }) => {
  const onSubmit = values =>
    createCategory(values)
      .then(() => history.push('/categories'))
      .catch(err => {
        throw new SubmissionError(err)
      })

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: 'Kategorie', path: '/categories' },
          {
            name: 'Dodaj kategorię'
          }
        ],
        name: 'Dodaj kategorię',
        icon: <ListIcon />
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{
              title: 'Podstawowe dane',
              icon: <InfoIcon />
            }}
            footer={
              <Button
                disabled={pristine}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="dashboard__form__submit">
                <div className="form-row grid-2">
                  <Field
                    name="name"
                    label="Nazwa kategorii"
                    component={InputField}
                    type="email"
                  />
                  <Field
                    name="parent_id"
                    component={InputField}
                    type="hidden"
                  />
                  <Field
                    name="parent_id"
                    label="Rodzic"
                    change={change}
                    component={AsyncSelectField}
                    getData={getCategoryOptions}
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="identifier"
                    label="Identyfikator"
                    component={InputField}
                    type="text"
                  />
                </div>
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

export default reduxForm({ form: 'create-category', enableReinitialize: true })(
  CategoryNew
)
