import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Button, ImageUploader, ModalDelete } from 'expano-components'
import { url } from 'utils/api'
import { Main, PanelLoader, Dashboard, InputField } from 'components'
import {
  getSpeditor,
  deleteSpeditor,
  updateSpeditor,
  updateSpeditorImage
} from 'actions/speditors'

import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as SpeditorIcon } from 'icons/speditor.svg'

const SpeditorEdit = ({
  history,
  submitting,
  handleSubmit,
  pristine,
  speditor,
  match: {
    params: { id }
  },
  getSpeditor,
  updateSpeditor,
  updateSpeditorImage
}) => {
  const [is_modal_open, handleOpenModal] = useState(false)

  useEffect(() => {
    getSpeditor(id)
  }, [id])

  const onSubmit = values =>
    updateSpeditor(id, values).catch(err => {
      throw new SubmissionError(err)
    })

  const handleDelete = () =>
    deleteSpeditor(id).then(() => history.push('/speditors'))

  const handleUploadImage = file =>
    new Promise((resolve, reject) => {
      try {
        const body = new FormData()
        body.append('image', file)
        updateSpeditorImage(id, body)
        resolve()
      } catch (error) {
        reject()
      }
    })

  if (['invalid', 'loading'].includes(speditor.status)) {
    return <PanelLoader />
  }

  if (speditor.status === 'failure') {
    return <Redirect to="/speditors" />
  }

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: 'Spedytorzy', path: '/speditors' },
          {
            name: speditor.data?.name
          }
        ],
        name: speditor?.data?.name,
        icon: <SpeditorIcon />,
        buttons: (
          <Button
            type="delete"
            text="Usuń spedytora"
            onClick={() => handleOpenModal(true)}
          />
        )
      }}>
      {is_modal_open && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => handleOpenModal(false)}
          name={`spedytora ${speditor.data?.name}`}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{ icon: <InfoIcon />, title: 'Podstawowe dane' }}
            footer={
              <Button
                disabled={pristine || submitting}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="dashboard__form__submit">
                <div className="form-row grid-2">
                  <Field
                    name="identifier"
                    label="Identyfikator"
                    component={InputField}
                    type="text"
                  />
                  <Field
                    name="name"
                    label="Nazwa spedytora"
                    component={InputField}
                    type="text"
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="tracking_url"
                    label="Tracking URL"
                    component={InputField}
                    type="text"
                  />
                </div>
              </div>
            </form>
          </Dashboard>
        </div>
        <div className="column-half">
          <Dashboard header={{ icon: <InfoIcon />, title: 'Logo' }}>
            <ImageUploader
              addText="dodaj logo"
              handleDelete={() => updateSpeditor(id, { image: null })}
              handleUpload={handleUploadImage}
              url={url}
              images={
                speditor.data?.image_url
                  ? [{ path: speditor.data.image_url }]
                  : []
              }
              limit={1}
            />
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

const mapStateToProps = ({ speditor }) => ({
  speditor,
  initialValues: {
    name: speditor?.data?.name,
    identifier: speditor?.data?.identifier,
    tracking_url: speditor?.data?.tracking_url
  }
})

const mapDispatchToProps = dispatch => ({
  getSpeditor: id => dispatch(getSpeditor(id)),
  updateSpeditor: (id, data) => dispatch(updateSpeditor(id, data)),
  updateSpeditorImage: (id, data) => dispatch(updateSpeditorImage(id, data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'edit-speditor',
    enableReinitialize: true
  })(SpeditorEdit)
)
