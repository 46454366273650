import { toastr } from 'react-redux-toastr'
import { client, handleErrors, stringifyQuery } from 'utils/api'

export const toggleFilters = () => dispatch =>
  dispatch({ type: 'PRODUCT_GROUPS_TOGGLE_FILTERS' })

export const filterProductGroups = query => async dispatch =>
  dispatch({ type: 'PRODUCT_GROUPS_SET_FILTERS_QUERY', query })

export const getProductGroups = ({
  page = 1,
  per_page = 10,
  ...rest
}) => async (dispatch, getState) => {
  try {
    const {
      status,
      filters: { query }
    } = getState().product_groups

    dispatch({
      type:
        status === 'invalid'
          ? 'PRODUCT_GROUPS_LOADING'
          : 'PRODUCT_GROUPS_SEARCHING'
    })

    const { data, meta } = await client.get(
      `/product_groups?${stringifyQuery({
        ...rest,
        page,
        per_page,
        context: 'ui_index',
        ...query
      })}`
    )
    dispatch({
      type: 'PRODUCT_GROUPS_SUCCESS',
      data,
      meta
    })
  } catch (error) {
    dispatch({ type: 'PRODUCT_GROUPS_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podczas pobierania')
  }
}

export const getProductGroup = id => async (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    const { product_group } = getState()
    let countries = product_group.countries
    try {
      if (product_group.status === 'invalid') {
        const { data } = await client.get('/available_countries')
        countries = data
      }

      if (
        product_group.status === 'invalid' ||
        product_group?.data?.id !== id
      ) {
        dispatch({ type: 'PRODUCT_GROUP_LOADING' })
      }
      
      const { data } = await client.get(`/product_groups/${id}?context=ui_show`)

      const channels_ids = data.channel_product_groups.map(
        ({ channel: { id } }) => id
      )

      const { data: available_channels } = await client.get(
        `/channels?${stringifyQuery({
          context: 'ui_index',
          per_page: 100,
          'q[id_not_in]': channels_ids
        })}`
      )

      dispatch({
        type: 'PRODUCT_GROUP_SUCCESS',
        data,
        countries,
        available_channels
      })
      resolve(data)
    } catch (error) {
      console.log(error)
      dispatch({ type: 'PRODUCT_GROUP_FAILURE' })
      toastr.error('Błąd', 'Wystąpił błąd podzczas pobierania')
      reject(error)
    }
  })

export const createProductGroup = values =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post(`/product_groups`, values)
      resolve(data)
      toastr.success('Sukces', 'Dodano produkt grupowy')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const updateProductGroup = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/product_groups/${id}`, values)
      await dispatch(getProductGroup(id))
      resolve()
      toastr.success('Sukces', 'Produkt grupowy został zaktualizowany')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')

      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const deleteProductGroup = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/product_groups/${id}`)
      toastr.success('Sukces', 'Usunięto produkt grupowy')
      resolve()
    } catch (err) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(err.response.data.error)
    }
  })

export const createProductGroupChannels = channel_ids => async (
  dispatch,
  getState
) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id: product_group_id }
      } = getState().product_group

      await Promise.all(
        channel_ids.map(channel_id =>
          client.post('/channel_product_groups', {
            channel_id,
            product_group_id
          })
        )
      )

      await dispatch(getProductGroup(product_group_id))
      resolve()
      toastr.success('Sukces', 'Kanały zostały dodane')
    } catch (error) {
      console.log(error)
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const deleteChannel = id => async (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id: product_group_id }
      } = getState().product_group

      await client.delete(`/channel_product_groups/${id}`)
      await dispatch(getProductGroup(product_group_id))

      resolve()
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(error?.response?.data?.error)
    }
  })

export const createProductGroupVatRates = product_group_vat_rates => async (
  dispatch,
  getState
) => {
  try {
    const { data } = getState().product_group

    await Promise.all(
      product_group_vat_rates.map(({ id, product_group_id, vat_rate_id }) =>
        id
          ? client.put(`/product_group_vat_rates/${id}`, {
              product_group_id,
              vat_rate_id
            })
          : client.post('product_group_vat_rates', {
              product_group_id,
              vat_rate_id
            })
      )
    )
    await dispatch(getProductGroup(data.id))
    toastr.success('Sukces', 'Zaktualizowano stawki VAT')
  } catch (error) {
    console.log(error)
  }
}
