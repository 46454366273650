import React from "react";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from "redux-form";
import { connect } from "react-redux";
import { Button } from "expano-components";
import {
  InputField,
  CheckField,
  AsyncSelectField,
  Dashboard,
} from "components";
import { updateProductGroup } from "actions/product_groups";

import getCategoryOptions from "utils/select_options/category_options";
import getProducerOptions from "utils/select_options/producer_options";
import getBrandOptions from "utils/select_options/brand_options";

import { ReactComponent as InfoIcon } from "icons/info.svg";

const selector = formValueSelector("edit-product-group");

const BasicForm = ({
  product_group,
  updateProductGroup,
  handleSubmit,
  pristine,
  change,
  producer_id,
  brand_id,
}) => {
  const submit = (values) =>
    updateProductGroup(product_group.data?.id, values).catch((err) => {
      throw new SubmissionError(err);
    });

  const { category, producer, brand } = product_group.data;

  return (
    <Dashboard
      header={{ icon: <InfoIcon />, title: "Podstawowe dane" }}
      footer={
        <Button
          disabled={pristine}
          type="save"
          onClick={handleSubmit(submit)}
        />
      }
    >
      <form onSubmit={handleSubmit(submit)} noValidate>
        <div className="dashboard__form__submit">
          <Field
            name="name"
            label="Nazwa produktu"
            component={InputField}
            type="email"
          />

          <div className="form-row grid-2">
            <Field
              name="category_id"
              component={AsyncSelectField}
              label="Kategoria"
              change={change}
              getData={getCategoryOptions}
              initValue={
                category?.id
                  ? {
                      label: `[${category.identifier}] ${category.name}`,
                      value: category,
                    }
                  : null
              }
            />
            <Field name="sku" label="SKU" component={InputField} type="text" />
          </div>
          <div className="form-row grid-2">
            <Field
              name="producer_id"
              component={AsyncSelectField}
              label="Producent"
              change={change}
              afterChange={() => change("brand_id", null)}
              getData={getProducerOptions}
              initValue={
                producer?.id
                  ? { label: producer.name, value: producer.id }
                  : null
              }
            />
            <Field
              key={producer_id}
              isDisabled={!producer_id}
              name="brand_id"
              component={AsyncSelectField}
              label="Marka"
              change={change}
              getData={(q) => getBrandOptions(q, producer_id)}
              initValue={
                brand_id && brand?.id
                  ? { label: brand.name, value: brand.id }
                  : null
              }
            />
          </div>
          <Field
            name="is_required_gpsr_document"
            label="Wymaga dokumentu GPSR"
            component={CheckField}
          />
        </div>
      </form>
    </Dashboard>
  );
};

const mapStateToProps = (state) => ({
  product_group: state.product_group,
  initialValues: {
    category_id: state.product_group.data?.category?.id,
    producer_id: state.product_group.data?.producer?.id,
    brand_id: state.product_group.data?.brand?.id,
    sku: state.product_group.data?.sku,
    name: state.product_group.data?.name,
    is_required_gpsr_document:
      state.product_group.data?.is_required_gpsr_document,
  },
  producer_id:
    selector(state, "producer_id") ||
    state.product_group.data?.producer?.id ||
    null,
  brand_id:
    selector(state, "brand_id") || state.product_group.data?.brand?.id || null,
});

const mapDispatchToProps = (dispatch) => ({
  updateProductGroup: (id, data) => dispatch(updateProductGroup(id, data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: "edit-product-group", enableReinitialize: true })(BasicForm)
);
