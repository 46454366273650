import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button } from 'expano-components'
import { Dashboard } from 'components'
import AddCurrency from './components/AddCurrency'
import CurrencyRow from './components/CurrencyRow'

import { ReactComponent as EuroIcon } from 'icons/euro.svg'

const PanelCurrency = ({ currencies }) => {
  const [is_open_add_modal, handleOpenAddModal] = useState(false)
  return (
    <div className="column-half">
      {is_open_add_modal && (
        <AddCurrency handleClose={() => handleOpenAddModal(false)} />
      )}
      <Dashboard
        header={{
          title: 'Waluty',
          icon: <EuroIcon />,
          aside: (
            <Button
              type="add"
              text="Dodaj walutę"
              onClick={() => handleOpenAddModal(true)}
            />
          )
        }}>
        <table className="table">
          <thead className="table__header table-head">
            <tr>
              <th>Waluta</th>
              <th width="100">Działania</th>
            </tr>
          </thead>
          <tbody className="table__body table-body">
            {currencies?.length > 0 &&
              currencies.map(item => <CurrencyRow key={item.id} {...item} />)}
          </tbody>
        </table>
      </Dashboard>
    </div>
  )
}

export default connect(
  ({ currencies: { data: currencies } }) => ({
    currencies
  }),
  null
)(PanelCurrency)
