import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Button } from 'expano-components'
import { createVatRate } from 'actions/vat_rates'
import { Modal, InputField, SelectField } from 'components'
import { ReactComponent as EuroIcon } from 'icons/euro.svg'

const AddVatRateModal = ({
  handleClose,
  createVatRate,
  handleSubmit,
  pristine,
  change,
  countries
}) => {
  const onSubmit = values =>
    createVatRate(values)
      .then(handleClose)
      .catch(err => {
        console.log(err)
        throw new SubmissionError(err)
      })

  return (
    <Modal
      handleClose={handleClose}
      header={{ icon: <EuroIcon />, title: 'Dodaj VAT' }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            disabled={pristine}
            type="add-green"
            onClick={handleSubmit(onSubmit)}
            text="Dodaj"
          />
        </>
      }>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="country_code"
          component={SelectField}
          change={change}
          label="Kraj"
          options={countries?.map(({ code }) => ({
            label: code,
            value: code
          }))}
          handleChange={val => change('country_code', val)}
        />
        <Field name="name" label="Nazwa" component={InputField} type="text" />
        <Field
          name="value"
          label="Stawka"
          postfix="%"
          component={InputField}
          type="number"
        />
      </form>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  createVatRate: data => dispatch(createVatRate(data))
})
export default connect(
  ({ vat_rates: { countries } }) => ({ countries }),
  mapDispatchToProps
)(reduxForm({ form: 'create-vat-rate-form' })(AddVatRateModal))
