import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Button } from 'expano-components'
import { Main, Dashboard, InputField } from 'components'
import { createSpeditor } from 'actions/speditors'

import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as SpeditorIcon } from 'icons/speditor.svg'

const SpeditorNew = ({ history, handleSubmit, pristine }) => {
  const onSubmit = values =>
    createSpeditor(values)
      .then(({ id }) => history.push(`/speditors/${id}`))
      .catch(err => {
        throw new SubmissionError(err)
      })

  return (
    <Main
      page={{
        name: 'Dodaj spedytora',
        icon: <SpeditorIcon />,
        breadcrumbs: [
          { name: 'Spedytorzy', path: '/speditors' },
          {
            name: 'Dodaj spedytora'
          }
        ]
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{ title: 'Podstawowe dane', icon: <InfoIcon /> }}
            footer={
              <Button
                disabled={pristine}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="dashboard__form__submit">
                <div className="form-row grid-2">
                  <Field
                    name="identifier"
                    label="Identyfikator"
                    component={InputField}
                    type="text"
                  />
                  <Field
                    name="name"
                    label="Nazwa spedytora"
                    component={InputField}
                    type="text"
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="tracking_url"
                    label="Tracking URL"
                    component={InputField}
                    type="text"
                  />
                </div>
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

export default reduxForm({ form: 'create-speditor', enableReinitialize: true })(
  SpeditorNew
)
