import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Button, ModalDelete } from 'expano-components'
import {
  PanelLoader,
  Main,
  Dashboard,
  InputField,
  CheckField,
  SelectField
} from 'components'
import {
  getWarehouse,
  deleteWarehouse,
  updateWarehouse
} from 'actions/warehouses'

import warehouse_country_options from 'utils/select_options/warehouse_country'

import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as WarehouseIcon } from 'icons/warehouse.svg'

const WarehouseEdit = ({
  submitting,
  handleSubmit,
  pristine,
  warehouse,
  change,
  history,
  match: {
    params: { id }
  },
  getWarehouse,
  updateWarehouse
}) => {
  const [is_open_modal, handleOpenModal] = useState(false)

  useEffect(() => {
    getWarehouse(id)
  }, [id])

  const onSubmit = values =>
    updateWarehouse(id, values).catch(err => {
      throw new SubmissionError(err)
    })

  const handleDelete = () =>
    deleteWarehouse(id).then(() => {
      history.push('/warehouses')
    })

  if (['invalid', 'loading'].includes(warehouse.status)) {
    return <PanelLoader />
  }

  if (warehouse.status === 'failure') {
    return <Redirect to="/warehouses" />
  }

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: 'Magazyny', path: '/warehouses' },
          { name: warehouse?.data?.name }
        ],
        name: warehouse?.data?.name,
        icon: <WarehouseIcon />,
        buttons: (
          <Button
            type="delete"
            text="Usuń magazyn"
            onClick={() => handleOpenModal(true)}
          />
        )
      }}>
      {is_open_modal && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => handleOpenModal(false)}
          name={`magazyn ${warehouse.data.name}`}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{ icon: <InfoIcon />, title: 'Podstawowe dane' }}
            footer={
              <Button
                disabled={pristine || submitting}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="dashboard__form__submit">
                <div className="form-row grid-2">
                  <Field
                    name="identifier"
                    label="Identyfikator"
                    component={InputField}
                    type="text"
                  />
                  <Field
                    name="name"
                    label="Nazwa magazynu"
                    component={InputField}
                    type="text"
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="street"
                    label="Ulica"
                    component={InputField}
                    type="text"
                  />
                  <Field
                    name="city"
                    label="Miasto"
                    component={InputField}
                    type="text"
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="zip_code"
                    label="Kod pocztowy"
                    component={InputField}
                    type="text"
                  />
                  <Field
                    label="Kraj"
                    name="country_code"
                    component={SelectField}
                    options={warehouse_country_options}
                    defaultValue={warehouse_country_options.find(
                      ({ value }) => value === warehouse.data.country_code
                    )}
                    change={change}
                  />
                </div>
                <Field
                  name="is_virtual"
                  label="Magazyn wirtualny"
                  component={CheckField}
                  mainLabel="Typ magazynu"
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

const mapStateToProps = ({ warehouse }) => ({
  warehouse,
  initialValues: {
    identifier: warehouse?.data?.identifier,
    name: warehouse?.data?.name,
    country_code: warehouse?.data?.country_code,
    zip_code: warehouse?.data?.zip_code,
    city: warehouse?.data?.city,
    street: warehouse?.data?.street,
    is_virtual: warehouse?.data?.is_virtual
  }
})
const mapDispatchToProps = dispatch => ({
  getWarehouse: id => dispatch(getWarehouse(id)),
  updateWarehouse: (id, data) => dispatch(updateWarehouse(id, data))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'edit-warehouse',
    enableReinitialize: true
  })(WarehouseEdit)
)
