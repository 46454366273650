import React, { useState } from "react";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { Dashboard } from "components";
import VirtualLineItem from "./components/VirtualLineItem";
import AddVirtualModal from "./components/AddVirtualModal";

import { ReactComponent as ProductIcon } from "icons/product.svg";

const Virtuals = ({ product_substitutes_as_virtual }) => {
  const [show_add_virtual, setShowAddVirtual] = useState(false);
  return (
    <>
      {show_add_virtual && (
        <AddVirtualModal handleClose={() => setShowAddVirtual(false)} />
      )}
      <Dashboard
        header={{
          title: "Produkty substytucyjne (virtual)",
          icon: <ProductIcon />,
        }}
      >
        {product_substitutes_as_virtual?.length > 0 && (
          <ul className="dashboard__list">
            {product_substitutes_as_virtual.map((item) => (
              <VirtualLineItem key={item.id} {...item} />
            ))}
          </ul>
        )}
        <div className="dashboard__button-section">
          <Button
            type="add"
            onClick={() => setShowAddVirtual(true)}
            text="Dodaj produkt"
          />
        </div>
      </Dashboard>
    </>
  );
};

export default connect(
  ({ product }) => ({
    product_substitutes_as_virtual:
      product?.data?.product_substitutes_as_virtual,
  }),
  null
)(Virtuals);
