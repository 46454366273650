import React, { useState, useEffect } from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import { Button, ModalDelete } from 'expano-components'
import { Main, PanelLoader, InputField, Dashboard } from 'components'
import {
  getAllowedIp,
  updateAllowedIp,
  deleteAllowedIp
} from 'actions/allowed_ips'

import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as AllowedIpIcon } from 'icons/allowed_ip.svg'

const AllowedIpEdit = ({
  handleSubmit,
  allowed_ip,
  pristine,
  valid,
  history,
  match: {
    params: { id }
  },
  getAllowedIp,
  updateAllowedIp
}) => {
  const [is_open_delete_modal, handleOpenDeleteModal] = useState(false)

  useEffect(() => {
    getAllowedIp(id)
  }, [id])
  const onSubmit = values =>
    updateAllowedIp(id, values).catch(err => {
      throw new SubmissionError(err)
    })

  const handleDelete = () =>
    deleteAllowedIp(id).then(() => history.push('/allowed_ips'))

  if (['invalid', 'loading'].includes(allowed_ip.status)) {
    return <PanelLoader />
  }

  if (allowed_ip.status === 'failure') {
    return <Redirect to="/allowed_ips" />
  }

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: 'Dozwolone IP', path: '/allowed_ips' },
          { name: allowed_ip?.data?.name }
        ],
        name: allowed_ip?.data?.name,
        icon: <AllowedIpIcon />,
        buttons: (
          <Button
            type="delete"
            text="Usuń IP"
            onClick={() => handleOpenDeleteModal(true)}
          />
        )
      }}>
      {is_open_delete_modal && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => handleOpenDeleteModal(false)}
          name={`adres IP (${allowed_ip?.data?.name} : ${allowed_ip?.data?.ip_address})`}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{
              title: 'Podstawowe dane',
              icon: <InfoIcon />
            }}
            footer={
              <Button
                disabled={pristine || !valid}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="dashboard__form__submit">
                <Field
                  name="name"
                  label="Nazwa"
                  component={InputField}
                  type="email"
                />
                <Field
                  name="ip_address"
                  label="Adres IP"
                  component={InputField}
                  type="text"
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

const mapStateToProps = ({ allowed_ip }) => ({
  allowed_ip,
  initialValues: {
    name: allowed_ip?.data?.name,
    ip_address: allowed_ip?.data?.ip_address
  }
})

const mapDispatchToProps = dispatch => ({
  updateAllowedIp: (id, data) => dispatch(updateAllowedIp(id, data)),
  deleteAllowedIp: id => dispatch(deleteAllowedIp(id)),
  getAllowedIp: id => dispatch(getAllowedIp(id))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({ form: 'edit-allowed-ip', enableReinitialize: true })(
    AllowedIpEdit
  )
)
