import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Button } from "expano-components";
import { Main, InputField, Dashboard, AsyncSelectField } from "components";
import { createBrand } from "actions/brands";
import getProducerOptions from "utils/select_options/producer_options";

import { ReactComponent as InfoIcon } from "icons/info.svg";
import { ReactComponent as UsersIcon } from "icons/users.svg";

const BrandNew = ({ handleSubmit, pristine, change, history }) => {
  const onSubmit = (values) =>
    createBrand(values)
      .then(({ id }) => history.push(`/brands/${id}`))
      .catch((err) => {
        throw new SubmissionError(err);
      });

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: "Marki", path: "/brands" },
          {
            name: "Dodaj markę",
          },
        ],
        name: "Dodaj markę",
        icon: <UsersIcon />,
      }}
    >
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{ icon: <InfoIcon />, title: "Podstawowe dane" }}
            footer={
              <Button
                disabled={pristine}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }
          >
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="dashboard__form__submit">
                <Field
                  name="name"
                  label="Nazwa"
                  component={InputField}
                  type="text"
                />
                <Field
                  name="email"
                  label="E-mail"
                  component={InputField}
                  type="text"
                />
                <Field
                  label="Producent"
                  name="producer_id"
                  component={AsyncSelectField}
                  change={change}
                  getData={getProducerOptions}
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  );
};

export default reduxForm({ form: "create-brand", enableReinitialize: true })(
  BrandNew
);
