import { Label } from "expano-components";
import { client } from "utils/api";

const getChannelOptions = (q = null) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/channels?context=ui_index&per_page=50${
          q ? `&q[name_matches]=%25${q}%25` : ""
        }`
      );
      resolve(
        data.map(({ id, shop, platform, identifier }) => ({
          label: (
            <p>
              <Label
                type="double"
                left={{
                  text: shop?.label_text,
                  color: shop?.label_color,
                }}
                right={{
                  text: platform?.label_text,
                  color: `#${platform?.label_color}`,
                }}
              />
              {identifier}
            </p>
          ),
          value: id,
        }))
      );
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });

export default getChannelOptions;
