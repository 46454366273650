import React, { useState } from 'react'
import { ReactComponent as EditIcon } from 'icons/edit.svg'
import EditSystemSetting from './EditSystemSetting'

const Row = ({ id, name, value, kind, group }) => {
  const [is_modal_open, handleOpenModal] = useState(false)
  return (
    <tr key={id}>
      <td>{name}</td>
      <td>{value}</td>
      <td>{group}</td>
      <td>
        <button
          type="button"
          className="btn btn-icon"
          onClick={() => handleOpenModal(true)}>
          <EditIcon />
        </button>
      </td>
      {is_modal_open && (
        <EditSystemSetting
          {...{ id, value, name, kind, initialValues: { value } }}
          handleClose={() => {
            handleOpenModal(false)
          }}
        />
      )}
    </tr>
  )
}
export default Row
