const initialState = {
  status: 'invalid',
  data: null,
  countries: [],
  available_channels: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PRODUCT_GROUP_LOADING':
      return {
        ...state,
        status: 'loading'
      }
    case 'PRODUCT_GROUP_SUCCESS':
      return {
        ...state,
        status: 'success',
        data: action.data,
        available_channels: action.available_channels,
        countries: action.countries || state.countries
      }
    case 'PRODUCT_GROUP_FAILURE':
      return {
        ...state,
        status: 'failure',
        err: action.err
      }
    default:
      return state
  }
}
