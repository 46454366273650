import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { PanelLoader, Main, Button, Dashboard } from 'components'
import { getCategories } from 'actions/categories'
import Tree from './components/Tree'

import { ReactComponent as ListIcon } from 'icons/list.svg'

const CategoriesList = ({ categories, history, getCategories }) => {
  useEffect(() => {
    getCategories()
  }, [])

  if (['invalid', 'loading'].includes(categories.status)) {
    return <PanelLoader />
  }

  return (
    <Main
      page={{
        breadcrumbs: [{ name: 'Kategorie' }],
        name: 'Kategorie',
        icon: <ListIcon />,
        buttons: (
          <Button type="add" text="Dodaj kategorię" path="/categories/new" />
        )
      }}>
      <Dashboard>
        {categories?.data?.length > 0 && (
          <Tree
            history={history}
            head={['Nazwa', 'ID']}
            data={categories.data}
            row_data={['identifier']}
            path={'/categories'}
          />
        )}
      </Dashboard>
    </Main>
  )
}

export default connect(
  ({ categories }) => ({ categories }),
  dispatch => ({
    getCategories: loading => dispatch(getCategories(loading))
  })
)(CategoriesList)
