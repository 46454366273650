const initialState = {
  status: 'invalid'
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PLATFORM_RESET':
      return {
        status: 'invalid'
      }
    case 'PLATFORM_LOADING':
      return {
        ...state,
        status: 'loading'
      }
    case 'PLATFORM_SUCCESS':
      return {
        ...state,
        status: 'success',
        data: action.data,
        shops: action.shops,
      }

    case 'PLATFORM_FAILURE':
      return {
        ...state,
        status: 'PLATFORM_FAILURE',
        err: action.err
      }
    default:
      return state
  }
}
