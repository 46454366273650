import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Button } from 'expano-components'
import { Dashboard, Main, InputField, CheckField } from 'components'
import { createUser } from 'actions/users'

import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as UserIcon } from 'icons/user.svg'

const UserNew = ({ history, handleSubmit, pristine }) => {
  const onSubmit = async values => {
    try {
      const { id } = await createUser(values)
      history.push(`/users/${id}`)
    } catch (error) {
      throw new SubmissionError(error)
    }
  }

  return (
    <Main
      page={{
        name: 'Dodaj użytkownika',
        icon: <UserIcon />,
        breadcrumbs: [
          { name: 'Użytkownicy', path: '/users' },
          {
            name: 'Dodaj użytkownika'
          }
        ]
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{
              title: 'Podstawowe dane',
              icon: <InfoIcon />
            }}
            footer={
              <Button
                disabled={pristine}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <form noValidate>
              <div className="dashboard__form__submit">
                <Field
                  name="is_active"
                  label="Aktywny"
                  component={CheckField}
                />
                <Field
                  name="email"
                  label="Adres e-mail"
                  component={InputField}
                  type="text"
                />
                <Field
                  name="first_name"
                  label="Imię"
                  component={InputField}
                  type="text"
                />
                <Field
                  name="last_name"
                  label="Nazwisko"
                  component={InputField}
                  type="text"
                />
                <Field
                  name="password"
                  label="Hasło"
                  component={InputField}
                  type="password"
                />
                <Field
                  name="password_confirmation"
                  label="Powtórz hasło"
                  component={InputField}
                  type="password"
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

export default reduxForm({ form: 'create-user', enableReinitialize: true })(
  UserNew
)
