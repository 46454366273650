import React from 'react'
import { Main, Dashboard } from 'components'

import { ReactComponent as InfoIcon } from 'icons/info.svg'

const NotFound = ({ location: { pathname } }) => (
  <Main
    page={{ name: '404', icon: <InfoIcon />, breadcrumbs: [{ name: '404' }] }}>
    <Dashboard>
      <div className="p-20">{pathname} - strona nie istnieje</div>
    </Dashboard>
  </Main>
)

export default NotFound
