import { toastr } from 'react-redux-toastr'
import { client, handleErrors, stringifyQuery } from 'utils/api'

export const toggleFilters = () => dispatch =>
  dispatch({ type: 'PLATFORMS_TOGGLE_FILTERS' })

export const filterPlatforms = query => async dispatch =>
  dispatch({ type: 'PLATFORMS_SET_FILTERS_QUERY', query })

export const getPlatforms = ({ page = 1, per_page = 10, ...rest }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      status,
      filters: { query }
    } = getState().platforms

    dispatch({
      type: status === 'invalid' ? 'PLATFORMS_LOADING' : 'PLATFORMS_SEARCHING'
    })

    const { data, meta } = await client.get(
      `/platforms?${stringifyQuery({
        ...rest,
        page,
        per_page,
        context: 'ui_index',
        ...query
      })}`
    )

    dispatch({
      type: 'PLATFORMS_SUCCESS',
      data,
      meta
    })
  } catch (error) {
    dispatch({ type: 'PLATFORMS_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podczas pobierania')
  }
}

export const getPlatform = id => async (dispatch, getState) => {
  try {
    const { platform } = getState()

    if (platform.status === 'invalid' || platform?.data?.id !== id) {
      dispatch({ type: 'PLATFORM_LOADING' })
    }

    const { data } = await client.get(`/platforms/${id}?context=ui_show`)
    const shop_ids = data.channels.map(({ shop: { id } }) => id)

    const { data: shops } = await client.get(
      `/shops?${stringifyQuery({
        context: 'list',
        per_page: 100,
        'q[id_not_in]': shop_ids
      })}`
    )

    dispatch({
      type: 'PLATFORM_SUCCESS',
      data,
      shops
    })
  } catch (error) {
    dispatch({ type: 'PLATFORM_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podzczas pobierania')
  }
}

export const createPlatform = values =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post(`/platforms`, values)
      resolve(data)
      toastr.success('Sukces', 'Platforma została dodana')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd podzczas tworzenia platformy')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const updatePlatform = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/platforms/${id}`, values)
      const { data } = await client.get(`/platforms/${id}?context=ui_show`)
      dispatch({
        type: 'PLATFORM_SUCCESS',
        data
      })
      resolve()
      toastr.success('Sukces', 'Platforma została zaktualizowana')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const deletePlatform = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/platforms/${id}`)
      toastr.success('Sukces', 'Usunięto platformę')
      resolve()
    } catch (error) {
      reject(error)
      toastr.error('Błąd', 'Wystąpił błąd')
    }
  })

export const createChannel = values => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const { id } = getState().platform.data
      await client.post('/channels', values)
      dispatch(getPlatform(id))
      resolve()
    } catch (error) {
      reject(error?.response?.data?.error)
    }
  })

export const deleteChannel = channel_id => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const { id } = getState().platform.data
      await client.delete(`/channels/${channel_id}`)
      dispatch(getPlatform(id))
      resolve()
      toastr.success('Sukces', 'Sklep został usunięty')
    } catch (error) {
      reject(error?.response?.data?.error)
      toastr.error('Błąd', 'Sklep nie został usunięty')
    }
  })
