import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Label } from 'expano-components'
import { Button, MainTable, Main, Dashboard } from 'components'
import { parseQuery } from 'utils/api'
import { getShops } from 'actions/shops'

import { ReactComponent as ShopIcon } from 'icons/shop.svg'

const ShopsList = ({ shops, getShops, history, location: { search } }) => {
  const query = parseQuery(search)
  const [is_loaded, setLoaded] = useState(false)
  const sort = {
    'sort[column]': query['sort[column]'] || 'id',
    'sort[order]': query['sort[order]'] || 'asc'
  }
  useEffect(() => {
    getShops({ ...query, ...sort })
    setLoaded(true)
  }, [search])

  useEffect(() => {
    if (is_loaded) {
      getShops({ ...query, page: undefined })
    }
  }, [shops.filters.query])

  const { data, meta } = shops

  return (
    <Main
      page={{
        breadcrumbs: [{ name: 'Sklepy' }],
        name: 'Sklepy',
        icon: <ShopIcon />,
        buttons: <Button type="add" text="Dodaj sklep" path="/shops/new" />
      }}>
      <Dashboard>
        <MainTable
          is_loading={['invalid', 'loading'].includes(shops.status)}
          is_searching={shops.status === 'searching'}
          filters_open={shops.filters.open}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: 'ID', name: 'id' },
            { label: 'Nazwa', name: 'name' },
            { label: 'Label', name: 'label', sortable: false },
            { label: 'Platformy', name: 'platforms', sortable: false }
          ]}
          empty_text="Brak sklepów"
          renderRow={({ id, name, label_text, label_color, platforms }) => (
            <tr
              key={id}
              style={{ cursor: 'pointer' }}
              onMouseDown={event => {
                if (event.button === 1) {
                  window.open(`/shops/${id}`, '_blank')
                }
              }}
              onClick={() => history.push(`/shops/${id}`)}>
              <td>{id}</td>
              <td>{name}</td>
              <td>
                <Label type="fill" text={label_text} color={label_color} />
              </td>
              <td>
                {platforms?.length > 0
                  ? platforms.map(platform => (
                      <Label
                        type="bordered"
                        key={platform.id}
                        text={platform?.label_text}
                        color={`#${platform?.label_color}`}
                      />
                    ))
                  : 'brak'}
              </td>
            </tr>
          )}
        />
      </Dashboard>
    </Main>
  )
}

export default connect(
  ({ shops }) => ({ shops }),
  dispatch => ({
    getShops: data => dispatch(getShops(data))
  })
)(ShopsList)
