import React from 'react'
import { connect } from 'react-redux'
import { Input } from 'expano-components'
import { Dashboard } from 'components'

import { ReactComponent as InfoIcon } from 'icons/specification.svg'

const Specification = ({ product }) => {
  return (
    <Dashboard header={{ title: 'Opakowanie', icon: <InfoIcon /> }}>
      <div className="dashboard__form__submit">
        <div className="form-row grid-2">
          <Input
            label="Waga"
            input={{ value: product.weight, disabled: true }}
            meta={{}}
          />
          <Input
            label="Wysokość (opakowanie) cm"
            input={{ value: product.package_height, disabled: true }}
            meta={{}}
          />
        </div>
        <div className="form-row grid-2">
          <Input
            label="Głębokość (opakowanie) cm"
            input={{ value: product.package_depth, disabled: true }}
            meta={{}}
          />
          <Input
            label="Szerokość (opakowanie) cm"
            input={{ value: product.package_width, disabled: true }}
            meta={{}}
          />
        </div>
      </div>
    </Dashboard>
  )
}

export default connect(({ product: { data: product } }) => ({
  product
}))(Specification)
