const initialState = {
  status: 'invalid',
  filters: {
    open: true,
    query: {}
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SYSTEM_SETTINGS_TOGGLE_FILTERS':
      return {
        ...state,
        filters: { ...state.filters, open: !state.filters.open }
      }
    case 'SYSTEM_SETTINGS_SET_FILTERS_QUERY':
      return {
        ...state,
        filters: { ...state.filters, query: action.query }
      }
    case 'SYSTEM_SETTINGS_SEARCHING':
      return {
        ...state,
        status: 'searching'
      }
    case 'SYSTEM_SETTINGS_LOADING':
      return {
        ...state,
        status: 'loading'
      }
    case 'SYSTEM_SETTINGS_SUCCESS':
      return {
        ...state,
        status: 'success',
        ...action
      }
    case 'SYSTEM_SETTINGS_FAILURE':
      return {
        ...state,
        status: 'failure'
      }
    case 'SYSTEM_SETTINGS_UPDATE_RECORD': {
      let data = [...state.data]
      const index = data.indexOf(data.find(({ id }) => id === action.data.id))
      if (index > -1) {
        data[index] = action.data
      }
      return {
        ...state,
        data
      }
    }
    default:
      return state
  }
}
