import { toastr } from 'react-redux-toastr'
import { client, handleErrors, stringifyQuery } from 'utils/api'

export const toggleFilters = () => dispatch =>
  dispatch({ type: 'SHOPS_TOGGLE_FILTERS' })

export const filterShops = query => async dispatch =>
  dispatch({ type: 'SHOPS_SET_FILTERS_QUERY', query })

export const getShops = ({ page = 1, per_page = 10, ...rest }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      status,
      filters: { query }
    } = getState().shops

    dispatch({
      type: status === 'invalid' ? 'SHOPS_LOADING' : 'SHOPS_SEARCHING'
    })

    const { data, meta } = await client.get(
      `/shops?${stringifyQuery({
        ...rest,
        page,
        per_page,
        context: 'ui_index',
        ...query
      })}`
    )

    dispatch({
      type: 'SHOPS_SUCCESS',
      data,
      meta
    })
  } catch (error) {
    dispatch({ type: 'SHOPS_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podczas pobierania')
  }
}

export const getShop = id => async (dispatch, getState) => {
  try {
    const { shop } = getState()

    if (shop.status === 'invalid' || shop?.data?.id !== id) {
      dispatch({ type: 'SHOP_LOADING' })
    }

    const { data } = await client.get(`/shops/${id}?context=ui_show`)
    const platform_ids = data.channels.map(({ platform: { id } }) => id)

    const { data: platforms } = await client.get(
      `/platforms?${stringifyQuery({
        context: 'list',
        per_page: 100,
        'q[id_not_in]': platform_ids
      })}`
    )

    dispatch({
      type: 'SHOP_SUCCESS',
      data,
      platforms
    })
  } catch (error) {
    dispatch({ type: 'SHOP_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podzczas pobierania')
  }
}

export const createShop = values =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post(`/shops`, values)
      resolve(data)
      toastr.success('Sukces', 'Sklep został dodany')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd podzczas tworzenia sklepu')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const updateShop = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/shops/${id}`, values)
      const { data } = await client.get(`/shops/${id}?context=ui_show`)
      dispatch({
        type: 'SHOP_SUCCESS',
        data
      })
      resolve()
      toastr.success('Sukces', 'Sklep został zaktualizowany')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const deleteShop = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/shops/${id}`)
      toastr.success('Sukces', 'Usunięto sklep')
      resolve()
    } catch (error) {
      reject(error)
      toastr.error('Błąd', 'Wystąpił błąd')
    }
  })

export const createChannel = values => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const { id } = getState().shop.data
      await client.post('/channels', values)
      dispatch(getShop(id))
      resolve()
      toastr.success('Sukces', 'Platforma została dodana')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(error?.response?.data?.error)
    }
  })

export const deleteChannel = channel_id => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const { id } = getState().shop.data
      await client.delete(`/channels/${channel_id}`)
      dispatch(getShop(id))
      resolve()
      toastr.success('Sukces', 'Platforma została usunięta')
    } catch (error) {
      reject(error?.response?.data?.error)
      toastr.error('Błąd', 'Platforma nie została usunięta')
    }
  })
