import { toastr } from "react-redux-toastr";
import { client, handleErrors, stringifyQuery } from "utils/api";

export const toggleFilters = () => (dispatch) =>
  dispatch({ type: "PRODUCTS_TOGGLE_FILTERS" });

export const filterProducts = (query) => async (dispatch) =>
  dispatch({ type: "PRODUCTS_SET_FILTERS_QUERY", query });

export const getProducts =
  ({ page = 1, per_page = 10, ...rest }) =>
  async (dispatch, getState) => {
    try {
      const {
        status,
        filters: { query },
      } = getState().products;

      dispatch({
        type: status === "invalid" ? "PRODUCTS_LOADING" : "PRODUCTS_SEARCHING",
      });
      const { data, meta } = await client.get(
        `/products?${stringifyQuery({
          ...rest,
          page,
          per_page,
          context: "ui_index",
          ...query,
        })}`
      );
      dispatch({
        type: "PRODUCTS_SUCCESS",
        data,
        meta,
      });
    } catch (error) {
      dispatch({ type: "PRODUCTS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    }
  };

export const getProductGroupData = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { category, brand, is_bundle, producer },
      } = await client.get(`/product_groups/${id}?context=product_form`);

      resolve({ category, brand, is_bundle, producer });

      if (!brand?.id) {
        toastr.info(
          "Brak marki.",
          "Przejdź do produktu grupowego i przypisz markę. Kliknij w wiadomość, aby otworzyć produkt grupowy w nowej karcie.",
          {
            timeOut: 0,
            onToastrClick: () =>
              window.open(
                `https://root.tradesk.pl/product_groups/${id}`,
                "_blank"
              ),
          }
        );
      }
    } catch (error) {
      toastr.error(
        "Błąd",
        "Wystąpił błąd podczas pobierania produktu grupowego"
      );
      reject(error);
    }
  });

export const getProduct = (id) => async (dispatch, getState) => {
  try {
    const { product } = getState();

    if (product.status === "invalid" || product?.data?.id !== id) {
      dispatch({ type: "PRODUCT_LOADING" });
    }

    const { data } = await client.get(`/products/${id}?context=ui_show`);

    const channels_ids = data.channel_products.map(({ channel: { id } }) => id);

    const { data: available_channels } = await client.get(
      `/channels?${stringifyQuery({
        context: "ui_index",
        per_page: 100,
        "q[id_not_in]": channels_ids,
      })}`
    );

    dispatch({
      type: "PRODUCT_SUCCESS",
      data,
      available_channels,
    });
  } catch (error) {
    dispatch({ type: "PRODUCT_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podczzas pobierania");
  }
};

export const createProduct = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post("/products", values);
      resolve(data);
    } catch (err) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(err?.response?.data?.errors));
    }
  });

export const updateProduct = (id, values) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/products/${id}`, values);
      await dispatch(getProduct(id));
      toastr.success("Sukces", "Produkt został zaktualizowany");
      resolve();
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error?.response?.data?.errors));
    }
  });

export const deleteProduct = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/products/${id}`);
      toastr.success("Sukces", "Usunięto produkt");
      resolve();
    } catch (err) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(err?.response?.data?.error);
    }
  });

export const createChannelProducts =
  (channel_ids) => async (dispatch, getState) =>
    new Promise(async (resolve, reject) => {
      try {
        const {
          data: { id: product_id },
        } = getState().product;

        await Promise.all(
          channel_ids.map((channel_id) =>
            client.post(`/channel_products`, { channel_id, product_id })
          )
        );
        toastr.success("Sukces", "Kanały zostały dodane");

        await dispatch(getProduct(product_id));

        resolve();
      } catch (error) {
        toastr.error("Błąd", "Wystąpił błąd");
        reject(handleErrors(error?.response?.data?.errors));
      }
    });

export const deleteProductChannel = (id) => async (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id: product_id },
      } = getState().product;

      await client.delete(`/channel_products/${id}`);
      await dispatch(getProduct(product_id));
      resolve();
      toastr.success("Sukces", "Kanał został usunięty");
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(error?.response?.data?.error);
    }
  });

export const createBundle = (values) => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id: product_id },
      } = getState().product;

      await client.post(`/product_components`, values);
      await dispatch(getProduct(product_id));
      resolve();
      toastr.success("Sukces", "Bundle został dodany");
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
      reject(handleErrors(error?.response?.data?.errors));
    }
  });

export const deleteBundle = (id) => async (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id: product_id },
      } = getState().product;

      await client.delete(`/product_components/${id}`);
      await dispatch(getProduct(product_id));
      resolve();
      toastr.success("Sukces", "Bundle został usunięty");
    } catch (error) {
      toastr.error("Błąd", "Nie udało się usunąć bundla");
      reject(error.response.data.error);
    }
  });

export const updateBundle = (id, values) => async (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    try {
      const {
        data: { id: product_id },
      } = getState().product;
      await client.put(`/product_components/${id}`, values);
      await dispatch(getProduct(product_id));
      resolve();
      toastr.success("Sukces", "Bundle został zaktualizowany");
    } catch (error) {
      toastr.error("Błąd", JSON.stringify(error));
      reject(error?.response?.data?.error);
    }
  });

export const createSubstitute = (values) => async (dispatch, getState) => {
  try {
    const {
      data: { id: product_id },
    } = getState().product;

    await client.post("/product_substitutes", values);
    await dispatch(getProduct(product_id));

    toastr.success("Sukces", "Substytut został dodany");
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
    throw handleErrors(error?.response?.data?.errors);
  }
};

export const deleteSubstitute = (id) => async (dispatch, getState) => {
  try {
    const {
      data: { id: product_id },
    } = getState().product;

    await client.delete(`/product_substitutes/${id}`);
    await dispatch(getProduct(product_id));

    toastr.success("Sukces", "Substytut został usunięty");
  } catch (error) {
    toastr.error("Błąd", "Nie udało się usunąć bundla");
    throw error.response.data.error;
  }
};

export const updateSubstitute = (id, values) => async (dispatch, getState) => {
  try {
    const {
      data: { id: product_id },
    } = getState().product;
    await client.put(`/product_substitutes/${id}`, values);
    await dispatch(getProduct(product_id));
    toastr.success("Sukces", "Substytut został zaktualizowany");
  } catch (error) {
    toastr.error("Błąd", JSON.stringify(error));
    throw error?.response?.data?.error;
  }
};

export const updateProductsByCsv = async (file) => {
  try {
    const form_data = new FormData();

    form_data.append("file", file);

    const { data } = await client.post("/products/update_by_csv", form_data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    toastr.success("Sukces", "Produkty zostały zaktualizowane");

    return data;
  } catch (error) {
    throw error?.response?.data?.error?.message || "Wystąpił błąd";
  }
};
