import React from "react";
import ReactSelect from "react-select";

const Select = ({ data, handleChange }) => {
  const customStyles = {
    control: (styles) => ({
      ...styles,
      borderColor: "#E2E5EC",
      borderWidth: 1,
      borderRadius: 4,
      minHeight: 30,
    }),
    option: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
      padding: 0,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "1px 10px",
    }),
    indicatorSeparator: (styles) => ({ ...styles, display: "none" }),
    placeholder: (provided) => ({
      ...provided,
      fontFamily: "Poppins",
      fontSize: "13px",
    }),
    multiValue: (provided) => ({
      ...provided,
      flexDirection: "row-reverse",
      backgroundColor: "#F3F6F9",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      padding: "0 6px",
      fontSize: 10,
      color: "#595D6E",
    }),
  };
  return (
    <div className="inp-wrapper">
      {data?.label && <label>{data.label}</label>}
      <ReactSelect
        onChange={handleChange}
        styles={customStyles}
        placeholder={"Wybierz..."}
        options={data.options || []}
        isClearable
      />
    </div>
  );
};

export default Select;
