const initialState = {
  status: 'invalid',
  filters: {
    open: true,
    query: {}
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'WAREHOUSES_TOGGLE_FILTERS':
      return {
        ...state,
        filters: { ...state.filters, open: !state.filters.open }
      }
    case 'WAREHOUSES_SET_FILTERS_QUERY':
      return {
        ...state,
        filters: { ...state.filters, query: action.query }
      }
    case 'WAREHOUSES_SEARCHING':
      return {
        ...state,
        status: 'searching'
      }
    case 'WAREHOUSES_LOADING':
      return {
        ...state,
        status: 'loading'
      }
    case 'WAREHOUSES_SUCCESS':
      return {
        ...state,
        status: 'success',
        ...action
      }
    case 'WAREHOUSES_FAILURE':
      return {
        ...state,
        status: 'failure'
      }
    default:
      return state
  }
}
