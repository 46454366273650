import { toastr } from 'react-redux-toastr'
import { client, handleErrors } from 'utils/api'

export const getCategories = (loading = true) => async dispatch => {
  try {
    if (loading) {
      dispatch({ type: 'CATEGORIES_LOADING' })
    }
    const { data } = await client.get(
      `/categories?q[ancestry_blank]=true&per_page=50`
    )
    dispatch({
      type: 'CATEGORIES_SUCCESS',
      data
    })
  } catch (error) {
    dispatch({ type: 'CATEGORIES_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podzczas pobierania')
  }
}

export const getCategoryByAncestry = ancestry =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/categories?q[ancestry_eq]=${ancestry}&per_page=50`
      )
      resolve(data)
    } catch (error) {
      reject(error)
      toastr.error('Błąd', 'Wystąpił błąd podzczas pobierania')
    }
  })

export const getCategory = id => async dispatch => {
  try {
    dispatch({ type: 'CATEGORY_LOADING' })
    const { data } = await client.get(`/categories/${id}?context=ui_show`)
    dispatch({
      type: 'CATEGORY_SUCCESS',
      data
    })
  } catch (error) {
    dispatch({ type: 'CATEGORY_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podzczas pobierania')
  }
}

export const createCategory = values =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post('/categories', values)
      resolve(data)
      toastr.success('Sukces', 'Dodano kategorię')
    } catch (error) {
      reject(handleErrors(error?.response?.data?.errors))
      toastr.error('Błąd', 'Wystąpił błąd')
    }
  })

export const updateCategory = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/categories/${id}`, values)
      const { data } = await client.get(`/categories/${id}?context=ui_show`)
      dispatch({
        type: 'CATEGORY_SUCCESS',
        data
      })
      resolve()
      toastr.success('Sukces', 'Kategoria została zaktualizowana')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const deleteCategory = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/categories/${id}`)
      resolve()
                  toastr.success('Sukces', 'Usunięto kategorię')

    } catch (error) {
      reject(error)
       toastr.error('Błąd', 'Wystąpił błąd')
    }
  })
