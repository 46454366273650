import { toastr } from 'react-redux-toastr'
import { client, handleErrors, stringifyQuery } from 'utils/api'

export const toggleFilters = () => dispatch =>
  dispatch({ type: 'SPEDITORS_TOGGLE_FILTERS' })

export const filterSpeditors = query => async dispatch =>
  dispatch({ type: 'SPEDITORS_SET_FILTERS_QUERY', query })

export const getSpeditors = ({ page = 1, per_page = 10, ...rest }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      status,
      filters: { query }
    } = getState().speditors

    dispatch({
      type: status === 'invalid' ? 'SPEDITORS_LOADING' : 'SPEDITORS_SEARCHING'
    })
    const { data, meta } = await client.get(
      `/speditors?${stringifyQuery({
        ...rest,
        page,
        per_page,
        context: 'ui_index',
        ...query
      })}`
    )
    dispatch({
      type: 'SPEDITORS_SUCCESS',
      data,
      meta
    })
  } catch (error) {
    dispatch({ type: 'SPEDITORS_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podczas pobierania')
  }
}

export const getSpeditor = id => async dispatch => {
  try {
    dispatch({ type: 'SPEDITOR_LOADING' })
    const { data } = await client.get(`/speditors/${id}`)
    dispatch({
      type: 'SPEDITOR_SUCCESS',
      data
    })
  } catch (error) {
    dispatch({ type: 'SPEDITOR_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podzczas pobierania')
  }
}

export const createSpeditor = values =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post(`/speditors`, values)
      toastr.success('Sukces', 'Dodano spedytora')
      resolve(data)
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const updateSpeditor = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/speditors/${id}`, values)
      const { data } = await client.get(`/speditors/${id}`)

      dispatch({
        type: 'SPEDITOR_SUCCESS',
        data
      })
      resolve()
      toastr.success('Sukces', 'Spedytor został zaktualizowany')
    } catch (error) {
      reject(handleErrors(error?.response?.data?.errors))
      toastr.error('Błąd', 'Wystąpił błąd')
    }
  })

export const updateSpeditorImage = (id, image) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/speditors/${id}`, image, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      const { data } = await client.get(`/speditors/${id}`)
      dispatch({
        type: 'SPEDITOR_SUCCESS',
        data
      })
      toastr.success('Sukces', 'Logo zostało dodane')
      resolve()
    } catch (error) {
      toastr.error('Błąd', 'Nie udało się dodać logo')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const deleteSpeditor = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/speditors/${id}`)
      resolve()
      toastr.success('Sukces', 'Usunięto spedytora')
    } catch (error) {
      reject(handleErrors(error?.response?.data?.errors))
      toastr.error('Błąd', 'Wystąpił błąd')
    }
  })
