import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Button } from 'expano-components'
import { createBundle } from 'actions/products'
import { Modal, AsyncSelectField, InputField } from 'components'

import getProductOptions from 'utils/select_options/product_options'

import { ReactComponent as ProductIcon } from 'icons/product.svg'

const AddBundleModal = ({
  handleClose,
  handleSubmit,
  change,
  pristine,
  createBundle
}) => {
  const onSubmit = values =>
    createBundle(values)
      .then(handleClose)
      .catch(err => {
        throw new SubmissionError(err)
      })

  return (
    <Modal
      handleClose={handleClose}
      header={{ title: 'Dodaj produkt', icon: <ProductIcon /> }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="add-green"
            text="Dodaj"
            disabled={pristine}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Field
          name="component_id"
          component={AsyncSelectField}
          change={change}
          getData={getProductOptions}
          label="Wybierz produkt, który będzie częścią bundla:"
        />
        <Field
          name="quantity"
          type="number"
          component={InputField}
          label="Ilość:"
        />
      </form>
    </Modal>
  )
}

const mapDispatchToProps = dispatch => ({
  createBundle: data => dispatch(createBundle(data))
})

export default connect(
  ({ product: { data: product } }) => ({
    initialValues: { bundle_id: product.id, component_id: null, quantity: 1 }
  }),
  mapDispatchToProps
)(reduxForm({ form: 'add-bundle' })(AddBundleModal))
