import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Button } from "expano-components";
import { AsyncSelectField } from "components";
import { createBrandChannel } from "actions/brands";
import { Modal } from "components";
import getChannels from "utils/select_options/channel_options";

import { ReactComponent as ChannelIcon } from "icons/channel.svg";

const AddBrandChannel = ({
  handleClose,
  handleSubmit,
  createBrandChannel,
  change,
  pristine,
  brand_id,
}) => {
  const submit = ({ channel_id }) =>
    createBrandChannel({ brand_id, channel_id }).then(handleClose);

  return (
    <Modal
      size="lg"
      handleClose={handleClose}
      header={{ title: "Dodaj kanał", icon: <ChannelIcon /> }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="save"
            text="Zapisz"
            disabled={pristine}
            onClick={handleSubmit(submit)}
          />
        </>
      }
    >
      <form noValidate onSubmit={handleSubmit(submit)}>
        <Field
          label="Kanał sprzedażowy"
          name="channel_id"
          component={AsyncSelectField}
          getData={getChannels}
          change={change}
        />
      </form>
    </Modal>
  );
};

export default connect(
  ({
    brand: {
      data: { id: brand_id },
    },
  }) => ({
    brand_id,
  }),
  (dispatch) => ({
    createBrandChannel: (data) => dispatch(createBrandChannel(data)),
  })
)(reduxForm({ form: "add-brand-channel" })(AddBrandChannel));
