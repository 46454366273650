import { toastr } from "react-redux-toastr";
import { client, handleErrors, stringifyQuery } from "utils/api";

export const toggleFilters = () => (dispatch) =>
  dispatch({ type: "BRANDS_TOGGLE_FILTERS" });

export const filterBrands = (query) => async (dispatch) =>
  dispatch({ type: "BRANDS_SET_FILTERS_QUERY", query });

export const getBrands =
  ({ page = 1, per_page = 10, ...rest }) =>
  async (dispatch, getState) => {
    try {
      const {
        status,
        filters: { query },
      } = getState().brands;

      dispatch({
        type: status === "invalid" ? "BRANDS_LOADING" : "BRANDS_SEARCHING",
      });

      const { data, meta } = await client.get(
        `/brands?${stringifyQuery({
          ...rest,
          page,
          per_page,
          context: "ui_index",
          ...query,
        })}`
      );

      dispatch({
        type: "BRANDS_SUCCESS",
        data,
        meta,
      });
    } catch (error) {
      dispatch({ type: "BRANDS_FAILURE" });
      toastr.error("Błąd", "Wystąpił błąd podczas pobierania");
    }
  };

export const getBrand = (id) => async (dispatch) => {
  try {
    dispatch({ type: "BRAND_LOADING" });
    const { data } = await client.get(`/brands/${id}?context=ui_show`);
    dispatch({
      type: "BRAND_SUCCESS",
      data,
    });
  } catch (error) {
    dispatch({ type: "BRAND_FAILURE" });
    toastr.error("Błąd", "Wystąpił błąd podzczas pobierania");
  }
};

export const createBrand = (values) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post(`/brands`, values);
      resolve(data);
      toastr.success("Sukces", "Dodano markę");
    } catch (error) {
      reject(handleErrors(error?.response?.data?.errors));
      toastr.error("Błąd", "Wystąpił błąd");
    }
  });

export const updateBrand = (id, values) => (dispatch) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/brands/${id}`, values);
      const { data } = await client.get(`/brands/${id}?context=ui_show`);

      dispatch({
        type: "BRAND_SUCCESS",
        data,
      });
      toastr.success("Sukces", "Marka została zaktualizowana");
      resolve();
    } catch (error) {
      reject(handleErrors(error?.response?.data?.errors));
      toastr.error("Błąd", "Wystąpił błąd");
    }
  });

export const deleteBrand = (id) =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/brands/${id}`);
      toastr.success("Sukces", "Usunięto markę");
      resolve();
    } catch (error) {
      reject(error.response.data.error);
      toastr.error("Błąd", "Wystąpił błąd");
    }
  });

export const createBrandChannel =
  ({ brand_id, channel_id }) =>
  async (dispatch) => {
    try {
      await client.post("/brand_channels", { brand_id, channel_id });
      toastr.success("Sukces", "Dodano kanał");
      dispatch(getBrand(brand_id));
    } catch (error) {
      toastr.error("Błąd", "Wystąpił błąd");
    }
  };

export const deleteBrandChannel = (id) => async (dispatch, getState) => {
  try {
    const { id: brand_id } = getState().brand.data;
    await client.delete(`/brand_channels/${id}`);
    dispatch(getBrand(brand_id));
  } catch (error) {
    toastr.error("Błąd", "Wystąpił błąd");
  }
};
