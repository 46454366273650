import React from 'react'
import Loader from 'react-loader-spinner'

const Loading = () => (
  <div className="main-table__loader">
    <Loader type="Watch" color="#fd397a" height={40} width={40} />
  </div>
)

export default Loading
