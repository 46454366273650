const initialState = {
  status: 'invalid',
  data: null,
  available_channels: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PRODUCT_RESET':
      return {
        status: 'invalid'
      }
    case 'PRODUCT_LOADING':
      return {
        ...state,
        status: 'loading'
      }
    case 'PRODUCT_SUCCESS':
      return {
        ...state,
        status: 'success',
        data: action.data,
        available_channels: action.available_channels
      }
    case 'PRODUCT_FAILURE':
      return {
        ...state,
        status: 'failure',
        err: action.err
      }

    default:
      return state
  }
}
