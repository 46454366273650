import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Button } from 'expano-components'
import { Main, InputField, Dashboard } from 'components'
import { createAllowedIp } from 'actions/allowed_ips'

import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as AllowedIpIcon } from 'icons/allowed_ip.svg'

const AllowedIpNew = ({ handleSubmit, history, pristine }) => {
  const submit = values =>
    createAllowedIp(values)
      .then(({ id }) => history.push(`/allowed_ips/${id}`))
      .catch(err => {
        throw new SubmissionError(err)
      })

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: 'Dozwolone IP', path: '/allowed_ips' },
          { name: 'Dodaj dozwolone IP' }
        ],
        name: 'Dodaj dozwolone IP',
        icon: <AllowedIpIcon />
      }}>
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{ title: 'Podstawowe dane', icon: <InfoIcon /> }}
            footer={
              <Button
                disabled={pristine}
                type="save"
                onClick={handleSubmit(submit)}
              />
            }>
            <form noValidate onSubmit={handleSubmit(submit)}>
              <div className="dashboard__form__submit">
                <Field
                  name="name"
                  label="Nazwa"
                  component={InputField}
                  type="email"
                />
                <Field
                  name="ip_address"
                  label="Adres IP"
                  component={InputField}
                  type="text"
                />
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

export default reduxForm({ form: 'create-allowed-ip' })(AllowedIpNew)
