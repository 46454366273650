import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { connect } from 'react-redux'
import { Label, Button } from 'expano-components'
import { createChannel } from 'actions/shops'
import { Modal, SelectField } from 'components'
import { ReactComponent as PlatformIcon } from 'icons/platform.svg'

const AddPlatform = ({
  handleClose,
  handleSubmit,
  change,
  platforms,
  pristine,
  shop,
  createChannel
}) => {
  const platform_options = platforms.map(
    ({ id, label_text, label_color, name }) => ({
      label: (
        <p>
          <Label type="fill" text={label_text} color={label_color} /> {name}
        </p>
      ),
      value: { id, label_text, label_color, name }
    })
  )
  const onSubmit = ({ platform }) =>
    createChannel({
      platform_id: platform.id,
      shop_id: shop.id
    }).then(() => handleClose())

  return (
    <Modal
      handleClose={handleClose}
      header={{ title: 'Dodaj platformę', icon: <PlatformIcon /> }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            type="add-green"
            text="Dodaj"
            disabled={pristine}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      }>
      <p style={styles.text}>
        Wybierz platformę na której chcesz prowadzić sprzedaż
      </p>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Field
          label="Platforma"
          name="platform"
          component={SelectField}
          options={platform_options}
          change={change}
        />
      </form>
    </Modal>
  )
}

export default connect(
  ({ shop: { data: shop } }) => ({ shop }),
  dispatch => ({
    createChannel: data => dispatch(createChannel(data))
  })
)(reduxForm({ form: 'add-platform' })(AddPlatform))

const styles = {
  text: {
    fontSize: '13px',
    lineHeight: '20px',
    color: '#595D6E',
    paddingBottom: 10
  },
  body: {
    padding: 30
  }
}
