import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, ModalDelete } from 'expano-components'
import EditVatRateModal from '../../../modals/EditVatRateModal'
import { deleteVatRate } from 'actions/vat_rates'

const VatRateRow = ({ deleteVatRate, id, name, value, country_code }) => {
  const [is_open_delete_modal, handleOpenDeleteModal] = useState(false)
  const [is_open_edit_modal, handleOpenEditModal] = useState(false)
  const onDelete = () => {
    deleteVatRate(id)
      .then(() => {
        handleOpenDeleteModal(false)
      })
      .catch(err => {
        console.error(err)
      })
  }
  return (
    <>
      {is_open_delete_modal && (
        <ModalDelete
          handleDelete={onDelete}
          handleClose={() => handleOpenDeleteModal(false)}
          name={`stawkę VAT ${name}`}
        />
      )}
      {is_open_edit_modal && (
        <EditVatRateModal
          handleClose={() => handleOpenEditModal(false)}
          initialValues={{ name, value }}
          id={id}
          country_code={country_code}
        />
      )}
      <tr>
        <td>{name}</td>
        <td>{value}</td>
        <td>
          <Button type="edit" onClick={() => handleOpenEditModal(true)} />
          <Button
            type="delete-bin"
            onClick={() => handleOpenDeleteModal(true)}
          />
        </td>
      </tr>
    </>
  )
}

export default connect(null, dispatch => ({
  deleteVatRate: id => dispatch(deleteVatRate(id))
}))(VatRateRow)
