import { client } from "utils/api";

const getProductOptions = (q = null, without_bundle = true) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/products?context=list&per_page=15${
          without_bundle ? `&q[is_bundle_eq]=false` : ""
        }${q ? `&q[name_or_sku_matches]=%25${q}%25` : ""}`
      );
      resolve(
        data.map(({ id, name, sku }) => ({
          label: `[${sku}] ${name}`,
          value: id,
        }))
      );
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });

export const getVirtualProductOptions = (q = null) =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.get(
        `/products?context=list&per_page=15&q[is_bundle_eq]=false&q[is_virtual_eq]=false${
          q ? `&q[name_or_sku_matches]=%25${q}%25` : ""
        }`
      );
      resolve(
        data.map(({ id, name, sku }) => ({
          label: `[${sku}] ${name}`,
          value: id,
        }))
      );
    } catch (error) {
      console.log(error);
      reject([]);
    }
  });

export default getProductOptions;
