import { getCookie } from "utils/cookies";

const default_open = getCookie("sidebar_open") === "true";

const initialState = {
  is_open: default_open,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SIDEBAR_TOGGLE": {
      return { is_open: !state.is_open };
    }
    default:
      return state;
  }
};