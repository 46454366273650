import { toastr } from 'react-redux-toastr'
import { client, handleErrors, stringifyQuery } from 'utils/api'

export const toggleFilters = () => dispatch =>
  dispatch({ type: 'USERS_TOGGLE_FILTERS' })

export const filterUsers = query => async dispatch =>
  dispatch({ type: 'USERS_SET_FILTERS_QUERY', query })

export const getUsers = ({ page = 1, per_page = 10, ...rest }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      status,
      filters: { query }
    } = getState().users

    dispatch({
      type: status === 'invalid' ? 'USERS_LOADING' : 'USERS_SEARCHING'
    })
    const { data, meta } = await client.get(
      `/users?${stringifyQuery({
        ...rest,
        page,
        per_page,
        context: 'ui_index',
        ...query
      })}`
    )

    dispatch({
      type: 'USERS_SUCCESS',
      data,
      meta
    })
  } catch (error) {
    dispatch({ type: 'USERS_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podczas pobierania')
  }
}

export const getUser = id => async dispatch => {
  try {
    dispatch({ type: 'USER_LOADING' })
    const { data } = await client.get(`/users/${id}`)
    dispatch({
      type: 'USER_SUCCESS',
      data
    })
  } catch (error) {
    dispatch({ type: 'USER_FAILURE' })
    toastr.error('Błąd', 'Wystąpił błąd podzczas pobierania')
  }
}

export const createUser = values =>
  new Promise(async (resolve, reject) => {
    try {
      const { data } = await client.post(`/users`, values)
      resolve(data)
      toastr.success('Sukces', 'Dodano użytkownika')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const updateUser = (id, values) => dispatch =>
  new Promise(async (resolve, reject) => {
    try {
      await client.put(`/users/${id}`, values)
      const { data } = await client.get(`/users/${id}`)

      dispatch({
        type: 'USER_SUCCESS',
        data
      })
      resolve()
      toastr.success('Sukces', 'Użytkownik został zaktualizowany')
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject(handleErrors(error?.response?.data?.errors))
    }
  })

export const deleteUser = id =>
  new Promise(async (resolve, reject) => {
    try {
      await client.delete(`/users/${id}`)
      toastr.success('Sukces', 'Usunięto użytkownika')
      resolve()
    } catch (error) {
      toastr.error('Błąd', 'Wystąpił błąd')
      reject()
    }
  })
