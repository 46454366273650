import React, { useState, useEffect } from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { Redirect } from 'react-router'
import { connect } from 'react-redux'
import { Button, Select, ModalDelete } from 'expano-components'
import { PanelLoader, Main, Dashboard, InputField } from 'components'
import { getCategory, updateCategory, deleteCategory } from 'actions/categories'

import { ReactComponent as InfoIcon } from 'icons/info.svg'
import { ReactComponent as ListIcon } from 'icons/list.svg'

const CategoryEdit = ({
  handleSubmit,
  category,
  pristine,
  valid,
  history,
  match: {
    params: { id }
  },
  getCategory,
  updateCategory
}) => {
  const [is_open_delete_modal, handleOpenDeleteModal] = useState(false)

  useEffect(() => {
    getCategory(id)
  }, [id])

  const onSubmit = values =>
    updateCategory(id, values).catch(err => {
      throw new SubmissionError(err)
    })

  const handleDelete = () =>
    deleteCategory(id).then(() => history.push('/categories'))

  if (['invalid', 'loading'].includes(category.status)) {
    return <PanelLoader />
  }

  if (category.status === 'failure') {
    return <Redirect to="/categories" />
  }

  return (
    <Main
      page={{
        breadcrumbs: [
          { name: 'Kategorie', path: '/categories' },
          {
            name: category?.data?.name
          }
        ],
        name: category?.data?.name,
        icon: <ListIcon />,
        buttons: (
          <Button
            type="delete"
            text="Usuń kategorię"
            onClick={() => handleOpenDeleteModal(true)}
          />
        )
      }}>
      {is_open_delete_modal && (
        <ModalDelete
          handleDelete={handleDelete}
          handleClose={() => handleOpenDeleteModal(false)}
          name={`kategorię ${category?.data?.name}`}
        />
      )}
      <div className="dashboard-wrapper-flex">
        <div className="column-half">
          <Dashboard
            header={{ title: 'Podstawowe dane', icon: <InfoIcon /> }}
            footer={
              <Button
                disabled={pristine || !valid}
                type="save"
                onClick={handleSubmit(onSubmit)}
              />
            }>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="dashboard__form__submit">
                <div className="form-row grid-2">
                  <Field
                    name="name"
                    label="Nazwa"
                    component={InputField}
                    type="email"
                  />
                  <Field
                    name="parent_id"
                    component={InputField}
                    type="hidden"
                  />
                  <Select
                    label="Rodzic"
                    isDisabled
                    defaultValue={
                      category?.data?.parent
                        ? {
                            label: category.data.parent.name,
                            value: category.data.parent.id
                          }
                        : { label: 'Brak', value: null }
                    }
                  />
                </div>
                <div className="form-row grid-2">
                  <Field
                    name="identifier"
                    label="Identyfikator"
                    component={InputField}
                    type="text"
                  />
                </div>
              </div>
            </form>
          </Dashboard>
        </div>
      </div>
    </Main>
  )
}

const mapStateToProps = ({ category }) => ({
  category,
  initialValues: {
    name: category?.data?.name,
    identifier: category?.data?.identifier
  }
})

const mapDispatchToProps = dispatch => ({
  getCategory: id => dispatch(getCategory(id)),
  updateCategory: (id, data) => dispatch(updateCategory(id, data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(reduxForm({ form: 'edit-category', enableReinitialize: true })(CategoryEdit))
