import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Label } from 'expano-components'
import { Button, MainTable, Main, Dashboard } from 'components'
import { parseQuery } from 'utils/api'
import { getPlatforms } from 'actions/platforms'

import { ReactComponent as PlatformIcon } from 'icons/platform.svg'

const PlatformsList = ({
  platforms,
  getPlatforms,
  history,
  location: { search }
}) => {
  const query = parseQuery(search)
  const [is_loaded, setLoaded] = useState(false)

  const sort = {
    'sort[column]': query['sort[column]'] || 'id',
    'sort[order]': query['sort[order]'] || 'asc'
  }

  useEffect(() => {
    getPlatforms({ ...query, ...sort })
    setLoaded(true)
  }, [search])

  useEffect(() => {
    if (is_loaded) {
      getPlatforms({ ...query, page: undefined })
    }
  }, [platforms.filters.query])

  const { data, meta } = platforms

  return (
    <Main
      page={{
        breadcrumbs: [{ name: 'Platformy' }],
        name: 'Platformy',
        icon: <PlatformIcon />,
        buttons: (
          <Button type="add" text="Dodaj platformę" path="/platforms/new" />
        )
      }}>
      <Dashboard>
        <MainTable
          is_loading={
            platforms.status === 'invalid' || platforms.status === 'loading'
          }
          is_searching={platforms.status === 'searching'}
          filters_open={platforms.filters.open}
          data={data}
          meta={meta}
          sort={sort}
          head={[
            { label: 'ID', name: 'id' },
            { label: 'Nazwa', name: 'name' },
            { label: 'Label', name: 'label', sortable: false },
            { label: 'Kraj', name: 'lang' },
            { label: 'Waluta', name: 'currency' },
            { label: 'Sklepy', name: 'shop', sortable: false }
          ]}
          empty_text="Brak platform"
          renderRow={({
            id,
            name,
            label_text,
            label_color,
            currency_code,
            country_code,
            shops
          }) => (
            <tr
              key={id}
              style={{ cursor: 'pointer' }}
              onMouseDown={event => {
                if (event.button === 1) {
                  window.open(`/platforms/${id}`, '_blank')
                }
              }}
              onClick={() => history.push(`/platforms/${id}`)}>
              <td>{id}</td>
              <td>{name}</td>
              <td>
                <span
                  className="table__platform"
                  style={{
                    color: `#${label_color}`,
                    borderColor: `#${label_color}`
                  }}>
                  {`${label_text}`}
                </span>
              </td>
              <td>{country_code}</td>
              <td>{currency_code}</td>
              <td>
                {shops?.length > 0
                  ? shops.map(shop => (
                      <Label
                        type="fill"
                        key={shop.id}
                        text={shop?.label_text}
                        color={`#${shop?.label_color}`}
                      />
                    ))
                  : 'brak'}
              </td>
              <td>
                <Button type="edit" path={`/platforms/${id}`} />
              </td>
            </tr>
          )}
        />
      </Dashboard>
    </Main>
  )
}

const mapDispatchToProps = dispatch => ({
  getPlatforms: data => dispatch(getPlatforms(data))
})
export default connect(
  ({ platforms }) => ({ platforms }),
  mapDispatchToProps
)(PlatformsList)
