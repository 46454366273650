import { toastr } from 'react-redux-toastr'
import { client } from 'utils/api'
import { removeCookie, setCookie } from '../utils/cookies'

export const handleLogin = async ({ email, password }) => {
  try {
    const { auth_token } = await client.post('/users/sign_in', {
      email,
      password
    })

    setCookie('token', auth_token).then(() => {
      window.location.pathname = '/'
    })
  } catch (error) {
    toastr.error('Błąd', 'Wystąpił błąd podzczas logowania')
  }
}
export const handleLogout = async () => {
  await removeCookie('token')
  window.location.pathname = '/'
}
