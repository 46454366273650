import React from 'react'
import { Head, Loading, Filters, Pagination, Empty } from './components'

const MainTable = ({
  is_loading,
  is_searching,
  data,
  filters,
  filtersAction,
  meta,
  head,
  sort,
  empty_text,
  renderRow,
  filters_open,
  toggleFilters,
  buttons
}) => (
  <>
    {filters?.length > 0 && (
      <Filters
        filtersAction={filtersAction}
        filters_open={filters_open}
        toggleFilters={toggleFilters}
        data={filters}
      />
    )}
    {buttons && <div className="main-table__buttons">{buttons}</div>}
    <table className={`main-table ${is_searching ? 'searching' : ''}`}>
      <Head data={head} sort={sort} />
      {data?.length > 0 && (
        <tbody className="main-table__body">
          {data.map(item => renderRow?.(item))}
        </tbody>
      )}
    </table>
    {is_loading && <Loading />}
    {data?.length < 1 && <Empty empty_text={empty_text} />}
    {meta && <Pagination {...meta} />}
  </>
)

export default MainTable
