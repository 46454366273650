import React from 'react'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { connect } from 'react-redux'
import { Select, Button } from 'expano-components'
import { Modal, InputField } from 'components'
import { updateVatRate } from 'actions/vat_rates'
import { ReactComponent as EuroIcon } from 'icons/euro.svg'

const EditVatRateModal = ({
  handleClose,
  updateVatRate,
  handleSubmit,
  pristine,
  countries,
  country_code,
  id
}) => {
  const onSubmit = values =>
    updateVatRate(id, values)
      .then(handleClose)
      .catch(err => {
        console.log(err)
        throw new SubmissionError(err)
      })

  const options = countries?.map(({ code }) => ({
    label: code,
    value: code
  }))

  return (
    <Modal
      handleClose={handleClose}
      header={{ icon: <EuroIcon />, title: 'Edytuj VAT' }}
      footer={
        <>
          <Button type="cancel" onClick={handleClose} />
          <Button
            disabled={pristine}
            type="save"
            onClick={handleSubmit(onSubmit)}
            text="Zapisz"
          />
        </>
      }>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Select
          isDisabled
          label="Kraj"
          options={options}
          defaultValue={options.find(({ value }) => value === country_code)}
        />
        <Field name="name" label="Nazwa" component={InputField} type="text" />
        <Field
          name="value"
          label="Stawka"
          postfix="%"
          component={InputField}
          type="number"
        />
      </form>
    </Modal>
  )
}

export default connect(
  ({ vat_rates: { countries } }) => ({ countries }),
  dispatch => ({
    updateVatRate: (id, data) => dispatch(updateVatRate(id, data))
  })
)(reduxForm({ form: 'edit-vat-rate-form' })(EditVatRateModal))
